import React from "react";
import { FaStar } from "react-icons/fa";

function Rating({ rating }) {
  const totalRating = [];

  for (let i = 0; i < 5; i++) {
    if (i < rating) {
      totalRating.push(
        <li key={i}>
          <FaStar style={{ color: "#4CC514" }} />
        </li>
      );
    } else {
      totalRating.push(
        <li key={i}>
          <FaStar style={{ color: "#4CC514" }} />
        </li>
      );
    }
  }
  return (
    <div className="subject-rating">
      <ul>{totalRating}</ul>
    </div>
  );
}

export default Rating;
