import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Space,
  Table,
  notification,
} from "antd";
import { useGetAllSuggestionBookByIdQuery } from "../../../../RTKQuery/rtkLibrary";
import {
  useCreateSuggestedBookMutation,
  useEditSuggestedBookMutation,
} from "../../../../RTKQuery/rtkLibrary";
import React, { useEffect, useState } from "react";
import {
  CheckOutlined,
  CloseOutlined,
  DislikeOutlined,
  EditOutlined,
  EyeOutlined,
  CheckCircleFilled,
} from "@ant-design/icons";
import Cookies from "js-cookie";
import "../MyAccount.scss";
import { useSelector } from "react-redux";
const SuggestedBooks = () => {
  const [suggestionForm] = Form.useForm();
  const [suggestedBooks, setSuggestedBooks] = useState([]);
  const [visible, setVisible] = useState(false);
  const [studyModalDetails, setStudyModalDetails] = useState(false);
  const [viewData, setViewData] = useState({});
  const [editSuggestedBook, seteditSuggestedBook] = useState(null);
  // const studentInfo = Cookies.get("studentInfo")
  //   ? JSON.parse(Cookies.get("studentInfo"))
  //   : [];
  const studentInfo = useSelector((state) => state.studentInfo.studentInfo);
  const studentId = studentInfo?.studentId;
  const userId = studentId;

  const { data, refetch } = useGetAllSuggestionBookByIdQuery({
    userId: userId,
    userType: "Student",
  });
  const [createSuggestionBooks] = useCreateSuggestedBookMutation();
  const [editSuggestionBooks] = useEditSuggestedBookMutation();

  useEffect(() => {
    if (editSuggestedBook?.id) {
      suggestionForm.setFieldsValue({
        authorName: editSuggestedBook?.authorName,
        bookName: editSuggestedBook?.bookName,
      });
    } else {
      suggestionForm.resetFields();
    }
  }, [suggestionForm, editSuggestedBook]);

  const viewSuggetionBook = (record) => {
    setStudyModalDetails(true);
    setViewData(record);
  };
  const handleOnclick = (record) => {
    seteditSuggestedBook(record);
    setVisible(true);
  };

  const columns = [
    {
      title: "Author Name",
      dataIndex: "authorName",
      key: "authorName",
      align: "center",
    },
    {
      title: "Book Name",
      dataIndex: "bookName",
      key: "bookName",
      align: "center",
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      render: (_, record) => (
        <Space>
          {/* <EyeOutlined
            className="full-details-button"
            onClick={() => viewSuggetionBook(record)}
          /> */}

          <Button className="blue-button">
            <EditOutlined
              className="full-details-button"
              onClick={() => handleOnclick(record)}
            />
          </Button>
        </Space>
      ),
    },
  ];

  const showModal = () => {
    setVisible(true);
  };
  useEffect(() => {
    if (data) {
      setSuggestedBooks(data?.data);
    }
  }, [data]);

  const handleCancel = () => {
    setVisible(false);
    suggestionForm.resetFields();
    seteditSuggestedBook(null);
  };

  const handleModalCancel = () => {
    setStudyModalDetails(false);
  };

  const viewcColumns = [
    {
      title: "Field",
      dataIndex: "field",
      key: "field",
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
    },
  ];
  const datas = [
    {
      field: "User Name",
      value: viewData?.userName,
    },
    {
      field: "Book Name",
      value: viewData?.bookName,
    },
    {
      field: "Author Name",
      value: viewData?.authorName,
    },
  ];

  const onFinish = (values) => {
    const payload = {
      ...values,
    };
    const params = { userId: userId, userType: "Student" };
    const bookId = editSuggestedBook?.id;
    if (!bookId) {
      createSuggestionBooks({
        payload: values,
        userId: userId,
        userType: "Student",
      })
        .then((response) => {
          setVisible(false);

          notification.success({
            message: `${values.bookName} Created successfully `,
            placement: "bottomRight",
            icon: (
              <CheckCircleFilled
                style={{
                  color: "green",
                }}
              />
            ),
          });
          refetch();
        })
        .catch((err) => {
          notification.error({
            message: `${err.message}`,
            icon: (
              <DislikeOutlined
                style={{
                  color: "red",
                }}
              />
            ),
            duration: 3,
            placement: "bottomRight",
          });
        });
    } else {
      editSuggestionBooks({ bookId: bookId, payload: payload }).then((res) => {
        setVisible(false);
        suggestionForm.resetFields();
        notification.success({
          message: `${values.bookName} Edited successfully `,
          placement: "bottomRight",
          icon: (
            <CheckCircleFilled
              style={{
                color: "#108ee9",
              }}
            />
          ),
        });
        refetch();
      });
    }
  };

  return (
    <div className="suggested_book_container mainContainer">
      <div className="SuggestButtonContainer">
        <Button className="SuggestButton submitButton" onClick={showModal}>
          Create Suggest Book
        </Button>
      </div>
      <>
        {/*<Table columns={columns} dataSource={data} />*/}
        <Table columns={columns} dataSource={suggestedBooks} />
      </>
      <div>
        <Modal
          title={
            editSuggestedBook && Object.keys(editSuggestedBook).length > 0
              ? "Edit Suggested Book"
              : "Create Suggested Book"
          }
          open={visible}
          onCancel={handleCancel}
          footer={null}
          width={300}
        >
          <Form onFinish={onFinish} form={suggestionForm}>
            <Row className="my-row">
              <Col span={24}>
                <Form.Item
                  name="authorName"
                  rules={[{ required: true, message: "" }]}
                >
                  <Input placeholder="Author Name" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="bookName"
                  rules={[{ required: true, message: "" }]}
                >
                  <Input placeholder="Book Name" />
                </Form.Item>
              </Col>
            </Row>
            <Row className="my-row" gutter={8}>
              <Col span={12} align="end">
                <Space direction="vertical" size="large">
                  <Button className="submit-button " htmlType="submit">
                    Save
                  </Button>
                </Space>
              </Col>
              <Col span={12}>
                <Space direction="vertical" size="large">
                  <Button className="cancel-btn" onClick={handleCancel}>
                    cancel
                  </Button>
                </Space>
              </Col>
            </Row>
          </Form>
        </Modal>
      </div>
      <Modal
        open={studyModalDetails}
        title="Suggested Book Details"
        onCancel={handleModalCancel}
        footer={null}
        width={600}
        centered
        className="view-assignment"
      >
        {viewData && (
          <Table
            columns={viewcColumns}
            dataSource={datas}
            pagination={false}
            showHeader={false}
          />
        )}
      </Modal>
    </div>
  );
};
export default SuggestedBooks;
