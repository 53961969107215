import { createSlice } from "@reduxjs/toolkit";

const toggleSlice = createSlice({
  name: "toggle",
  initialState: {
    openMenu: false,
  },
  reducers: {
    menuActive: (state) => {
      state.openMenu = !state.openMenu;
    },
  },
});

export const selectMenu = (state) => state?.toggle?.openMenu;

export const { menuActive } = toggleSlice?.actions;

export default toggleSlice.reducer;
