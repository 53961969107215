import React from "react";
import { Birthday, Schoolname } from "../../components";

const Birthdays = () => {
  return (
    <main>
      <Schoolname />
      <Birthday />
    </main>
  );
};

export default Birthdays;
