import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import Cookies from "js-cookie";
export const culturalActivicties = createApi({
  reducerPath: "culturalActivicties",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://api.edpedia.co/v1.0/student/",
    prepareHeaders: (headers, { getState }) => {
      const accessToken = Cookies.get("accessToken");
      if (accessToken) {
        headers.set("Authorization", `Bearer ${accessToken}`);
      }

      headers.set("Content-Type", "application/json");
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getCulturalActivities: builder.query({
      query: () => {
        return {
          url: "api/culturalActivity/culturalActivitiesList",
          method: "GET",
        };
      },
    }),
    createCulturalActivities: builder.mutation({
      query: (payload) => ({
        url: `/api/culturalActivityRegistration/createCulturalActivityRegistration`,
        method: "POST",
        body: payload,
      }),
    }),
    // createCulturalActivities:
  }),
});
export const {
  useGetCulturalActivitiesQuery,
  useCreateCulturalActivitiesMutation,
} = culturalActivicties;
// /api/culturalActivityRegistration/list
