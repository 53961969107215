import { createSlice } from "@reduxjs/toolkit";

const studentInfoInitialState = {
  studentInfo: JSON.parse(sessionStorage.getItem("studentsList")) || [],
};

const studentInfoSlice = createSlice({
  name: "studentInfo",
  initialState: studentInfoInitialState,
  reducers: {
    setStudentInfo: (state, action) => {
      state.studentInfo = action.payload;
      sessionStorage.setItem("studentsList", JSON.stringify(action.payload));
    },
  },
});

export const getStudentInfo = (state) => state?.studentInfo?.studentInfo || [];

export const { setStudentInfo } = studentInfoSlice.actions;

export default studentInfoSlice.reducer;
