import React from "react";
import { Exam, Schoolname } from "../../components";

function Exams() {
  return (
    <main>
      <Schoolname />
      <Exam />
    </main>
  );
}

export default Exams;
