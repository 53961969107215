import "../MyAccount.scss";
import BookImage from "../../../../assets/images/BookImage.jpg";
import { useGetRentedBooksQuery } from "../../../../RTKQuery/rtkLibrary";
import Cookies from "js-cookie";
import { Empty, Spin } from "antd";
import EmptyComponent from "../../../EmptyComponent/EmptyComponent";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
const PendingBooks = () => {
  // const studentInfo = Cookies.get("studentInfo")
  //   ? JSON.parse(Cookies.get("studentInfo"))
  //   : [];

  const studentInfo = useSelector(
    (state) => state.studentInfo.studentInfo
  );
  const studentId = studentInfo?.rollNo;
  const userId = studentId;
  const { data, isLoading, isError } = useGetRentedBooksQuery({
    userId: userId,
    userType: "Student",
  });
  const [pendingData, setPendingData] = useState([]);

  useEffect(() => {
    if (data) {
      setPendingData(data?.data?.pending);
    }
  }, [data]);
  if (isLoading) {
    return (
      <div className="centered_spin">
        <Spin />
      </div>
    );
  }

  if (isError) {
    return (
      <div className="ErrorContainer">
        <EmptyComponent />
      </div>
    );
  }

  return (
    <div className="mainContainer">
      {pendingData.length == 0 ? (
        <div
          style={{
            paddingTop: "70px",
            paddingBottom: "40px",
            backgroundColor: "#f0f0f0",
          }}
        >
          {" "}
          <EmptyComponent />
        </div>
      ) : (
        <>
          <div className="conatiner-missing">
            {pendingData?.map((returnedBook, index) => {
              const { book } = returnedBook;
              return (
                <div key={returnedBook.rentalId} className="rent-card">
                  <div className="left-side">
                    <img src={BookImage} alt="CardImage" />
                  </div>
                  <div className="right-side">
                    <p>{book?.bookName}</p>
                    <span>Publisher: {book?.publisher}</span>
                    <span>{book?.bookRate} /-</span>
                  </div>
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};
export default PendingBooks;
