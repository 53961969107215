import React, { useState } from "react";
import { Button, Card, Col, Row, Select, notification } from "antd";
import CheckOutForm from "../Cart/CheckOutForm";
import "../EdpediaStore.scss";
import { DislikeOutlined, HeartOutlined } from "@ant-design/icons";
import {
  useGetFindProductByIdQuery,
  useGetSubCategoryProductsListQuery,
  useProductAddToCartMutation,
  useWishListCreateMutation,
} from "../../../RTKQuery/rtkEdpediaStore";
import Cookies from "js-cookie";
const { Option } = Select;
const SubCategoryList = (props) => {
  const studentInfo = Cookies.get("studentInfo")
    ? JSON.parse(Cookies.get("studentInfo"))
    : [];
  const [productById, setProductById] = useState(null);
  const studentId = studentInfo?.studentId;
  const { selectedCategory, selectedSudId } = props;
  const { data: subcategoryProductsList } =
    useGetSubCategoryProductsListQuery(selectedSudId);
  const { data: findProductById } = useGetFindProductByIdQuery(productById);
  const [AddToCartsData] = useProductAddToCartMutation();
  const [AddWishList] = useWishListCreateMutation();
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [checkOutProduct, setCheckOutProduct] = useState(null);
  const [selectedValueQuantity, setSelectedValueQuantity] = useState("1");
  const [sizeState, setSizeState] = useState(null);

  const handleSelectChange = (value) => {
    setSelectedValueQuantity(value);
  };

  const handleProduct = (product) => {
    setProductById(product?.id);
    setSelectedProduct(true);
  };
  const handleAddCart = () => {
    setCheckOutProduct(true);
  };

  // const AddToCart = (productId) => {

  // };
  const AddToCart = (product) => {
    const payload = {
      productId: product?.id,
      brandId: product?.brandId,
      quantiy: selectedValueQuantity,
      sizeId: sizeState,
    };
    AddToCartsData({
      userId: studentId,
      payload,
    })
      .then((res) => {
        if (res.error) {
     
          notification.error({
            placement: "bottomRight",
            message: `${res.error?.data?.message} Please Select`,
            icon: <DislikeOutlined style={{ color: "red" }} />,
          });
        } else {
          notification.success({
            message: "Product Added To Cart",
            placement: "bottomRight",
          });
        }
      })
      .catch((error) => {});
  };

  const sizeSelectionFunct = (sizes) => {
    setSizeState(sizes?.sizeId);
  };

  const AddToWishList = (product) => {
    const payload = {
      productId: product?.id,
      brandId: product?.brandId,
      quantiy: selectedValueQuantity,
      sizeId: sizeState,
      userId: studentId,
    };
    AddWishList(payload)
      .then((res) => {
        if (res.error) {
          notification.error({
            placement: "bottomRight",
            message: `${res.error?.data?.message} Select Size`,
            icon: <DislikeOutlined style={{ color: "red" }} />,
          });
        } else {
          notification.success({
            message: "Product Added To Wishlist",
            placement: "bottomRight",
          });
        }
      })
      .catch((error) => {});
  };
  return (
    <>
      {selectedProduct === null && (
        <>
          {subcategoryProductsList?.data?.map((product) => {
            return (
              <>
                <div className="subcategory-container" key={product?.id}>
                  <Col span={6}>
                    <Card
                      hoverable
                      style={{ width: 240 }}
                      // cover={
                      //   <img
                      //     onClick={() => handleProduct(product)}
                      //     alt="example"
                      //     src={product?.images[0]}
                      //     className="card-img"
                      //   />
                      // }
                      cover={
                        <>
                          {product?.images?.map((i) => {
                            return (
                              <img
                                onClick={() => handleProduct(product)}
                                alt="example"
                                src={i}
                                className="card-img"
                              />
                            );
                          })}
                        </>
                      }
                    >
                      <div className="card-text">
                        <HeartOutlined onClick={() => AddToWishList(product)} />
                        <span className="text-name">
                          Name {product?.name} ({product?.subCategoryName})
                        </span>
                        <span className="text-name">
                          {product?.brandName} Brand
                        </span>
                        <span className="text-percentage">25% - 50%</span>
                        <span className="text-price">
                          Price {product?.actualPrice}
                        </span>
                        <span className="text-price">
                          After Discount {product?.afterDiscountAmount}
                        </span>
                        <span className="text-item-left">Only 10 left</span>
                        {product?.productSizes?.map((sizes) => {
                          return (
                            <>
                              {sizes?.quantity > 0 && (
                                <>
                                  <p>Available Sizes</p>
                                  <span
                                    onClick={() => sizeSelectionFunct(sizes)}
                                  >
                                    {" "}
                                    {sizes?.sizeName}
                                  </span>
                                </>
                              )}
                            </>
                          );
                        })}
                        <Select
                          value={selectedValueQuantity}
                          onChange={handleSelectChange}
                        >
                          <Option value="1">Quantity 1</Option>
                          <Option value="2">Quantity 2</Option>
                          <Option value="3">Quantity 3</Option>
                          <Option value="4">Quantity 4</Option>
                          <Option value="5">Quantity 5</Option>
                          <Option value="6">Quantity 6</Option>
                          <Option value="7">Quantity 7</Option>
                          <Option value="8">Quantity 8</Option>
                          <Option value="9">Quantity 9</Option>
                          <Option value="10">Quantity 10</Option>
                        </Select>
                        <Button
                          className="add-card-button"
                          onClick={() => AddToCart(product)}
                        >
                          Add to Cart
                        </Button>
                      </div>
                    </Card>
                  </Col>
                </div>
              </>
            );
          })}
        </>
      )}
      {selectedProduct !== null && checkOutProduct == null && (
        <div className="product-view">
          <div className="uniform-container">
            <img
              src={findProductById?.data?.images[0]}
              alt="girlUniform"
              className="dress"
            />
            {/* <img src={GirlUniform} alt="girlUniform" className="dress" /> */}
          </div>
          <div className="content-container">
            <h2>
              {findProductById?.data?.name} ({findProductById?.data?.brandName}){" "}
            </h2>
            <p>1200 rating & 750 reviews</p>
            <h3>Extra 200 off</h3>
            <h2>ActualPrice {findProductById?.data?.actualPrice}rs /-</h2>
            <h2>
              AfterDiscountAmount {findProductById?.data?.afterDiscountAmount}rs
              /-
            </h2>
            <h3>Available Coupons</h3>
            <h4>
              CODE : <span>EDPE2023</span>
            </h4>
            {findProductById?.data?.productSizes?.map((sizes) => {
              return (
                <>
                  {sizes?.quantity > 0 && (
                    <>
                      <p>Available Sizes</p>
                      <span onClick={() => sizeSelectionFunct(sizes)}>
                        {" "}
                        {sizes?.sizeName}
                      </span>
                    </>
                  )}
                </>
              );
            })}
            <div className="buttons-container">
              <Button className="buynow-button">Buy Now</Button>
              <Button className="add-to-cart-button" onClick={handleAddCart}>
                Add to Cart
              </Button>
            </div>
            <h1>Reviews</h1>
            <p>1.Good Fabric</p>
          </div>
        </div>
      )}
      <CheckOutForm checkOutProduct={checkOutProduct} />
    </>
  );
};

export default SubCategoryList;
