import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { BsArrowLeft } from "react-icons/bs";
import Cookies from "js-cookie";
import { yourprofile } from "../../assets/images";
import { useDispatch, useSelector } from "react-redux";
import { menuActive, selectMenu } from "../../features/toggleSlice";
import { useState } from "react";
import { useGetAttendanceQuery } from "../../RTKQuery/Attendance";
import { useGetResultQuery } from "../../RTKQuery/rtkQuery";
const ProfileRatings = ({ select, setSelect }) => {
  const fifty = 50;
  const sixty = 60;
  const toggle = () => {
    setSelect(!select);
  };

  // const studentInfo = Cookies.get("studentInfo")
  //   ? JSON.parse(Cookies.get("studentInfo"))
  //   : [];
  const studentInfo = useSelector((state) => state.studentInfo.studentInfo);

  const studentRollNo = studentInfo?.rollNo;
  const studentId = studentInfo?.studentId;

  const { data, isLoading, isError } = useGetAttendanceQuery(studentRollNo);
  const { data: results } = useGetResultQuery(studentId);

  const resultsData = results?.data?.examResults?.[0];
  const show = useSelector(selectMenu);

  return (
    <div className={show ? "profile-active" : "profile"}>
      <div className="">
        <BsArrowLeft className="profileactive-img" onClick={() => toggle()} />
        <div className="dashboard-profileItems flexDir-sty flex-sty text-ct">
          <div className="dashboard-profileItem flexDir-sty flex-sty ">
            <img
              src={yourprofile}
              alt="yourprofile"
              style={{ width: "50px" }}
            />
            <p>Profile</p>
          </div>

          <div className="dashboard-profileItem flexDir-sty flex-sty ">
            <div style={{ width: "50px" }}>
              <CircularProgressbar
                value={data?.data?.attendancePercentage}
                text={`${data?.data?.attendancePercentage}%`}
                styles={buildStyles({
                  rotation: 0.7,
                  textSize: "28px",
                  pathColor: "#BD9147",
                  textColor: "black",
                  backgroundColor: "#BD9147",
                })}
              />
            </div>
            <p>Attendance</p>
          </div>

          <div className="dashboard-profileItem flexDir-sty flex-sty ">
            <div style={{ width: "50px" }}>
              <CircularProgressbar
                // value={resultsData?.Grade}
                text={`${resultsData?.Grade}`}
                styles={buildStyles({
                  rotation: 0.7,
                  textSize: "28px",
                  pathColor: "#BD9147",
                  textColor: "black",
                  backgroundColor: "#BD9147",
                })}
              />
            </div>
            <p>Recent Examination</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileRatings;
