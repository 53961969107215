import { useEffect, useState } from "react";
import "./Library.scss";
import { Radio } from "antd";
import LibraryHome from "./HomePage/LibraryHome";
import Authors from "./Authors/Authors";
import Books from "./Books/Books";
import Categories from "./Categories/Categories";
import MyAccount from "./MyAccount/MyAccount";
import { useLocation } from "react-router-dom";
const RadioGroup = Radio.Group;
const Library = () => {
  const [activeTabKey, setActiveTabKey] = useState("home");
  const handleTabChange = (event) => {
    setActiveTabKey(event.target.value);
  };
  const location = useLocation();
  useEffect(() => {
    const tabSelected = location.state;
    if (tabSelected === "authors") {
      setActiveTabKey("authors");
    } else {
      setActiveTabKey("home");
    }
  }, [location]);

  return (
    <div className="library-wrapper">
      <div className="header-container mainContainer ">
        <h2 className="mheader">Library</h2>

        <div className="radioparent">
        <RadioGroup
          onChange={handleTabChange}
          value={activeTabKey}
          optionType="button"
          buttonStyle="solid"
          className="mainradio"
        >
          <Radio.Button value="home">Home</Radio.Button>
          <Radio.Button value="authors">Authors</Radio.Button>
          <Radio.Button value="books">Books</Radio.Button>
          <Radio.Button value="myAccount">My Account</Radio.Button>
        </RadioGroup>
      </div>
      {activeTabKey === "home" && <LibraryHome />}
      {activeTabKey === "authors" && (
        <Authors setActiveTabKey={setActiveTabKey} />
      )}
      {activeTabKey === "books" && <Books setActiveTabKey={setActiveTabKey} />}

      {activeTabKey === "myAccount" && (
        <MyAccount setActiveTabKey={setActiveTabKey} />
      )}
        </div>
    
    </div>
  );
};

export default Library;
