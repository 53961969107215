import React, { useEffect } from "react";
import "./Attendance.scss";
import { BsDashCircle, BsCheckCircle } from "react-icons/bs";
import { AiFillCloseCircle } from "react-icons/ai";
import { BiBlock } from "react-icons/bi";
import { Calendar, Spin } from "antd";
import { useGetAttendanceQuery } from "../../RTKQuery/Attendance";
import Cookies from "js-cookie";
import EmptyComponent from "../EmptyComponent/EmptyComponent";
import moment from "moment";
import gradeIcon from "../../assets/icons/Mask_Group.svg";
import calendar from "../../assets/icons/calendar4-week.svg";
import { useDispatch, useSelector } from "react-redux";
import { fetchStudentInfo } from "../../Store/StudentInfoSlice";

const Attendance = () => {
  const currentDate = new Date();

  const studentInfo = useSelector(
    (state) => state.studentInfo.studentInfo
  );
  const studentRollNo = studentInfo?.rollNo;

  const dateFormat = "DD-MM-YYYY";
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const formattedDate = currentDate.toLocaleDateString("en-US", options);

  const { data, isLoading, isError } = useGetAttendanceQuery(studentRollNo);

  if (isLoading) {
    return (
      <div>
        <Spin />
      </div>
    );
  }

  if (isError) {
    return (
      <div className="ErrorContainer">
        <EmptyComponent />
      </div>
    );
  }

  const absentClasses = data?.data?.absentClasses;
  const attendancePercentage = data?.data?.attendancePercentage;
  const presentedClasses = data?.data?.presentClasses;
  const totalClasses = data?.data?.totalClasses;
  const schoolWorkingDays = data?.data?.workingDays;
  const dayWiseMap = data?.data?.dayWiseMap;

  const dateCellRender = (date) => {
    const dateString = date.format("YYYY-MM-DD");
    const status = dayWiseMap[dateString];

    if (status === "PRESENT") {
      return (
        <div style={{ backgroundColor: "green" }} className="daily-status">
          Present
        </div>
      );
    } else if (status === "ABSENT") {
      return (
        <div style={{ backgroundColor: "red" }} className="daily-status">
          Absent
        </div>
      );
    } else if (status === "HOLIDAY") {
      return (
        <div style={{ backgroundColor: "#8B8000" }} className="daily-status">
          Holidays
        </div>
      );
    } else if (status === "") {
      return (
        <div style={{ backgroundColor: "orange" }} className="daily-status">
          Attendance not taken
        </div>
      );
    } else if (status === "SUNDAY") {
      // backgroundColor = "red";
      return (
        <div style={{ backgroundColor: "red" }} className="daily-status">
          Sunday
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <section
      className="attendace-wrapper sidebar-scroll-container "
      id="culture"
    >
      <div>
        <div className="headings ">
          <h3>Attendance </h3>
          <p className="attendance-desc">{moment().format(dateFormat)}</p>
        </div>
        <div className="calnederContainer">
          <article className="attendane-grid grid-sty">
            <div className="attendance-content-item">
              <div className="att-top-content">
                <small>School Working Days</small>

                <div className="iconblock col1">
                  <img src={calendar}></img>
                </div>
              </div>

              <div className="attendance-circle exam-circle">
                {schoolWorkingDays}
              </div>
            </div>

            <div className="attendance-content-item">
              <div className="att-top-content">
                <small>Total Classes</small>

                <div className="iconblock col2">
                  <img src={calendar}></img>
                </div>
              </div>
              <div className="attendance-circle exam-circle">
                {totalClasses}
              </div>
            </div>

            <div className="attendance-content-item">
              <div className="att-top-content">
                {" "}
                <small>Presented Classes</small>
                <div className="iconblock col3">
                  <img src={calendar}></img>
                </div>
              </div>
              <div className="attendance-circle exam-circle">
                {presentedClasses}
              </div>
            </div>

            <div className="attendance-content-item">
              <div className="att-top-content">
                {" "}
                <small>Absent Classes</small>
                <div className="iconblock col4">
                  <img src={calendar}></img>
                </div>
              </div>
              <div className="attendance-circle exam-circle ">
                {absentClasses}
              </div>
            </div>

            <div className="attendance-content-item">
              <div className="att-top-content">
                {" "}
                <small>Attendance Percent</small>
                <div className="iconblock col5">
                  <img src={calendar}></img>
                </div>
              </div>

              <div className="attendance-circle exam-circle">
                {attendancePercentage.toFixed(2)} %
              </div>
            </div>
          </article>
          <div className="attendance-rates">
            {/* <div className="rates-item flex-sty">
              <div className="rate-circle" style={{ background: "#935252" }} />
              <small>School Working Days - {schoolWorkingDays}</small>
            </div> */}

            <div className="rates-item flex-sty">
              <div className="rate-circle" style={{ background: "#96BB2D" }} />
              <small>Attend Days - 07</small>
            </div>

            <div className="rates-item flex-sty">
              <div className="rate-circle" />
              <small>Absent Days - 01</small>
            </div>

            <div className="rates-item flex-sty">
              <div className="rate-circle" style={{ background: "#7E8184" }} />
              <small>Leave Days - 01</small>
            </div>

            <div className="rates-item flex-sty">
              <div className="rate-circle" style={{ background: "#096CC8" }} />
              <small>Holidays - 01</small>
            </div>
          </div>
          <Calendar
            dateCellRender={dateCellRender}
            picker="month"
            className="custom-calendar "
          />
        </div>
      </div>
    </section>
  );
};

export default Attendance;
