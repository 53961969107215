import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import Cookies from "js-cookie";
export const getLatestActivityListApi2 = createApi({
  reducerPath: "getLatestActivityListApi2",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://api.edpedia.co/v1.0/student/",
    prepareHeaders: (headers, { getState }) => {
      const accessToken = Cookies.get("accessToken");
      if (accessToken) {
        headers.set("Authorization", `Bearer ${accessToken}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    submitAssignment: builder.mutation({
      query: ({ assignmentId, payload }) => ({
        url: `api/student/${assignmentId}/submitAssignment`,
        method: "POST",
        body: payload,
      }),
    }),
    uploadStudentImage: builder.mutation({
      query: ({ StudentId, payload }) => ({
        url: `api/student/${StudentId}/uploadProfilePic`,
        method: "POST",
        body: payload,
      }),
    }),
  }),
});
export const { useSubmitAssignmentMutation, useUploadStudentImageMutation } =
  getLatestActivityListApi2;
