import "./LibraryHome.scss";
import RichBook from "../../../assets/images/book image.jpg";
import AuthorGirl from "../../../assets/images/author girl.jpg";
import BookImage from "../../../assets/images/BookImage.jpg";
import {
  useGetFamousBooksQuery,
  useGetLatestBooksQuery,
  useGetRentedBooksQuery,
  useGetTopAuthorsQuery,
} from "../../../RTKQuery/rtkLibrary";
import { Button, Empty, Spin } from "antd";
import StarRating from "../../../Utils/StartRating";
import EmptyComponent from "../../EmptyComponent/EmptyComponent";
import { useState } from "react";
import RentBookModal from "../Books/RentBookModal";
import Cookies from "js-cookie";
import RequestBookModal from "../Books/RequestBookModal";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const LibraryHome = () => {
  // const studentInfo = Cookies.get("studentInfo")
  //   ? JSON.parse(Cookies.get("studentInfo"))
  //   : [];
  const studentInfo = useSelector(
    (state) => state.studentInfo.studentInfo
  );
  const studentId = studentInfo?.rollNo;
  const userId = studentId;
  const rollNo = studentInfo?.rollNo;
  const {
    data: GetFamousBooks,
    isLoading: GetFamousBooksLoading,
    isError: GetFamousBooksError,
  } = useGetFamousBooksQuery();
  const {
    data: GetLatestBooks,
    isLoading: GetLatestBooksLoading,
    isError: GetLatestBooksError,
  } = useGetLatestBooksQuery();
  const {
    data: GetTopAuthors,
    isLoading: GetTopAuthorsLoading,
    isError: GetTopAuthorsError,
  } = useGetTopAuthorsQuery();

  const getFamousBooks = GetFamousBooks?.data;
  const getLatestBooks = GetLatestBooks?.data;
  const getTopAuthors = GetTopAuthors?.data;
  const { data: rentedBooks } = useGetRentedBooksQuery({
    userId: rollNo,
    userType: "Student",
  });
  const location = useLocation();
  const navigate = useNavigate();
  const GetRentedBooks = rentedBooks?.data?.rented;
  const [selectedBook, setSelectedBook] = useState(null);
  const [isRentModalVisible, setRentModalVisible] = useState(false);
  const [isRequestModalVisible, setRequestModalVisible] = useState(false);
  const handleRentBook = (book) => {
    setRentModalVisible(book);
    setSelectedBook(book);
  };
  if (GetFamousBooksLoading) {
    return (
      <div className="centered_spin">
        <Spin />
      </div>
    );
  }
  if (GetFamousBooksError) {
    return (
      <div className="ErrorContainer">
        <EmptyComponent />
      </div>
    );
  }
  if (GetLatestBooksLoading) {
    return (
      <div className="centered_spin">
        <Spin />
      </div>
    );
  }
  if (GetLatestBooksError) {
    return (
      <div className="ErrorContainer">
        <EmptyComponent />
      </div>
    );
  }
  if (GetTopAuthorsLoading) {
    return (
      <div className="centered_spin">
        <Spin />
      </div>
    );
  }
  if (GetTopAuthorsError) {
    return (
      <div className="ErrorContainer">
        <EmptyComponent />
      </div>
    );
  }
  const onHandleRequest = (book) => {
    setRequestModalVisible(true);
  };

  const handleClickAuthor = () => {
    navigate("/library", { state: "authors" });
  };
  return (
    <>
      <div className="sidebar-scroll-container">
        <div className="">
          <div className="home-container">
            <h3>Latest Books</h3>
            <div className="horizontal-scroll-container">
              {getLatestBooks.length === 0 ? (
                <EmptyComponent />
              ) : (
                <>
                  <div className="books-container">
                    {getLatestBooks?.map((latestBooks) => {
                      return (
                        <div key={latestBooks.id} className="card-container">
                          <img
                            src={latestBooks.image}
                            alt="img"
                            className="card-image"
                          />
                          <div className="author-card-content-container">
                            <h5 className="nameWidth">
                              {latestBooks.bookName}
                            </h5>
                            <h5>{latestBooks.publisher}</h5>
                            <h5>
                              Available Books -{latestBooks.booksAvailable}
                            </h5>
                            <StarRating
                              numberOfRatings={latestBooks.numberOfRatings}
                            />
                          </div>
                          <div>
                            {latestBooks?.booksAvailable > 0 ? (
                              GetRentedBooks?.some(
                                (item) => item.book.id === latestBooks.id
                              ) ? (
                                <Button className="rent-button green-bg">
                                  Rented
                                </Button>
                              ) : (
                                <Button
                                  className="rent-button orange-bg"
                                  onClick={() => handleRentBook(latestBooks)}
                                >
                                  Rent Now
                                </Button>
                              )
                            ) : (
                              <Button
                                className="request-button"
                                onClick={() => onHandleRequest(latestBooks)}
                              >
                                Request
                              </Button>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="home-container">
            <h3>Famous Books</h3>
            <div className="horizontal-scroll-container">
              {getFamousBooks.length === 0 ? (
                <Empty />
              ) : (
                <>
                  {" "}
                  <div className="books-container">
                    {getFamousBooks?.map((famousBook) => {
                      return (
                        <div key={famousBook.id} className="card-container">
                          <img
                            src={famousBook.image}
                            alt="img"
                            className="card-image"
                          />
                          <div className="author-card-content-container">
                            <h5 className="nameWidth">{famousBook.bookName}</h5>
                            <h5>{famousBook.publisher}</h5>
                            <StarRating
                              numberOfRatings={famousBook.numberOfRatings}
                            />
                          </div>
                          <div>
                            {famousBook?.numbersOfBooks > 0 ? (
                              GetRentedBooks?.some(
                                (item) => item.book.id === famousBook.id
                              ) ? (
                                <Button className="rent-button green-bg">
                                  Rented
                                </Button>
                              ) : (
                                <Button
                                  className="rent-button orange-bg"
                                  onClick={() => handleRentBook(famousBook)}
                                >
                                  Rent Now
                                </Button>
                              )
                            ) : (
                              <Button
                                className="request-button"
                                onClick={() => onHandleRequest(famousBook)}
                              >
                                Request
                              </Button>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="home-container">
            <h3>Top Authors</h3>
            <div className="horizontal-scroll-container">
              {getTopAuthors.length === 0 ? (
                <Empty />
              ) : (
                <>
                  {" "}
                  <div className="books-container">
                    {getTopAuthors?.map((topAuthorsBook) => {
                      return (
                        <div
                          key={topAuthorsBook.id}
                          className="author_main_container"
                          onClick={handleClickAuthor}
                        >
                          <img
                            src={topAuthorsBook.image}
                            alt="img"
                            className="card-image"
                          />
                          <div className="author-card-content-container">
                            <h5 className="nameWidth">{topAuthorsBook.name}</h5>
                            <h6>{topAuthorsBook.email}</h6>
                            <div className="icon-container">
                              <i className="star-icon"></i>
                              <i className="star-icon"></i>
                              <i className="star-icon"></i>
                              <i className="star-icon"></i>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </>
              )}
            </div>
            <RentBookModal
              isRentModalVisible={isRentModalVisible}
              setRentModalVisible={setRentModalVisible}
              selectedBook={selectedBook}
            />
            <RequestBookModal
              isRequestModalVisible={isRequestModalVisible}
              setRequestModalVisible={setRequestModalVisible}
              selectedBook={selectedBook}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default LibraryHome;
