import React, { useEffect, useState } from "react";
import { Button, Progress, Spin, Table, notification } from "antd";
import { useGetChapterStatusQuery } from "../../RTKQuery/rtkQuery";
import "./Syllabus.css";
import Cookies from "js-cookie";
import EmptyComponent from "../EmptyComponent/EmptyComponent";
import {
  DownloadOutlined,
  DislikeOutlined,
  CheckCircleFilled,
} from "@ant-design/icons";
import axios from "axios";
import { useSelector } from "react-redux";
function SyllabusDetail() {
  // const studentInfo = Cookies.get("studentInfo")
  //   ? JSON.parse(Cookies.get("studentInfo"))
  //   : [];
  const studentInfo = useSelector((state) => state.studentInfo.studentInfo);
  const classIds = studentInfo?.classId;

  const { data, isLoading, isError } = useGetChapterStatusQuery(classIds);
  const [chaptersStatusData, setChaptersStatusData] = useState([]);
  useEffect(() => {
    if (data) {
      setChaptersStatusData(data?.data);
    }
  }, [data]);
  const columns = [
    {
      title: "Subject Name",
      dataIndex: "subjectName",
      key: "subjectName",
      align: "center",
      sorter: (a, b) => a.subjectName.localeCompare(b.subjectName),
    },
    {
      title: "Subject Teacher",
      dataIndex: "teacherName",
      key: "teacherName",
      align: "center",
      sorter: (a, b) => a.teacherName.localeCompare(b.teacherName),
    },
    {
      title: "Total Classes",
      dataIndex: "totalClass",
      key: "totalClass",
      align: "center",
      sorter: (a, b) => a.totalClass - b.totalClass,
    },
    {
      title: "Chapters",
      dataIndex: "chapters",
      key: "chapters",
      align: "center",
      sorter: (a, b) => a.chapters - b.chapters,
    },
    {
      title: "Completed Chapters",
      dataIndex: "completedChapters",
      key: "completedChapters",
      align: "center",
      sorter: (a, b) => a.completedChapters - b.completedChapters,
    },
    {
      title: "Info",
      dataIndex: "info",
      key: "info",
      align: "center",
      sorter: (a, b) => a.info.localeCompare(b.info),
    },
    {
      title: "Ongoing",
      dataIndex: "onGoing",
      key: "onGoing",
      align: "center",
      sorter: (a, b) => a.onGoing.localeCompare(b.onGoing),
    },
    {
      title: "Syllabus Book",
      dataIndex: "overAll",
      key: "overAll",
      align: "center",
      render: (record, value) => {
        // const roundedValue = value.toFixed(0);

        return (
          <span>
            <DownloadOutlined onClick={() => downloadStaffDetails(value)} />
          </span>
        );
      },
    },
  ];

  if (isLoading) {
    return (
      <div className="centered_spin">
        <Spin />
      </div>
    );
  }

  if (isError) {
    return (
      <div className="ErrorContainer">
        <EmptyComponent />
      </div>
    );
  }
  const accessToken = Cookies.get("accessToken");
  const downloadStaff = (record) => {
    return axios.get(
      `https://api.edpedia.co/v1.0/instAdmin/api/syllabus/exportSyllabus?classId=${record?.classId}&subjectId=${record.subjectId}`,
      {
        responseType: "arraybuffer",
        headers: {
          Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
        },
      }
    );
  };

  function downloadFile(data, filename) {
    const blob = new Blob([data], { type: "application/vnd.ms-excel" });

    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename;

    // Download after 1 second to ensure file has been fully downloaded
    setTimeout(() => {
      a.click();
      window.URL.revokeObjectURL(url);
    }, 1000);
  }
  const downloadStaffDetails = (record) => {
    downloadStaff(record)
      .then((response) => {
        if (response && response.data) {
          downloadFile(response.data, "syllabus.pdf");
          notification.success({
            message: "The File is downloading...",
            description: `Syllabus downloading sucessfully `,
            icon: (
              <CheckCircleFilled
                style={{
                  color: "green",
                }}
              />
            ),
            duration: 3,
            placement: "bottomRight",
          });
        } else {
          console.log("No response data available");
        }
      })
      .catch((error) => {
        notification.error({
          message: "Error",
          description: "An error occurred while Downloading, try again later.",
          icon: (
            <DislikeOutlined
              style={{
                color: "red",
              }}
            />
          ),
          duration: 3,
          placement: "bottomRight",
        });
      });
  };

  return (
    <>
      <div
        className="main-bg"
        //  id="cluster"
      >
        <h3>Syllabus</h3>
        <div className="sidebar-scroll-container">
          <Table
            dataSource={chaptersStatusData}
            columns={columns}
            pagination={false}
            showSorterTooltip={false}
          />
        </div>
      </div>
    </>
  );
}

export default SyllabusDetail;
