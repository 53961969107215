import React from "react";
import { Subject, Schoolname } from "../../components";

const Subjects = () => {
  return (
    <main>
      <Schoolname />
      <Subject />
    </main>
  );
};

export default Subjects;
