import React, { useEffect, useState } from "react";
import { Column } from "@ant-design/plots";
import { useGetMonthWiseAttendanceQuery } from "../../RTKQuery/Attendance";
import "./Dashboard.css";
import Cookies from "js-cookie";
import { Spin, Progress } from "antd";
import EmptyComponent from "../EmptyComponent/EmptyComponent";
import { Pie } from "@ant-design/plots";
import { useSelector } from "react-redux";

const DashboardAttendace = () => {
  const [monthWiseAttndance, setMonthWiseAttendanceData] = useState([]);
  // const studentId = Cookies.get("userId");
  const studentInfo = useSelector((state) => state.studentInfo.studentInfo);
  const studentId = studentInfo?.studentId;

  const currentDate = new Date();
  const presentMonth = currentDate.getMonth() + 1;
  console.log("presented", presentMonth, currentDate);
  const {
    data: getMonthWiseAttendance,
    isLoading: getIsloadingAttendace,
    isError: getIsErrorAttendace,
  } = useGetMonthWiseAttendanceQuery({
    studentId: studentId,
    presentMonth: presentMonth,
  });

  console.log("dash-col", getMonthWiseAttendance);

  useEffect(() => {
    if (getMonthWiseAttendance) {
      setMonthWiseAttendanceData(getMonthWiseAttendance);
    }
  }, [getMonthWiseAttendance]);

  const [currentMonth, setCurrentMonth] = useState("");

  useEffect(() => {
    const currentDate = new Date();
    const options = { month: "long" };
    const currentMonthName = currentDate.toLocaleDateString(undefined, options);
    setCurrentMonth(currentMonthName);
  }, []);
  const chartData = [
    { type: "Total Classes", value: monthWiseAttndance.totalClasses },
    { type: "Present Classes", value: monthWiseAttndance.presentedClasses },
    { type: "Absent Classes", value: monthWiseAttndance.absentClasses },
  ];

  if (getIsloadingAttendace) {
    return (
      <div className="centered_spin">
        <Spin />
      </div>
    );
  }

  if (getIsErrorAttendace) {
    return (
      <div
      // className="ErrorContainer"
      >
        <EmptyComponent />
      </div>
    );
  }
  // "schoolWorkingDays": 0,
  // "totalClasses": 0,
  // "presentedClasses": 0,
  // "absentClasses": 0,
  // "presentPercentage": "NaN"
  const data = [
    {
      type: "Present",
      // value: monthWiseAttndance?.presentedClasses,
      value: getMonthWiseAttendance.data.presentedClasses,
    },
    {
      type: "Absent",
      // value: monthWiseAttndance.absentClasses,
      value: getMonthWiseAttendance.data.absentClasses,
    },
  ];

  const config = {
    data,
    angleField: "value",
    colorField: "type",
    radius: 1,
    innerRadius: 0.6,
    color: ["#172b4d", "#90c5f5"],
    label: {
      type: "inner",
      offset: "-50%",
      // content: "Total",
      style: {
        textAlign: "center",
        fontSize: 12,
      },
    },
    interactions: [
      {
        type: "element-selected",
      },
      {
        type: "element-active",
      },
    ],
    legend: {
      position: "bottom", // Change the legend position to top
      itemMarginBottom: 8,
      itemName: {
        style: {
          fontSize: 12,
        },
      },
      offsetY: -5, // Adjust the vertical offset to move the legend closer to the pie chart
    },
  };
  return (
    <>
      <div className="attendace common-bgs ">
        <h5> Attendace : {currentMonth} </h5>
        <div className="attendace-card">
          <div className="atednace-content ">
            {/* <p>Working Days: {monthWiseAttndance?.schoolWorkingDays}</p>
            <p>Present Classes : {monthWiseAttndance?.presentedClasses}</p>

            <p>Absent Classes : {monthWiseAttndance.absentClasses}</p>
            <h5 style={{ marginTop: "5px" }}>
              {" "}
              Total Classes : {monthWiseAttndance.totalClasses}
            </h5> */}
            {/* <p>Working Days: {getMonthWiseAttendance.data.schoolWorkingDays}</p> */}
            <p>
              Present Classes : {getMonthWiseAttendance.data.presentedClasses}
            </p>

            <p>Absent Classes : {getMonthWiseAttendance.data.absentClasses}</p>

            <p>
              Present Percentage :{" "}
              {getMonthWiseAttendance.data.presentPercentage == "NaN"
                ? 0
                : getMonthWiseAttendance.data.presentPercentage}{" "}
              %
            </p>
            <h5 style={{ marginTop: "5px" }}>
              {" "}
              Total Classes : {getMonthWiseAttendance.data.totalClasses}
            </h5>
          </div>
          <div className="atednace-chat">
            {/* <Progress
              strokeWidth={10}
              strokeColor="#172b4d"
              type="circle"
              size="medium"
              percent={monthWiseAttndance.totalClasses}
            /> */}
            <Pie
              {...config}
              style={{ width: 180, height: 180, marginBottom: "0px" }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardAttendace;
