import React from "react";
import { Schoolname } from "../../components";
import Library from '../../components/LibraryManagement/Library'
const Librarys = () => {
  return (
    <main>
      <Schoolname />
      <Library />
    </main>
  );
};

export default Librarys;
