import { useState } from "react";
import {
  useGetRentedBooksQuery,
  useMissingBookMutation,
} from "../../../../RTKQuery/rtkLibrary";
import {
  Button,
  Col,
  Empty,
  Modal,
  Row,
  Space,
  Spin,
  notification,
} from "antd";
import {
  CheckCircleFilled,
  DislikeOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import EmptyComponent from "../../../EmptyComponent/EmptyComponent";
import Cookies from "js-cookie";
import "./RentBooks.scss";
import { useSelector } from "react-redux";
const RentBooks = () => {
  const [isRequestModalVisible, setRequestModalVisible] = useState(false);
  const [idRentalId, setIdRentalId] = useState();
  // const studentInfo = Cookies.get("studentInfo")
  //   ? JSON.parse(Cookies.get("studentInfo"))
  //   : [];
  const studentInfo = useSelector((state) => state.studentInfo.studentInfo);
  const userId = studentInfo?.rollNo;
  const { data, isLoading, isError, refetch } = useGetRentedBooksQuery({
    userId: userId,
    userType: "Student",
  });
  const [missingBookMutation, { error: missingBookCreateError }] =
    useMissingBookMutation();
  const GetRentedBooks = data?.data?.rented;

  if (isLoading) {
    return (
      <div className="centered_spin">
        <Spin />
      </div>
    );
  }

  if (isError) {
    return (
      <div className="ErrorContainer">
        <EmptyComponent />
      </div>
    );
  }
  const handleMissingReport = (rentedBook) => {
    setIdRentalId(rentedBook?.rentalId);
    setRequestModalVisible(true);
  };

  const handleRequestOk = () => {
    missingBookMutation(idRentalId).then((res) => {
      let errorMessage = "";
      if (res.error && res.error.status === 500) {
        errorMessage = res.error.data.message;
      }
      if (errorMessage) {
        errorMessage = missingBookCreateError?.data?.message;
      }
      if (errorMessage) {
        notification.error({
          message: "Failed",
          placement: "bottomRight",
          description: `${errorMessage}`,
          icon: <DislikeOutlined style={{ color: "red" }} />,
        });
      } else {
        setRequestModalVisible(false);
        notification.success({
          message: "Book Missing Report Successfully Created.",
          placement: "bottomRight",
        });
        refetch();
      }
    });
  };

  const handleRequestCancel = () => {
    setRequestModalVisible(false);
  };
  return (
    <div>
      {GetRentedBooks.length === 0 ? (
        <div
          style={{
            paddingTop: "70px",
            paddingBottom: "40px",
            backgroundColor: "#f0f0f0",
          }}
        >
          {" "}
          <EmptyComponent />
        </div>
      ) : (
        <div className=" main-bg mainContainer  rent-cards-header">
          {GetRentedBooks?.map((rentedBook, index) => {
            const { book, rentalId, image } = rentedBook;
            return (
              <div key={rentedBook.rentalId} className="card-container">
                <div className="image">
                  <img src={book.image} alt="CardImage" />
                </div>
                <div className="right-side">
                  <h3>{book?.bookName}</h3>
                  <h5>ISBN: {book?.isbn}</h5>
                  <h5>Price - {book?.bookRate} /-</h5>
                  <Button
                    // onClick={() => showMissingbookModal(rentalId)}
                    onClick={() => handleMissingReport(rentedBook)}
                    className="miss-book"
                  >
                    Report
                  </Button>
                </div>
              </div>
            );
          })}
        </div>
      )}

      <Modal
        open={isRequestModalVisible}
        onCancel={handleRequestCancel}
        onOk={handleRequestOk}
        footer={null}
        title="Report"
      >
        <div style={{ marginBottom: "20px" }}>
          {/* <h3 className="high high2">Book Missing Information: </h3> */}

          <div className="datesInfo">
            <h4>
              <span className="high">DueDate:</span> {idRentalId?.dueDate}
            </h4>
            <h4>
              <span className="high">Book Price:</span>{" "}
              {idRentalId?.book?.bookRate} /-
            </h4>
            <h4>
              <span className="high">Fine Amount for Missing books:</span>
              {idRentalId?.missingBooksFineAmount}
            </h4>
            <h4>
              <span className="high">Per Day Fine Amount: </span>
              {idRentalId?.perDayFineAmount}
            </h4>
            <br />
            <p>
              Are you sure you want to report the book as missing? This action
              will notify the library about the missing book.
            </p>
          </div>
        </div>
        <Row className="my-row" gutter={6}>
          <Col span={12} align="end">
            <Space direction="vertical" size="large">
              <Button
                htmlType="submit"
                className="submitButton"
                onClick={handleRequestOk}
              >
                Save
              </Button>
            </Space>
          </Col>
          <Col span={12}>
            <Space direction="vertical" size="large">
              <Button
                onClick={handleRequestCancel}
                style={{ backgroundColor: "red", color: "white" }}
              >
                Cancel
              </Button>
            </Space>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};
export default RentBooks;
