
import { createSlice } from "@reduxjs/toolkit";

const studentSlice = createSlice({
  name: "student",
  initialState: {
    selectedStudentId: null,
  },
  reducers: {
    setSelectedStudentId: (state, action) => {
      state.selectedStudentId = action.payload;
    },
  },
});

export const { setSelectedStudentId } = studentSlice.actions;
export default studentSlice.reducer;
