import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Col,
  Dropdown,
  Menu,
  Select,
  message,
  notification,
} from "antd";
import {
  DownOutlined,
  EditOutlined,
  DeleteOutlined,
  LeftOutlined,
  HeartOutlined,
} from "@ant-design/icons";
import Cookies from "js-cookie";
import "./Wishlist.scss";
import { useGetWishListQuery } from "../../../RTKQuery/rtkEdpediaStore";
import { useNavigate } from "react-router-dom";

const { Meta } = Card;
const EdpediaStoreService = [];
const { Option } = Select;

const Address = () => {
  const navigate = useNavigate();
  const userId = Cookies.get("userId");
  const studentInfo = Cookies.get("studentInfo")
    ? JSON.parse(Cookies.get("studentInfo"))
    : [];
  const studentId = studentInfo?.rollNo;
  // const [addressList, setAddressList] = useState([]);
  const [visible, setVisible] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState([]);
  const [selectedValueQuantity, setSelectedValueQuantity] = useState("1");
  const [sizeState, setSizeState] = useState(null);
  const {
    data: wishList,
    isLoading,
    isError,
    refetch,
  } = useGetWishListQuery(studentId);
  const handleSelectChange = (value) => {
    setSelectedValueQuantity(value);
  };

  const sizeSelectionFunct = (sizes) => {
    setSizeState(sizes?.sizeId);
  };

  //   const [deleteResource, { isLoading: isDeleting }] =
  //     useLazyRemoveAddressQuery();

  // const handleDelete = async (id) => {
  //   try {
  //     const response = await deleteResource(id);
  //     if (response?.error) {
  //       console.log(response?.error?.data?.message);
  //       notification.error({
  //         placement: "bottomRight",
  //         message: `${response?.error?.data?.message} `,
  //         icon: <DislikeOutlined style={{ color: "red" }} />,
  //       });
  //     } else {
  //       refetch();
  //       notification.success({
  //         message: `${response?.data?.data}`,
  //         placement: "bottomRight",
  //       });
  //     }
  //   } catch (error) {
  //     console.error("An error occurred:", error);
  //   } finally {
  //     refetch();
  //   }
  // };

  const handleBack = () => {
    navigate("/edpediaStore");
  };

  return (
    <main>
      <section className="wishlist-container">
        <div className="back-container">
          <Button className="button" onClick={handleBack}>
            <LeftOutlined />
          </Button>
          <h1>WishList</h1>
        </div>
        <div>
          {wishList?.data?.products?.map((product) => {
            
            return (
              <>
                <div className="subcategory-container" key={product?.id}>
                  <Col span={6}>
                    <Card
                      hoverable
                      style={{ width: 240 }}
                      // cover={
                      //   <img
                      //     onClick={() => handleProduct(product)}
                      //     alt="example"
                      //     src={product?.images[0]}
                      //     className="card-img"
                      //   />
                      // }
                      cover={
                        <>
                          {product?.images?.map((i) => {
                            return (
                              <img alt="example" src={i} className="card-img" />
                            );
                          })}
                        </>
                      }
                    >
                      <div className="card-text">
                        {/* <HeartOutlined onClick={() => AddToWishList(product)} /> */}
                        <span className="text-name">
                          {product?.productName}
                        </span>
                        <span className="text-name">
                          {product?.description}
                        </span>
                        <span className="text-name">
                          {product?.brandName} Brand
                        </span>
                        <span className="text-percentage">25% - 50%</span>
                        <span className="text-price">
                          Price {product?.productPrices}
                        </span>
                        <span className="text-price">
                          After Discount {product?.afterDiscountAmount}
                        </span>
                        <span className="text-item-left">Only 10 left</span>
                        {product?.productSizes?.map((sizes) => {
                          return (
                            <>
                              {sizes?.quantity > 0 && (
                                <>
                                  <p>Available Sizes</p>
                                  <span
                                    onClick={() => sizeSelectionFunct(sizes)}
                                  >
                                    {" "}
                                    {sizes?.sizeName}
                                  </span>
                                </>
                              )}
                            </>
                          );
                        })}
                        <Select
                          value={selectedValueQuantity}
                          onChange={handleSelectChange}
                        >
                          <Option value="1">Quantity 1</Option>
                          <Option value="2">Quantity 2</Option>
                          <Option value="3">Quantity 3</Option>
                          <Option value="4">Quantity 4</Option>
                          <Option value="5">Quantity 5</Option>
                          <Option value="6">Quantity 6</Option>
                          <Option value="7">Quantity 7</Option>
                          <Option value="8">Quantity 8</Option>
                          <Option value="9">Quantity 9</Option>
                          <Option value="10">Quantity 10</Option>
                        </Select>
                        {product?.addToCart ? (
                          <>
                            <Button
                              className="add-card-button"
                              // onClick={() => AddToCart(product)}
                            >
                              Add to Cart
                            </Button>
                          </>
                        ) : (
                          <>
                            <Button
                              disabled
                              // className="add-card-button"
                              // onClick={() => AddToCart(product)}
                            >
                              Add to Cart
                            </Button>
                          </>
                        )}
                      </div>
                    </Card>
                  </Col>
                </div>
              </>
            );
          })}
        </div>
      </section>
    </main>
  );
};

export default Address;
