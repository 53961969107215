import Cookies from "js-cookie";
import { useGetReqBooksQuery } from "../../../../RTKQuery/rtkLibrary";
import BookImage from "../../../../assets/images/book image.jpg";
import "../MyAccount.scss";
import { Empty } from "antd";
import { useEffect, useState } from "react";
import EmptyComponent from "../../../EmptyComponent/EmptyComponent";
import { useSelector } from "react-redux";

const RequestedBooks = () => {
  // const studentInfo = Cookies.get("studentInfo")
  //   ? JSON.parse(Cookies.get("studentInfo"))
  //   : [];

  const studentInfo = useSelector(
    (state) => state.studentInfo.studentInfo
  );
  const studentId = studentInfo?.studentId;
  const userId = studentId;
  const [requestBookData, setRequestBookData] = useState([]);
  const { data, isLoading, isError } = useGetReqBooksQuery({
    userId: userId,
    userType: "Student",
  });

  useEffect(() => {
    if (data) {
      setRequestBookData(data?.data);
    }
  }, []);
  return (
    <div className="common-conmtainers mainContainer">
      {requestBookData && requestBookData.length == 0 ? (
        <div
          style={{
            paddingTop: "70px",
            paddingBottom: "40px",
            backgroundColor: "#f0f0f0",
          }}
        >
          {" "}
          <EmptyComponent />
        </div>
      ) : (
        <>
          {" "}
          <div className="rent-cards-header main-bg ">
            {requestBookData?.map((reqBook) => {
              const { book } = reqBook;
              return (
                <div key={reqBook.id} className="rent-card">
                  <div className="left-side">
                    <img src={BookImage} alt="CardImage" />
                  </div>
                  <div className="right-side">
                    <p> {book.bookName}</p>
                    <span>RequestedDate: {reqBook.requestedDate}</span>
                    <span>Status: {reqBook.status}</span>
                  </div>
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};
export default RequestedBooks;
