import React, { useEffect, useState } from "react";
// import { logout } from "../../assets/icons";
import {
  Button,
  Tooltip,
  Popover,
  Menu,
  Form,
  notification,
  Avatar,
  Dropdown,
  Select,
} from "antd";
import "./SchoolName.css";
// import image from "../../assets/images/images (1),school.jpg";
import { useViewNotificationQuery } from "../../RTKQuery/Notification";
import { useGetInstituteDetailsQuery } from "../../RTKQuery/rtkQuery";
// import { useUpdateReadStatusMutation } from "../../RTKQuery/Notification";
// import studentImg from "../../assets/images/girl.png";
import { BellOutlined } from "@ant-design/icons";
import edpedialogo from "../../../src/assets/images/times_logo.png";
import Cookies from "js-cookie";
import Config from "../../Utils/Config";
import NotificationModal from "./NotificationModal";
import girl from "../../assets/images/girl.png";
import { useNavigate } from "react-router-dom";
import { setSelectedStudentId } from "../../Store/StudentSlice";
import { todayTimeTableService } from "../../Services/TodayTimeTableService";
import { CheckCircleFilled } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { fetchStudentInfo, setStudentInfo } from "../../Store/StudentInfoSlice";
const { Option } = Select;

const Schoolname = () => {
  const branchCode = Cookies.get("branchCode");
  const userId = Cookies.get("userId");
  const [logoImage, setLogoImage] = useState(null);
  const [instLogoForm] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false);
  const navigate = useNavigate();
  const [notificationData, setNotificationData] = useState([]);
  const [popoverVisible, setPopoverVisible] = useState(false);
  const [readtStatus, setReadStatus] = useState(0);
  const { data, refetch: refetchNotificationData } = useViewNotificationQuery({
    userId: userId,
    userType: "STUDENT",
  });
  const studentsInfo = sessionStorage.getItem("studentsData");
  const [students, setStudents] = useState(JSON.parse(studentsInfo));
  const [shouldRenderDropdown, setShouldRenderDropdown] = useState(false);

  const [selectedStudent, setSelectedStudent] = useState(
    sessionStorage.getItem("activeStudent")
  );

  const dispatch = useDispatch();
  const entity = Cookies.get("entity");
  const ParentUserName = Cookies.get("userName");

  const studentInfo = useSelector((state) => state.studentInfo.studentInfo);

  const buttonStyle = {
    backgroundColor: popoverVisible ? "#172b4d" : "white",
    border: "none",
    borderRadius: "50%",
    color: popoverVisible ? "white" : "black",
    paddingBottom: 2,
  };

  useEffect(() => {
    if (entity === "STUDENT") {
      fetchStudentInfo();
    } else {
      const selectedStudent = sessionStorage.getItem("activeStudent");
      const studentData =
        JSON.parse(sessionStorage.getItem("studentsData")) || students;
      if (
        studentData === null ||
        studentData === undefined ||
        studentData === ""
      ) {
        getParentStudentListByAdar();
      } else {
        const student = studentData?.find(
          (item) => item.studentName === selectedStudent
        );
        dispatch(setStudentInfo(student));
      }
    }
  }, []);

  const fetchStudentInfo = () => {
    const studentId = Cookies.get("userId");
    todayTimeTableService
      .findStudentListByStudentId(studentId)
      .then((response) => {
        dispatch(setStudentInfo(response.data));
      })
      .catch((error) => {});
  };

  useEffect(() => {
    const unreadNotifications = notificationData.filter(
      (notification) => notification.readState == false
    );
    setReadStatus(unreadNotifications);
    refetchNotificationData();
  }, [notificationData]);

  useEffect(() => {
    if (data) {
      setNotificationData(data?.data);
    }
  }, [data]);

  const {
    data: instituteDatas,
    isLoading,
    isError,
  } = useGetInstituteDetailsQuery(branchCode);

  const handlePopoverVisibleChange = (visible) => {
    setPopoverVisible(visible);
  };

  const logoutFunction = () => {
    const cookieNames = Object.keys(Cookies.get());
    cookieNames.forEach((cookieName) => {
      Cookies.remove(cookieName);
    });
    window.location.replace(`${Config.loginUrl}`);
  };

  const handleUploadChange = (e) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      setFileList([file]);
      setLogoImage(URL.createObjectURL(file));
      onfinish({ fileList: [file] });
    }
  };

  const onfinish = (values) => {
    const { fileList } = values;
    const formData = new FormData();

    fileList.forEach((file) => {
      formData.append(`profilePic`, file);
    });

    const payload = formData;

    if (fileList.length > 0) {
      todayTimeTableService
        .UploadStudentImage(userId, payload)
        .then((res) => {
          instLogoForm.resetFields();
          setUploading(false);
          if (res.status === 200) {
            notification.success({
              description: `Profile Picture Sucessfully`,
              icon: (
                <CheckCircleFilled
                  style={{
                    color: "green",
                  }}
                />
              ),
              duration: 3,
              placement: "bottomRight",
            });
          }
        })
        .catch((err) => {
          setUploading(false);
          if (err) {
            notification.error({
              description:
                "An error occurred while Uploading Profile , try again later.",
              duration: 5,
              placement: "topRight",
            });
          }
        });
    } else {
      setUploading(false);
    }
  };

  const selectedStudentId = useSelector(
    (state) => state.student.selectedStudentId
  );

  // useEffect(() => {
  //   if (entity === "PARENT") {
  //     dispatch(fetchStudentInfo(selectedStudentId));
  //   } else if (entity === "STUDENT") {
  //     dispatch(fetchStudentInfo(userId));
  //   }
  // }, [selectedStudentId, entity]);

  const handlechange = (value, students) => {
    // const studentsList = sessionStorage.getItem()
    setSelectedStudent(value);
    sessionStorage.setItem("activeStudent", value);
    const student = students?.find((item) => item.studentName === value);
    dispatch(setStudentInfo(student));
    // dispatch(fetchStudentInfo(studentId));
    // setSelectedStudentId(studentId);
    // // dispatch(setSelectedStudentId(studentId));
  };

  // useEffect(() => {
  //   getParentStudentListByAdar();
  // }, []);

  // useEffect(() => {
  //   const studentName = sessionStorage.getItem("activeStudent");
  //   if (studentName === null || undefined || "") {
  //     getParentStudentListByAdar();
  //   } else {
  //     if (students) {
  //       handlechange(studentName, students);
  //     }
  //   }
  // }, [students]);

  const studentData =
    JSON.parse(sessionStorage.getItem("studentsData")) || students;

  // const getParentStudents = () => {
  //   todayTimeTableService
  //     .parentFind(ParentUserName)
  //     .then((res) => {
  //       setStudents(res?.data);

  //     })

  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  // useEffect(() => {
  //   getParentStudents()
  // },[])

  const getParentStudentListByAdar = () => {
    todayTimeTableService
      .parentFind(ParentUserName)
      .then((res) => {
        setStudents(res?.data);
        sessionStorage.setItem("studentsData", JSON.stringify(res?.data));
        handlechange(res?.data[0]?.studentName, res.data);
        // if (
        //   sessionStorage.getItem("activeStudent") === null ||
        //   undefined ||
        //   ""
        // ) {
        //   handlechange(res?.data[0]?.studentName, res.data);
        //   sessionStorage.setItem("activeStudent", res?.data[0]?.studentName);
        // } else {
        //   handlechange(sessionStorage.getItem("activeStudent", res.data));
        // }
      })

      .catch((err) => {
        console.log(err);
      });
  };

  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const menu = (
    <Menu>
      <Menu.Item key="0" onClick={() => navigate("/profile")}>
        Profile
      </Menu.Item>

      <Menu.Item key="2" onClick={logoutFunction}>
        Log Out
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="schoolNameContainer">
      <div style={{ display: "flex" }}>
        <img
          style={{
            height: 50,
            width: 50,
            marginRight: 10,
            marginLeft: 5,
            borderRadius: "50%",
            textAlign: "center",
          }}
          src={instituteDatas?.data?.instLogo}
          alt="Institute image"
        />
        <h1 style={{ fontSize: 20, marginTop: 10 }}>
          {instituteDatas?.data?.instituteName}
        </h1>
      </div>

      <div className="dashboard-img-items flexBox-sty">
        {students?.length > 1 && (
          <div className="studentDropdown">
            <Select
              showSearch
              placeholder="Select a student"
              optionFilterProp="children"
              value={selectedStudent}
              onChange={(value) => handlechange(value, students)}
              style={{ width: 200 }}
            >
              {studentData?.map((student) => (
                <Option key={student.studentId} value={student.studentName}>
                  {capitalizeFirstLetter(student.studentName)}
                </Option>
              ))}
            </Select>
          </div>
        )}

        <Tooltip title="Notifications" className="notificationTooltip">
          <Popover
            open={popoverVisible}
            onVisibleChange={handlePopoverVisibleChange}
            content={
              <NotificationModal
                notificationData={notificationData}
                refetchNotificationData={refetchNotificationData}
              />
            }
            trigger="click"
            placement="bottom"
            width={700}
            title="Notification"
          >
            <Button
              style={buttonStyle}
              icon={<BellOutlined style={{ fontSize: "20px" }} />}
            />
            <span className="notification_number">{readtStatus?.length}</span>
          </Popover>
        </Tooltip>
        {/* <Tooltip title="Logout">
          <Button
            onClick={logoutFunction}
            style={{
              backgroundColor: "white",
              border: "none",
              marginTop: "-10px",
            }}
          >
            <img src={logout} alt="logout" style={{ width: 25, height: 20 }} />
          </Button>
        </Tooltip> */}
        <div>
          {/* <Form form={instLogoForm} onFinish={onfinish}>
            <Form.Item name="instLogo">
              <div onClick={handleImageClick} style={{ cursor: "pointer" }}>
                {studentData?.imageUrl ? (
                  <img
                    src={logoImage || studentData?.imageUrl}
                    alt="Uploaded Logo"
                    className="studentprofile-img"
                    loading="lazy"
                    style={{
                      width: 40,
                      height: 40,
                      borderRadius: "50%",
                      marginLeft: 15,
                      marginBlock: 5,
                    }}
                  />
                ) : (
                  <img
                    src={studentImg}
                    style={{ width: 45, height: 45 }}
             
                    alt="Default Image"
                  />
                )}
              </div>
            </Form.Item>
   
            <input
              id="uploadInput"
              type="file"
              accept="image/*"
              style={{ display: "none" }}
              onChange={handleUploadChange}
            />
          </Form> */}

          <Dropdown overlay={menu} trigger={["hover"]}>
            <Avatar
              src={studentInfo ? studentInfo?.imageUrl : girl}
              alt="user"
            />
          </Dropdown>
        </div>
        {/* <Dropdown overlay={menu} trigger={["hover"]}>
          <span className="admin-sp cursor-style">Profile</span>
        </Dropdown> */}
      </div>
    </div>
  );
};

export default Schoolname;
