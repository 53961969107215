import React, { useEffect, useState } from "react";
import {
  Menu,
  Pagination,
  Input,
  Tabs,
  Button,
  Empty,
  Card,
  Col,
  Row,
  Spin,
} from "antd";
import "./Authors.scss";
import "../HomePage/LibraryHome.scss";

import { LeftOutlined } from "@ant-design/icons";
import {
  useGetAuthorsQuery,
  useGetAuthorsFirstAlphabetQuery,
} from "../../../RTKQuery/rtkLibrary";
import ViewAauthorBooks from "./ViewAauthorBooks";
import EmptyComponent from "../../EmptyComponent/EmptyComponent";
// import AuthorsCard from "../../components/AuthorsCard";
const { TabPane } = Tabs;
const { Search } = Input;
const tabsData = [
  {
    tabName: "A",
    key: "1",
    tabContent: "Tab A",
  },
  {
    tabName: "B",
    key: "2",
    tabContent: "Tab B",
  },
  {
    tabName: "C",
    key: "3",
    tabContent: "Tab C",
  },
  {
    tabName: "D",
    key: "4",
    tabContent: "Tab D",
  },
  {
    tabName: "E",
    key: "5",
    tabContent: "Tab E",
  },
  {
    tabName: "F",
    key: "6",
    tabContent: "Tab F",
  },
  {
    tabName: "G",
    key: "7",
    tabContent: "Tab G",
  },
  {
    tabName: "H",
    key: "8",
    tabContent: "Tab H",
  },
  {
    tabName: "I",
    key: "9",
    tabContent: "Tab I",
  },
  {
    tabName: "J",
    key: "10",
    tabContent: "Tab J",
  },
  {
    tabName: "K",
    key: "11",
    tabContent: "Tab K",
  },
  {
    tabName: "L",
    key: "12",
    tabContent: "Tab L",
  },
  {
    tabName: "M",
    key: "13",
    tabContent: "Tab M",
  },
  {
    tabName: "N",
    key: "14",
    tabContent: "Tab N",
  },
  {
    tabName: "O",
    key: "15",
    tabContent: "Tab O",
  },
  {
    tabName: "P",
    key: "16",
    tabContent: "Tab P",
  },
  {
    tabName: "Q",
    key: "17",
    tabContent: "Tab Q",
  },
  {
    tabName: "R",
    key: "18",
    tabContent: "Tab R",
  },
  {
    tabName: "S",
    key: "19",
    tabContent: "Tab S",
  },
  {
    tabName: "T",
    key: "20",
    tabContent: "Tab T",
  },
  {
    tabName: "U",
    key: "21",
    tabContent: "Tab U",
  },
  {
    tabName: "V",
    key: "22",
    tabContent: "Tab V",
  },
  {
    tabName: "W",
    key: "23",
    tabContent: "Tab W",
  },
  {
    tabName: "X",
    key: "24",
    tabContent: "Tab X",
  },
  {
    tabName: "Y",
    key: "25",
    tabContent: "Tab Y",
  },
  {
    tabName: "Z",
    key: "26",
    tabContent: "Tab Z",
  },
];

export default function Authors({ setActiveTabKey }) {
  const [selectedTab, setSelectedTab] = useState(null);
  const [searchedText, setSearchedText] = useState("");
  const [selectedAuthor, setSelectedAuthor] = useState(null);
  const [book, setBook] = useState(null);
  const { data, isLoading, isError } = useGetAuthorsQuery(searchedText);
  const { data: GetAuthorsFirstAlphabetData } =
    useGetAuthorsFirstAlphabetQuery(selectedTab);
  const GetAuthors = data?.data?.authors;
  const GetAuthorCount = data?.data?.authorCount;
  const nameWiseAuthorsData = GetAuthorsFirstAlphabetData?.data?.authors;
  const [showCards, setShowCards] = useState(true);

  if (isLoading) {
    return (
      <div className="centered_spin">
        <Spin />
      </div>
    );
  }

  if (isError) {
    return (
      <div className="ErrorContainer">
        <EmptyComponent />
      </div>
    );
  }
  const handleGoBack = () => {
    setSelectedAuthor(null); // Reset selected author
    setShowCards(true); // Switch back to showing author cards
  };
  const handleTabchange = (key) => {
    if (key !== "all") {
      setSelectedTab(key);
    }
  };

  const handleClickAuthorCard = (author) => {
    setSelectedAuthor(author?.name);
    setBook(true);
    setShowCards(false);
  };

  return (
    <div className=" ">
      {showCards && (
        <>
          <div className="first-item-container">
            <div className="book-total-container">
              <h3 className="books">Authors</h3>
              <div className="books-count-container">
                <p className="books-count"> {GetAuthorCount}</p>
              </div>
            </div>
            {/* <Search
              placeholder="Search by Author name"
              style={{ width: 490 }}
              onChange={(event) => setSearchedText(event.target.value)}
            /> */}
          </div>
          <div className="horizontal-scroll-container">
            <div className="second-item-container main-bg">
              <Tabs defaultActiveKey="all" onChange={handleTabchange}>
                <TabPane tab={<span className="tab">All</span>} key="all">
                  <div className="authors-cards ">
                    {GetAuthors?.map((author) => {
                      return (
                        <div
                          key={author.id}
                          className="card-container"
                          onClick={() => handleClickAuthorCard(author)}
                        >
                          <img
                            // src={author.image}
                            src={author.image}
                            alt="img"
                            className="card-image"
                          />
                          <div className="author-card-content-container">
                            <p className="authorName">{author.name}</p>
                            <p>Phone: {author.mobile}</p>
                            <p>{author.email}</p>
                            <p>
                              Published Books: {author.numbersOfBooksPublished}
                            </p>
                            <p>Experience: {author.experienceInWriting}</p>
                            <div className="icon-container">
                              <i className="star-icon"></i>
                              <i className="star-icon"></i>
                              <i className="star-icon"></i>
                              <i className="star-icon"></i>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  {/* <div className="pagination-container">
              <Pagination defaultCurrent={1} total={50} />
            </div> */}
                </TabPane>
                {/* {tabsData.map((tabs) => (
                  <TabPane tab={tabs.tabName} key={tabs.tabName}>
                    <div className="authors-cards">
                      {nameWiseAuthorsData?.map((author) => {
                        return (
                          <div
                            key={author.id}
                            className="card-container"
                            onClick={() => handleClickAuthorCard(author)}
                          >
                            <img
                              // src={author.image}
                              src={author.image}
                              alt="img"
                              className="card-image"
                            />
                            <div className="author-card-content-container">
                              <p className="authorName">{author.name}</p>
                              <p>Phone: {author.mobile}</p>
                              <p>{author.email}</p>
                              <p>
                                Published Books:{" "}
                                {author.numbersOfBooksPublished}
                              </p>
                              <p>Experience: {author.experienceInWriting}</p>
                              <div className="icon-container">
                                <i className="star-icon"></i>
                                <i className="star-icon"></i>
                                <i className="star-icon"></i>
                                <i className="star-icon"></i>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </TabPane>
                ))} */}
                {tabsData.map((tabs) => (
                  <TabPane tab={tabs.tabName} key={tabs.tabName}>
                    <div className="authors-cards">
                      {nameWiseAuthorsData?.length > 0 ? (
                        nameWiseAuthorsData.map((author) => (
                          <div
                            key={author.id}
                            className="card-container"
                            onClick={() => handleClickAuthorCard(author)}
                          >
                            <img
                              // src={author.image}
                              src={author.image}
                              alt="img"
                              className="card-image"
                            />
                            <div className="author-card-content-container">
                              <p className="authorName">{author.name}</p>
                              <p>Phone: {author.mobile}</p>
                              <p>{author.email}</p>
                              <p>
                                Published Books:{" "}
                                {author.numbersOfBooksPublished}
                              </p>
                              <p>Experience: {author.experienceInWriting}</p>
                              <div className="icon-container">
                                <i className="star-icon"></i>
                                <i className="star-icon"></i>
                                <i className="star-icon"></i>
                                <i className="star-icon"></i>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div
                          className="empty-list-message"
                          style={{
                            marginTop: "70px",
                            paddingBottom: "40px",
                            marginLeft: "300px",
                          }}
                        >
                          <EmptyComponent />
                        </div>
                      )}
                    </div>
                  </TabPane>
                ))}
              </Tabs>
            </div>
          </div>
        </>
      )}

      {!showCards && (
        <>
          {book && (
            <ViewAauthorBooks
              authorName={selectedAuthor}
              onGoBack={handleGoBack}
              onShowAuthorCards={() => setShowCards(true)}
            />
          )}
        </>
      )}
    </div>
  );
}
