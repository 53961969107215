import React, { useEffect, useState } from "react";
import { mybirthday } from "../../assets/images";
import {
  useAddUserTransportMutation,
  useGetAllRoutesInTransportQuery,
} from "../../RTKQuery/rtkQuery";
import {
  DownloadOutlined,
  DislikeOutlined,
  CheckCircleFilled,
} from "@ant-design/icons";
import Cookies from "js-cookie";
import { Button, Input, Spin, Row, notification } from "antd";
import EmptyComponent from "../EmptyComponent/EmptyComponent";
import "./Transports.scss";
import { Form, Select } from "antd";
import TransportDetails from "./TransportDetails/TransportDetails";
import { useScroll } from "framer-motion";
import Footer from "../Footer/Footer";
import { useSelector } from "react-redux";
const { Option } = Select;

function Transport() {
  const [TransportForm] = Form.useForm();
  const [transportDetailsData, setTransportDetailsData] = useState(null);
  const [pickupPoints, setPickupPoints] = useState([]);
  const [selectedRoute, setSelectedRoute] = useState(null);
  const [userRouteDetails, setUserRouteDetails] = useState([]);

  // const studentInfo = Cookies.get("studentInfo")
  //   ? JSON.parse(Cookies.get("studentInfo"))
  //   : [];
  const studentInfo = useSelector((state) => state.studentInfo.studentInfo);

  const classId = studentInfo?.classId;
  const studentRollNo = studentInfo?.rollNo;
  const { data, isLoading, isError } = useGetAllRoutesInTransportQuery();
  const [addUserTransport] = useAddUserTransportMutation();
  const [selectedPickupPoint, setSelectedPickupPoint] = useState(null);
  const routesData = data?.data;

  const handleChangePickUpPoint = (value) => {
    setSelectedPickupPoint(value);
    TransportForm.setFieldValue("pickuppointId", value);
  };

  useEffect(() => {
    if (!selectedRoute && routesData && routesData.length > 0) {
      const initialRoute = routesData[0];

      setPickupPoints(initialRoute.pickupPoints);
      setSelectedRoute(initialRoute.routeId);
      setTransportDetailsData(initialRoute);
      setSelectedPickupPoint(initialRoute.pickupPoints[0]?.id || null);
      TransportForm.setFieldsValue({
        routeId: initialRoute.routeId,
        pickuppointId: initialRoute?.pickupPoints[0]?.id || null
      });
    }
  }, [routesData, selectedRoute]);

  if (isLoading) {
    return (
      <div className="centered_spin">
        <Spin />
      </div>
    );
  }

  if (isError) {
    return (
      <div className="ErrorContainer">
        <EmptyComponent />
      </div>
    );
  }

  const onFinish = (values) => {
    const payload = {
      routeId: selectedRoute,
      userType: "Student",
      pickupId: selectedPickupPoint,
      enrolledUsers: [studentRollNo],
    };
    addUserTransport(payload)
      .then((res) => {
        notification.success({
          message: "Route Selected Successfully",

          icon: (
            <CheckCircleFilled
              style={{
                color: "green",
              }}
            />
          ),
          duration: 3,
          placement: "bottomRight",
        });
        // setUserRouteDetails(res.data.data);
      })
      .catch((error) => console.log(error));
  };

  const handleRouteChange = (value) => {
    const selectedRouteData = routesData?.find(
      (route) => route.routeId === value
    );

    if (selectedRouteData) {
      setPickupPoints(selectedRouteData.pickupPoints);
      setSelectedRoute(selectedRouteData.routeId);
      TransportForm.setFieldValue("routeId", selectedRouteData?.routeId);
      setTransportDetailsData(selectedRouteData);
    }
  };

  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  return (
    <>
      <div className="transport-wrapper">
        <div className="main-bg" id="culture">
          <h3>Transport</h3>
          <Form form={TransportForm} onFinish={onFinish}>
            <Row>
              <Form.Item
                name="routeId"
                rules={[{ required: true, message: "Please select an option" }]}
                label="Select Route"
              >
                <Select
                  placeholder="Select a Route"
                  onChange={handleRouteChange}
                  value={selectedRoute}
                  defaultValue={routesData[0]?.routeId}
                >
                  {routesData?.map((route) => (
                    <Option key={route.routeId} value={route.routeId}>
                      {capitalizeFirstLetter(route.fromStop)} to{" "}
                      {capitalizeFirstLetter(route.toStop)}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Row>
            <TransportDetails transportDetailsData={transportDetailsData} />
            <Row>
              <Form.Item
                name="pickuppointId"
                rules={[{ required: true, message: "Please select an option" }]}
                label="Select Pickup Point"
              >
                <Select
                  value={selectedPickupPoint}
                  placeholder="Select Pickup Point"
                  onChange={handleChangePickUpPoint}
                  defaultValue={routesData[0]?.pickupPoints[0]?.id}
                >
                  {pickupPoints?.map((pickupPoint) => (
                    <Option key={pickupPoint.id} value={pickupPoint.id}>
                      {capitalizeFirstLetter(pickupPoint.pickupName)}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item>
                {selectedPickupPoint === null ? (
                  <>
                    <Button
                      disabled
                      type="primary"
                      htmlType="submit"
                      style={{ marginLeft: 30 }}
                    >
                      Submit
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{ marginLeft: 30 }}
                    >
                      Submit
                    </Button>
                  </>
                )}
              </Form.Item>
            </Row>
          </Form>

          {/* <div className="containers">
            <h1>Student Selected Routes</h1>
            {userRouteDetails.map((message, index) => (
              <span key={index}>
                {message}
                <br />
              </span>
            ))}
          </div> */}
        </div>
      </div>
    </>
  );
}

export default Transport;
