import React, { useState, useEffect } from "react";
import { Button, Modal, Radio, Space } from "antd";
import axios from "axios";
import Cookies from "js-cookie";
import "./Study.css";
import { DownloadOutlined } from "@ant-design/icons";
const FileView = ({ view, setView, fileData, downloadExamPaper }) => {
  const [selectedFileIndex, setSelectedFileIndex] = useState(0);
  const [fileContent, setFileContent] = useState(null);
  const accessToken = Cookies.get("accessToken");
  const handleCancel = () => {
    setView(false);
  };

  const handleFileChange = (e) => {
    setSelectedFileIndex(e.target.value);
  };

  useEffect(() => {
    const fetchFileContent = async () => {
      try {
        const response = await axios.get(
          `https://api.edpedia.co/v1.0/instAdmin/api/storage/viewFile?url=${fileData[selectedFileIndex]}`,

          {
            headers: {
              Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
            },
          }
        );
        setFileContent(response.data.data);
      } catch (error) {
        console.error("Error downloading question paper:", error);
      }
    };

    fetchFileContent();
  }, [selectedFileIndex, fileData]);

  const handleDownload = () => {
    downloadExamPaper(fileData[selectedFileIndex]);
  };

  return (
    <div>
      <Modal
        title="Files View"
        open={view}
        centered
        onCancel={handleCancel}
        footer={null}
        width={700}
        bodyStyle={{ maxHeight: "490px", overflowY: "auto", overflowX: "clip" }}
        // height={100}
        wrapClassName="sidebar-scroll-container"
        className="sidebar-scroll-container"
      >
        <div style={{ position: "relative", top: 0 }}>
          <Radio.Group onChange={handleFileChange} value={selectedFileIndex}>
            {fileData?.map((file, index) => (
              <Radio key={index} value={index}>
                {`File ${index + 1}`}
              </Radio>
            ))}
          </Radio.Group>
        </div>
        <div style={{ position: "absolute", right: "20px", top: "65px" }}>
          <Space direction="vertical" size="large" align="end">
            <Button
              className="submit-button "
              htmlType="submit"
              onClick={handleDownload}
            >
              <DownloadOutlined />
            </Button>
          </Space>
        </div>
        <div>
          <iframe
            src={fileContent}
            style={{ width: "100%", height: "500px", marginTop: "15px" }}
            title="File Preview"
          />
        </div>
      </Modal>
    </div>
  );
};

export default FileView;
