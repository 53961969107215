import React from 'react'
import { three } from "../../assets/images";
import {Card} from "antd"
const upComingExamsData = [
    {
      id: 1,
      date: "11-22-3333",
      image: three,
      subject: "Telugu",
      time: "10am to 12pm",
    },
    {
      id: 2,
      date: "22-33-444",
      image: three,
      subject: "Telugu",
      time: "10am to 12pm",
    },
    {
      id: 3,
      date: "11-22-3333",
      image: three,
      subject: "Telugu",
      time: "10am to 12pm",
    },
    {
      id: 4,
      date: "22-33-444",
      image: three,
      subject: "Telugu",
      time: "10am to 12pm",
    },
    {
      id: 4,
      date: "22-33-444",
      image: three,
      subject: "Telugu",
      time: "10am to 12pm",
    },
  ];

function DataUpComingExams() {
  return (
    <div className="dashboard-grid grid-sty gt2">
    {upComingExamsData.map((item) => {
      const { id, date, image, subject, time } = item;
      return (
        <Card style={{width:250,height:230}}
          key={id}
          cover={
            <div style={{width:250,height:230}} className="present-grid-item div-sty">
              <p>Date :{date}</p>
              <div className="present-single-item flex-sty">
                <div className="present-img">
                  <img src={image} alt="three" />
                </div>
                <div className="present-desc">
                  <h3>{item.subject}</h3>
                  <p>Time:{time}</p>
                </div>
              </div>

              <div className="present-single-item flex-sty">
                <div className="present-img">
                  <img src={item.image} alt="three" />
                </div>
                <div className="present-desc">
                  <h3>{subject}</h3>
                  <p>Time: {time}</p>
                </div>
              </div>
            </div>
          }
        ></Card>
      );
    })}
  </div>
  )
}

export default DataUpComingExams