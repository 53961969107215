import React, { useEffect } from "react";
import {
  Card,
  Image,
  Input,
  Button,
  Select,
  Row,
  Col,
  notification,
} from "antd";
import { DislikeOutlined } from "@ant-design/icons";
import { GirlUniform } from "../../../assets/icons";
import EmptyComponent from "../../EmptyComponent/EmptyComponent";
import "./CartProducts.scss";
import { BiRupee } from "react-icons/bi";
import {
  useGetListOfCartProductsQuery,
  useLazyRemoveFromCartProductQuery,
  useLazyClearFromCartProductAllQuery,
} from "../../../RTKQuery/rtkEdpediaStore";
import Cookies from "js-cookie";
const { Option } = Select;

const CartProducts = () => {
  const studentInfo = Cookies.get("studentInfo")
    ? JSON.parse(Cookies.get("studentInfo"))
    : [];
  const studentId = studentInfo?.rollNo;

  const {
    data: listOfCartProducts,
    isLoading,
    isError,
    refetch,
  } = useGetListOfCartProductsQuery(studentId);

  const [deleteResource, { isLoading: isDeleting }] =
    useLazyRemoveFromCartProductQuery();

  const [deleteCartClear, { isLoading: isclearing }] =
    useLazyClearFromCartProductAllQuery();

  const removeFromCartFun = async (item) => {
    const productId = item?.productId;
    try {
      const response = await deleteResource({ productId, studentId });
      if (response?.error) {
        notification.error({
          placement: "bottomRight",
          message: `${response?.error?.data?.message} Please Select`,
          icon: <DislikeOutlined style={{ color: "red" }} />,
        });
      } else {
        refetch();
        notification.success({
          message: `${response?.data?.data}`,
          placement: "bottomRight",
        });
      }
    } catch (error) {
      console.error("An error occurred:", error);
    } finally {
      refetch();
    }
  };

  const allClearFun = async () => {
    try {
      const response = await deleteCartClear(studentId);

      if (response?.error) {
       
        notification.error({
          placement: "bottomRight",
          message: `${response?.error?.data?.message}`,
          icon: <DislikeOutlined style={{ color: "red" }} />,
        });
      } else {
        refetch();
        notification.success({
          message: `${response?.data?.data}`,
          placement: "bottomRight",
        });
      }
    } catch (error) {
      console.error("An error occurred:", error);
    } finally {
      refetch();
    }
  };

  return (
    <div className="cart-product">
      <p
        style={{
          textAlign: "center",
          textDecoration: "underLine",
          cursor: "pointer",
          marginLeft: 150,
        }}
        onClick={() => allClearFun()}
      >
        Clear Cart
      </p>
      {listOfCartProducts?.data?.cartItems.length > 0 ? (
        <>
          {listOfCartProducts?.data?.cartItems.map((item) => {
            return (
              <>
                <Card>
                  <Row gutter={{ md: 6, lg: 8 }}>
                    <Col span={7}>
                      <Image
                        src={GirlUniform}
                        alt="Product"
                        className="product-image"
                        style={{ width: "250px", height: "250px" }}
                      />
                    </Col>
                    <Col span={10}>
                      <div className="product-info" key={item?.id}>
                        <h3>
                          {item?.productName} ({item?.brandName})
                        </h3>
                        <p>{item?.description}</p>
                        <p>Seller : Eaglemount Company</p>
                        <h4>25% - OFF</h4>
                        <div className="product-details">
                          <div className="price-quantity">
                            <p>Price: $20.00</p>
                            <Select
                              defaultValue="1"
                              className="quantity-select"
                            >
                              {[...Array(10)].map((_, index) => (
                                <Option
                                  key={index}
                                  value={(index + 1).toString()}
                                >
                                  {index + 1}
                                </Option>
                              ))}
                            </Select>
                          </div>
                        </div>
                        <div className="action-buttons">
                          <Button type="primary" className="move-to-wishlist">
                            Move to Wishlist
                          </Button>{" "}
                          <Button
                            onClick={() => removeFromCartFun(item)}
                            type="danger"
                            className="remove-from-cart"
                          >
                            Remove From Cart
                          </Button>
                        </div>
                      </div>
                    </Col>
                    <Col span={7}>
                      <div className="price-details-container">
                        <Button className="price-details-button">
                          {" "}
                          Price Details
                        </Button>
                        <div className="single-item">
                          <span>
                            Price : <BiRupee />
                            1500
                          </span>
                        </div>
                        <div className="single-item">
                          <span>
                            Deliver Charges : <BiRupee />
                            500
                          </span>
                        </div>
                        <div className="single-item">
                          <span>
                            Discount : <BiRupee />
                            -500
                          </span>
                        </div>

                        <div className="total single-item">
                          <span>
                            Total Amount :<BiRupee />
                            1500
                          </span>
                        </div>

                        <div className="saving">
                          <span>You saved 500 in this offer .</span>
                        </div>

                        <div className="delivery-time">
                          <span>Delivery in 2 Dec-10 Dec </span>
                          {/* <Button className="submitButton"> Enter Details</Button> */}
                        </div>
                      </div>
                    </Col>
                  </Row>
                  {/* <div className="product-card"> */}
                  {/* <Image
            src={GirlUniform}
            alt="Product"
            className="product-image"
            style={{ width: "250px", height: "250px" }}
          /> */}
                  {/* <div className="product-info">
            <h3>Girl Uniform</h3>
            <p>
              Description: Lorem ipsum dolor sit amet, consectetur adipiscing
              elit.
            </p>
            <p>Seller : Eaglemount Company</p>
            <h4>25% - OFF</h4>
            <div className="product-details">
              <div className="price-quantity">
                <p>Price: $20.00</p>
                <Select defaultValue="1" className="quantity-select">
                  {[...Array(10)].map((_, index) => (
                    <Option key={index} value={(index + 1).toString()}>
                      {index + 1}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
            <div className="action-buttons">
              <Button type="primary" className="move-to-wishlist">
                Move to Wishlist
              </Button>
              <Button type="danger" className="remove-from-cart">
                Remove
              </Button>
            </div>
          </div> */}
                  {/* </div> */}
                </Card>
              </>
            );
          })}
        </>
      ) : (
        <>
          <EmptyComponent />
        </>
      )}
    </div>
  );
};

export default CartProducts;
