import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import Cookies from "js-cookie";
export const getLatestActivityListApi = createApi({
  reducerPath: "getLatestActivityListApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://api.edpedia.co/v1.0/student/",
    prepareHeaders: (headers, { getState }) => {
      const accessToken = Cookies.get("accessToken");
      if (accessToken) {
        headers.set("Authorization", `Bearer ${accessToken}`);
      }
      headers.set("Content-Type", "application/json");
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getLatestActivity: builder.query({
      query: () => {
        return {
          url: "/api/activity/latestActivity",
          method: "GET",
        };
      },
    }),
    getSyllabus: builder.query({
      query: (classId) => {
        return {
          url: `/api/student/${classId}/getSyllabus`,
          method: "GET",
        };
      },
    }),
    getStudentInformation: builder.query({
      query: (studentId) => {
        return {
          url: `/api/student/${studentId}/find`,
          method: "GET",
        };
      },
    }),
    getTodayTimetable: builder.query({
      query: (classId) => {
        return {
          url: `/api/student/${classId}/getTodayTimetable`,
          method: "GET",
        };
      },
    }),

    getTodayStudentsBirthDay: builder.query({
      query: (classId) => {
        return {
          url: `/api/student/${classId}/todayStudentsBirthDay`,
          method: "GET",
        };
      },
    }),
    getTeachersSubject: builder.query({
      query: (studentId) => {
        return {
          url: `/api/student/${studentId}/getTeachersSubject`,
          method: "GET",
        };
      },
    }),
    createLeave: builder.mutation({
      query: (payload) => ({
        url: `/api/student/createStudentLeave`,
        method: "POST",
        body: payload,
      }),
    }),
    editLeave: builder.mutation({
      query: ({ payload, id }) => ({
        url: `/api/student/${id}/studentLeaveEdit`,
        method: "POST",
        body: payload,
      }),
    }),

    getStudentLeave: builder.query({
      query: () => {
        return {
          url: `/api/student/studentLeaveList`,
          method: "GET",
        };
      },
    }),
    getExam: builder.query({
      query: (classId) => {
        return {
          url: `/api/student/${classId}/exam?offSet=0&pageSize=10`,
          method: "GET",
        };
      },
    }),
    getAllStudyMaterials: builder.query({
      query: (studentId) => {
        return {
          url: `/api/student/${studentId}/getAllStudyMaterials`,
          method: "GET",
        };
      },
    }),
    getAllAssignments: builder.query({
      query: (studentId) => {
        return {
          url: `/api/student/${studentId}/getAllAssignments`,
          method: "GET",
        };
      },
    }),

    getResult: builder.query({
      query: (studentId) => {
        return {
          url: `/api/student/${studentId}/getResult`,
          method: "GET",
        };
      },
    }),
    getChapterStatus: builder.query({
      query: (classId) => {
        return {
          url: `/api/student/${classId}/getChapterStatus`,
          method: "GET",
        };
      },
    }),
    getStudentPosition: builder.query({
      query: (studentId) => {
        return {
          url: `/api/student/${studentId}/position`,
          method: "GET",
        };
      },
    }),
    getOverralSyllabusPercentage: builder.query({
      query: (studentId) => {
        return {
          url: `/api/student/${studentId}/overallSyllabus`,
          method: "GET",
        };
      },
    }),

    // api/student/{studentId}/position
    // submitAssignment: builder.mutation({
    //   query: ({ assignmentId, payload }) => ({
    //     url: `api/student/${assignmentId}/submitAssignment`,
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "multipart/form-data",
    //     },
    //     body: payload,
    //   }),
    // }),
    getHolidays: builder.query({
      query: (classId) => {
        return {
          url: `/api/student/holidays?offSet=0&sortField=holidayId&sortOrder=ASC&pageSize=300`,
          method: "GET",
        };
      },
    }),
    getInstituteDetails: builder.query({
      query: (branchCode) => ({
        url: `api/institute/getInstituteDetails?branchCode=${branchCode}`,
        method: "GET",
      }),
    }),
  }),
});
export const {
  useGetHolidaysQuery,
  // useSubmitAssignmentMutation,

  useGetChapterStatusQuery,
  useGetResultQuery,
  useGetStudentFeePaymentsQuery,
  useGetAllAssignmentsQuery,
  useEditLeaveMutation,
  useCreateLeaveMutation,
  useGetAllStudyMaterialsQuery,
  useGetExamQuery,
  useGetTeachersSubjectQuery,
  useGetTodayStudentsBirthDayQuery,

  useGetLatestActivityQuery,
  useGetSyllabusQuery,
  useGetTodayTimetableQuery,
  useGetStudentPositionQuery,
  useGetOverralSyllabusPercentageQuery,
  // useGetInstituteDetailsQuery
  useGetStudentLeaveQuery,
  useGetStudentInformationQuery,
} = getLatestActivityListApi;

export const getLatestActivityListApi2 = createApi({
  reducerPath: "getLatestActivityListApi2",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://api.edpedia.co/v1.0/superAdmin/",
    prepareHeaders: (headers, { getState }) => {
      const accessToken = Cookies.get("accessToken");
      if (accessToken) {
        headers.set("Authorization", `Bearer ${accessToken}`);
      }

      headers.set("Content-Type", "application/json");
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getInstituteDetails: builder.query({
      query: (branchCode) => ({
        url: `api/institute/getInstituteDetails?branchCode=${branchCode}`,
        method: "GET",
      }),
    }),
  }),
});
export const { useGetInstituteDetailsQuery } = getLatestActivityListApi2;
//transport

export const getLatestActivityListApi3 = createApi({
  reducerPath: "getLatestActivityListApi3",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://api.edpedia.co/v1.0/student/",
    prepareHeaders: (headers, { getState }) => {
      const accessToken = Cookies.get("accessToken");
      if (accessToken) {
        headers.set("Authorization", `Bearer ${accessToken}`);
      }

      headers.set("Content-Type", "application/json");
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getAllRoutesInTransport: builder.query({
      query: () => ({
        url: `/api/transport/getAllRoutes`,
        method: "GET",
      }),
    }),
    getAllPickupPointsInTransport: builder.query({
      query: () => ({
        url: `/api/transport/getAllPickupPoint`,
        method: "GET",
      }),
    }),
    addUserTransport: builder.mutation({
      query: ({ routeId, pickupId, userType, enrolledUsers }) => ({
        url: "/api/transport/addUserToTransport",
        method: "POST",
        body: { routeId, pickupId, userType, enrolledUsers },
      }),
    }),
  }),
});
export const {
  useGetAllRoutesInTransportQuery,
  useGetAllPickupPointsInTransportQuery,
  useAddUserTransportMutation,
} = getLatestActivityListApi3;
