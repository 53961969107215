import React, { useEffect, useState } from "react";
import { Popover } from "antd";
import {
  Bags,
  Stationery,
  StoreHome,
  TextBooks,
  Toys,
  Uniform,
  footWear,
} from "../../../assets/icons";
import "./Category.scss";
import {
  useGetCategoryListQuery,
  useGetSubCategoryListQuery,
  useGetSubCategoryProductsListQuery,
} from "../../../RTKQuery/rtkEdpediaStore";
const CategoryList = ({ setSelected, setSelectedSubId }) => {
  const [hoveredCategoryId, setHoveredCategoryId] = useState(null);
  const [subCategoryId, setSubCategoryId] = useState(null);

  const { data: categoryNameList } = useGetCategoryListQuery();
  const { data: subcategoryList } =
    useGetSubCategoryListQuery(hoveredCategoryId);

  const onCategoryHandle = (category) => {
    setSelected(category);
  };

  const onSubcategoryClick = (id) => {
    setSelectedSubId(id);
    setSubCategoryId(id);

    setSelected(id);
    
  };
  return (
    <div className="category-container">
      <div className="category-item" style={{ backgroundColor: "#172b4d" }}>
        <img src={StoreHome} alt="HomeIcon" className="img-icon" />
      </div>
      {categoryNameList?.data?.map((category) => (
        <div className="category-item" key={category?.id}>
          <Popover
            placement="bottom"
            title={category?.categoryName}
            content={
              <div className="subcategory-container">
                {subcategoryList?.data?.map((subcategory) => (
                  <h3
                    key={subcategory?.id}
                    onClick={() => onSubcategoryClick(subcategory.id)}
                    style={{ cursor: "pointer" }}
                  >
                    {subcategory?.subCategoryName}
                  </h3>
                ))}
              </div>
            }
            onMouseEnter={() => setHoveredCategoryId(category.id)}
            onMouseLeave={() => setHoveredCategoryId(null)}
          >
            <div style={{ display: "flex", flexDirection: "row" }}>
              <img src={Uniform} alt="HomeIcon" className="img-icon" />
              <h3>{category?.categoryName}</h3>
            </div>
          </Popover>
        </div>
      ))}
      {/* {subCategoryId !== null && (<SubCategory/>) } */}
    </div>
  );
};

export default CategoryList;
