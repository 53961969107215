import React from "react";
import { Dashboard, Schoolname } from "../../components";
const Home = () => {
  return (
    <main>
      <Schoolname />
      <Dashboard />
    </main>
  );
};

export default Home;
