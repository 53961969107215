import "../MyAccount.scss";
import {
  useGetRentedBooksQuery,
  useCancelMissingBookRequestMutation,
} from "../../../../RTKQuery/rtkLibrary";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import {
  CheckCircleFilled,
  DislikeOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  Empty,
  Spin,
  Button,
  Modal,
  Space,
  Col,
  Row,
  notification,
} from "antd";
import EmptyComponent from "../../../EmptyComponent/EmptyComponent";
import { useSelector } from "react-redux";
const MissingBooks = () => {
  // const studentInfo = Cookies.get("studentInfo")
  //   ? JSON.parse(Cookies.get("studentInfo"))
  //   : [];
  const studentInfo = useSelector((state) => state.studentInfo.studentInfo);
  const studentId = studentInfo?.rollNo;
  const userId = studentId;
  const { data, isLoading, isError, refetch } = useGetRentedBooksQuery({
    userId: userId,
    userType: "Student",
  });
  const [isOpen, setIsOpen] = useState(false);
  const [missingBooks, setMissingBooks] = useState(null);
  const [rentId, setRentId] = useState(null);
  useEffect(() => {
    if (data) {
      setMissingBooks(data?.data?.missing);
    }
  }, [data]);
  // const getReturnBooks = data?.data?.missing;

  const [CancelMissingBookRequest, { error: CancelMissingBookRequestError }] =
    useCancelMissingBookRequestMutation();
  if (isLoading) {
    return (
      <div className="centered_spin">
        <Spin />
      </div>
    );
  }

  if (isError) {
    return (
      <div className="ErrorContainer">
        <EmptyComponent />
      </div>
    );
  }
  const handleOnSave = () => {
    const rentalId = rentId;
    CancelMissingBookRequest(rentalId).then((res) => {
      let errorMessage = "";
      if (res.error && res.error.status === 500) {
        errorMessage = res.error.data.message;
      }
      if (errorMessage) {
        errorMessage = CancelMissingBookRequestError.data.message;
      }
      if (errorMessage) {
        notification.error({
          message: "Failed",
          placement: "bottomRight",
          description: `${CancelMissingBookRequestError?.data?.message}`,
          icon: <DislikeOutlined style={{ color: "red" }} />,
        });
      } else {
        setIsOpen(false);
        refetch();
        notification.success({
          message: `Cancel Missing Book Request Successfully.`,
          placement: "bottomRight",
          icon: (
            <CheckCircleFilled
              style={{
                color: "green",
              }}
            />
          ),
        });
      }
    });
  };
  const handleClickCancelBtn = (rental) => {
    setIsOpen(true);
    setRentId(rental?.rentalId);
  };
  const handleCancel = () => {
    setIsOpen(false);
  };
  return (
    <>
      <div className="conatiner-missing mainContainer">
        {missingBooks &&
          missingBooks.map((rental) => {
            const { book } = rental;
            return (
              <div key={rental.rentalId} className="rent-card">
                <div className="left-side">
                  <img alt="img" src={book.image} />
                </div>
                <div className="right-side">
                  <h4 style={{ fontSize: 15, fontWeight: 700 }}>
                    {book?.bookName}
                  </h4>
                  <span>{rental.publisher}</span>
                  <span>Fine Price - {rental.fineAmount}</span>
                  <span>Rent Date: {rental.rentalDate}</span>
                  <span>Due Date: {rental.dueDate}</span>
                </div>
                <Button
                  style={{ backgroundColor: "red", color: "white" }}
                  onClick={() => handleClickCancelBtn(rental)}
                >
                  Cancel
                </Button>
              </div>
            );
          })}
      </div>
      <Modal
        title="Missing Book Request Update"
        open={isOpen}
        onCancel={() => setIsOpen(false)}
        footer={null}
      >
        <div style={{ marginBottom: "20px" }}>
          <p>
            Before canceling the missing book request, please consider the
            following:
          </p>
          <br />
          <p>- Are you sure you want to cancel the missing book request?</p>
          <br />
          <p>
            - If you proceed, you will need to return the book to the librarian
            along with any applicable fine amount.
          </p>
          <br />
          <p>
            - This action will notify the library about the cancellation of your
            request.
          </p>
        </div>
        <Row className="my-row" gutter={6}>
          <Col span={12} align="end">
            <Space direction="vertical" size="large">
              <Button
                htmlType="submit"
                className="submitButton"
                onClick={handleOnSave}
              >
                Save
              </Button>
            </Space>
          </Col>
          <Col span={12}>
            <Space direction="vertical" size="large">
              <Button
                onClick={handleCancel}
                style={{ backgroundColor: "red", color: "white" }}
              >
                Cancel
              </Button>
            </Space>
          </Col>
        </Row>
      </Modal>
    </>
  );
};
export default MissingBooks;
