import React from "react";
import { Transport, Schoolname } from "../../components";

const Transports = () => {
  return (
    <main>
      <Schoolname />
      <Transport />
    </main>
  );
};

export default Transports;
