import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import Cookies from "js-cookie";
export const edpediaStoreStudent = createApi({
  reducerPath: "edpediaStoreStudent",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://api.edpedia.co/v1.0/store",
    prepareHeaders: (headers, { getState }) => {
      const accessToken = Cookies.get("accessToken");
      if (accessToken) {
        headers.set("Authorization", `Bearer ${accessToken}`);
      }
      headers.set("Content-Type", "application/json");
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getCategoryList: builder.query({
      query: () => {
        return {
          url: "/api/storeCategory/categoryList",
          method: "GET",
        };
      },
    }),
    getSubCategoryList: builder.query({
      query: (subCategoryId) => {
        return {
          url: `/api/storeSubCategory/${subCategoryId}/findListOfSubCategory`,
          method: "GET",
        };
      },
    }),
    getSubCategoryProductsList: builder.query({
      query: (productId) => {
        return {
          url: `/api/product/${productId}/findProductBySubCategoryId`,
          method: "GET",
        };
      },
    }),
    productAddToCart: builder.mutation({
      query: ({ payload, userId }) => ({
        url: `/cart/${userId}/add`,
        method: "POST",
        body: payload,
      }),
    }),
    getFindProductById: builder.query({
      query: (productId) => {
        return {
          url: `/api/product/${productId}/findProductById`,
          method: "GET",
        };
      },
    }),
    getListOfCartProducts: builder.query({
      query: (userId) => {
        return {
          url: `/cart/${userId}`,
          method: "GET",
        };
      },
    }),
    removeFromCartProduct: builder.query({
      query: ({ studentId, productId }) => {
        return {
          url: `/cart/${studentId}/remove/${productId}`,
          method: "DELETE",
        };
      },
    }),
    clearFromCartProductAll: builder.query({
      query: (studentId) => {
        return {
          url: `/cart/${studentId}/clear`,
          method: "DELETE",
        };
      },
    }),
    //Address
    AddressCreate: builder.mutation({
      query: ({ studentId, payload }) => ({
        url: `/api/address/${studentId}/createAddress`,
        method: "POST",
        body: payload,
      }),
    }),
    AddressEdit: builder.mutation({
      query: ({ selectedId, payload }) => ({
        url: `/api/address/${selectedId}/edit`,
        method: "POST",
        body: payload,
      }),
    }),
    getFindAddress: builder.query({
      query: (addressId) => {
        return {
          url: `api/address/${addressId}/findAddress`,
          method: "GET",
        };
      },
    }),
    getFindAddressByUser: builder.query({
      query: (userId) => {
        return {
          url: `/api/address/${userId}/findByUser`,
          method: "GET",
        };
      },
    }),
    removeAddress: builder.query({
      query: (addressId) => {
        return {
          url: `/api/address/${addressId}/delete`,
          method: "DELETE",
        };
      },
    }),
    //orders
    orderCreate: builder.mutation({
      query: (payload) => ({
        url: `/api/order/create`,
        method: "POST",
        body: payload,
      }),
    }),
    getOrderList: builder.query({
      query: (studentId) => {
        return {
          url: `/api/order/${studentId}/findByUser`,
          method: "GET",
        };
      },
    }),
    // wishList,
    wishListCreate: builder.mutation({
      query: (payload) => ({
        url: `/api/wishlist/add`,
        method: "POST",
        body: payload,
      }),
    }),
    getWishList: builder.query({
      query: (studentId) => {
        return {
          url: `/api/wishlist/${studentId}/findByUser`,
          method: "GET",
        };
      },
    }),
  }),
});
export const {
  useLazyRemoveFromCartProductQuery,
  useGetCategoryListQuery,
  useGetSubCategoryListQuery,
  useGetSubCategoryProductsListQuery,
  useProductAddToCartMutation,
  useGetFindProductByIdQuery,
  useGetListOfCartProductsQuery,
  useLazyClearFromCartProductAllQuery,
  //address
  useAddressCreateMutation,
  useAddressEditMutation,
  useGetFindAddressByUserQuery,
  useGetFindAddressQuery,
  useLazyRemoveAddressQuery,
  // orders
  useOrderCreateMutation,
  useGetOrderListQuery,
  //wishList
  useWishListCreateMutation,
  useGetWishListQuery,
} = edpediaStoreStudent;
