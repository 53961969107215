import React, { useState } from "react";
import "./Exam.css";
import { FiSearch } from "react-icons/fi";
import { useGetExamQuery } from "../../RTKQuery/rtkQuery";
import { Radio, Spin } from "antd";
import Upcoming from "./Upcoming";
import Present from "./Present";
import Previous from "./Previous";
import Cookies from "js-cookie";
import EmptyComponent from "../EmptyComponent/EmptyComponent";
import { useSelector } from "react-redux";

const Exam = () => {
  const [selectedOption, setSelectedOption] = useState("ONGOING");
  // const studentInfo = Cookies.get("studentInfo")
  //   ? JSON.parse(Cookies.get("studentInfo"))
  //   : [];
  const studentInfo = useSelector(
    (state) => state.studentInfo.studentInfo
  );
  const classId = studentInfo?.classId;

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const { data, isLoading, isError } = useGetExamQuery(classId);

  const examsData = data?.data || [];
  const examNames = Object?.keys(examsData || []);
  const { UPCOMING, COMPLETED, ONGOING } = examsData;

  if (isLoading) {
    return (
      <div className="centered_spin">
        <Spin />
      </div>
    );
  }

  if (isError) {
    return (
      <div className="ErrorContainer">
        <EmptyComponent />
      </div>
    );
  }

  return (
    <section className="main-bg" id="culture">
      <div>
        <div className="results-title">
          <h3>Exams </h3>
          <div className="exam-btns flexBox-sty">
            <Radio.Group
              defaultValue={selectedOption}
              buttonStyle="solid"
              onChange={handleOptionChange}
            >
              <Radio.Button value="UPCOMING">UPCOMING</Radio.Button>
              <Radio.Button value="ONGOING">ONGOING</Radio.Button>
              <Radio.Button value="COMPLETED">COMPLETED</Radio.Button>
            </Radio.Group>
          </div>
        </div>
        {/* <div className="result-search">
          <input
            className="input-sty"
            placeholder="Search Exma Type:EX - monthly,quarterly"
          />
          <FiSearch className="results-icon" />
        </div> */}
      </div>
      <div className="sidebar-scroll-container" style={{ paddingTop: 15 }}>
        {selectedOption == "UPCOMING" && <Upcoming exams={UPCOMING} />}
        {selectedOption == "COMPLETED" && <Previous exams={COMPLETED} />}
        {selectedOption == "ONGOING" && <Present exams={ONGOING} />}
      </div>
    </section>
  );
};

export default Exam;
