import { Button, Col, Modal, Row, Space, notification } from "antd";
import React from "react";
import { DislikeOutlined, CheckCircleFilled } from "@ant-design/icons";
import "../Books/Books.scss";
import {
  useGetRentalInformationByBookQuery,
  usePostRentalBookMutation,
} from "../../../RTKQuery/rtkLibrary";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";

const RentBookModal = ({
  isRentModalVisible,
  selectedBook,
  setRentModalVisible,
}) => {
  const { data, refetch } = useGetRentalInformationByBookQuery(
    selectedBook?.id
  );
  const rentedBookInformation = data?.data;
  const [rentBookMutation, { error: rentBookError }] =
    usePostRentalBookMutation();

  // const studentInfo = Cookies.get("studentInfo")
  //   ? JSON.parse(Cookies.get("studentInfo"))
  //   : [];
  const studentInfo = useSelector((state) => state.studentInfo.studentInfo);
  const studentId = studentInfo?.rollNo;
  const userId = studentId;

  const handleRentOk = (values) => {
    rentBookMutation({
      userId: userId,
      bookId: selectedBook?.id,
      userType: "Student",
    }).then((res) => {
      let errorMessage = "";
      if (res.error && res.error.status === 500) {
        errorMessage = res.error.data.message;
      }
      if (errorMessage) {
        errorMessage = rentBookError.data.message;
      }
      if (errorMessage) {
        notification.error({
          message: "Failed",
          placement: "bottomRight",
          description: `${rentBookError?.data?.message}`,
          icon: <DislikeOutlined style={{ color: "red" }} />,
        });
        setRentModalVisible(false);
      } else {
        notification.success({
          message: "Book Rent Successfully",
          placement: "bottomRight",
          icon: (
            <CheckCircleFilled
              style={{
                color: "green",
              }}
            />
          ),
        });
        setRentModalVisible(false);
        refetch();
      }
    });
  };
  const handleRentCancel = () => {
    setRentModalVisible(false);
  };

  return (
    <Modal
      open={isRentModalVisible}
      // onOk={handleRentOk}
      onCancel={handleRentCancel}
      footer={null}
      className="custom-modal"
    >
      <div style={{ marginBottom: "20px" }}>
        <h3 className="high">Read Carefully</h3>
        <br />
        <h4 className="high high2">Rent Book Information: </h4>

        <div className="datesInfo">
          <h4>
            <span className="high">RentalDate:</span>{" "}
            {rentedBookInformation?.rentalDate}
          </h4>
          <h4>
            <span className="high">DueDate:</span>{" "}
            {rentedBookInformation?.dueDate}
          </h4>
          <h4>
            <span className="high">Fine Amount for Missing books:</span>
            {rentedBookInformation?.missingBooksFineAmount}
          </h4>
          <h4>
            <span className="high">Per Day Fine Amount: </span>
            {rentedBookInformation?.perDayFineAmount}
          </h4>
          <br />
          <p>Are you sure you want to take the Rent?</p>
        </div>
      </div>
      <Row className="my-row">
        <Col span={12} align="end">
          <Space direction="vertical" size="large">
            <Button
              htmlType="submit"
              className="submitButton"
              onClick={handleRentOk}
            >
              Rent
            </Button>
          </Space>
        </Col>
        <Col span={12} align="start">
          <Space direction="vertical" size="large">
            <Button
              onClick={handleRentCancel}
              style={{ backgroundColor: "red", color: "white" }}
            >
              Cancel
            </Button>
          </Space>
        </Col>
      </Row>
    </Modal>
  );
};

export default RentBookModal;
