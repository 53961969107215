import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import Cookies from "js-cookie";

export const instituteDetails = createApi({
  reducerPath: "instituteDetails",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://api.edpedia.co/v1.0/superAdmin/",
    prepareHeaders: (headers, { getState }) => {
      const accessToken = Cookies.get("accessToken");
      if (accessToken) {
        headers.set("Authorization", `Bearer ${accessToken}`);
      }

      headers.set("Content-Type", "application/json");
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getInstituteDetails: builder.query({
      query: ({ branchCode }) => ({
        url: `api/institute/getInstituteDetails?branchCode=${branchCode}`,
        method: "GET",
      }),
    }),
    viewNotification: builder.query({
      query: ({ userId, userType }) => ({
        url: `/api/notification/${userId}/getAllNotifications?userType=${userType}`,
        method: "GET",
      }),
    }),
  }),
});

export const { useLazyGetInstituteDetailsQuery, useViewNotificationQuery } =
  instituteDetails;
