import React, { useState } from "react";
import "./Assignment.css";
import {
  Button,
  Table,
  Upload,
  Modal,
  Form,
  notification,
  Spin,
  Space,
  Col,
  Row,
} from "antd";
import {
  UploadOutlined,
  CheckCircleFilled,
  DislikeOutlined,
} from "@ant-design/icons";
import { useGetAllAssignmentsQuery } from "../../RTKQuery/rtkQuery";
import { useSubmitAssignmentMutation } from "../../RTKQuery/rtkHandlingFileUpload";
import Cookies from "js-cookie";
import EmptyComponent from "../EmptyComponent/EmptyComponent";
import { useSelector } from "react-redux";

const Assignment = () => {
  const [form] = Form.useForm();
  const [files, setFiles] = React.useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [assignmentId, setAssignmentId] = useState(null);
  const [upload, setUpload] = useState("Upload");
  const [confirmModal, setConfirmModal] = useState(false);
  // const studentInfo = Cookies.get("")
  //   ? JSON.parse(Cookies.get("studentInfo"))
  //   : [];

  // const studentId = Cookies.get("userId");

  const studentInfo = useSelector((state) => state.studentInfo.studentInfo);
  const studentId = studentInfo.studentId;

  const {
    data,
    isLoading,
    isError,
    refetch: refechAassignment,
  } = useGetAllAssignmentsQuery(studentId);

  const AllAssignments = data?.data;

  const handleFileChange = ({ fileList }) => {
    let files = fileList.map((file) => file.originFileObj);
    setFiles(files);
  };

  const [
    submitAssignment,
    {
      isError: SubmitAssignmentIsError,
      // isSuccess,
    },
  ] = useSubmitAssignmentMutation();

  const handleSubmit = () => {
    if (files.length === 0) {
      notification.error({
        message: "Please select one or more files to upload",
        placement: "bottomRight",
      });
      return;
    }

    const formData = new FormData();
    formData.append("studentId", studentId);
    files.forEach((file) => {
      formData.append("files", file);
    });

    form.validateFields().then(() => {
      submitAssignment({ assignmentId, payload: formData })
        .unwrap()
        .then((res) => {
          if (res.status === 200) {
            form.resetFields();
            setModalVisible(false);
            notification.success({
              message: "Assignment Uploaded Successfully",
              placement: "bottomRight",
              icon: <CheckCircleFilled style={{ color: "green" }} />,
            });
            refechAassignment();
          }
        })
        .catch((err) => {
          console.log("err", err);
          notification.error({
            description:
              "You already submitted the assignment. Please contact Concerned Teacher",
            placement: "bottomRight",
            icon: <DislikeOutlined style={{ color: "red" }} />,
          });
          setModalVisible(false);
          form.resetFields();
        });
    });
  };

  if (isLoading) {
    return (
      <div className="centered_spin">
        <Spin />
      </div>
    );
  }

  if (isError) {
    return (
      <div className="ErrorContainer">
        {/* <EmptyComponent /> */}Error occurred while fetching data.
      </div>
    );
  }
  const handleUploadFile = (row) => {
    console.log("handle clicker triggered");
    if (row.status === "PENDING") {
      Modal.confirm({
        title: `You have already submitted this assignment. Would you like to resubmit it?`,
        onOk: () => {
          setModalVisible(true);
          setAssignmentId(row?.assignmentId);
        },
        onCancel: () => {
          Modal.destroyAll();
        },
      });
    } else if (row.status === null) {
      setModalVisible(true);
    }
  };

  const handleCancel = () => {
    setModalVisible(false);
    form.resetFields();
  };
  const columns = [
    {
      title: "Assignment Title",
      dataIndex: "assignmentTitle",
      key: "assignmentTitle",
      align: "center",
      sorter: (a, b) => a.assignmentTitle.localeCompare(b.assignmentTitle),
    },
    {
      title: "Given Date",
      dataIndex: "givenDate",
      key: "givenDate",
      sorter: (a, b) => a.givenDate - b.givenDate,
      render: (dateStr) => {
        const dateObj = new Date(dateStr);
        const day = dateObj.getDate().toString().padStart(2, "0");
        const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
        const year = dateObj.getFullYear().toString();
        return <span>{`${day}-${month}-${year}`}</span>;
      },
      align: "center",
    },
    {
      title: "Due Date",
      dataIndex: "dueDate",
      key: "dueDate",
      sorter: (a, b) => a.dueDate - b.dueDate,
      render: (dateStr) => {
        const dateObj = new Date(dateStr);
        const day = dateObj.getDate().toString().padStart(2, "0");
        const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
        const year = dateObj.getFullYear().toString();
        const formattedDate = `${day}-${month}-${year}`;
        const currentDate = new Date();
        const isUpcoming = dateObj > currentDate;
        return (
          <span className={isUpcoming ? "upcoming-assignment" : ""}>
            {formattedDate}
          </span>
        );
      },
      align: "center",
    },
    {
      title: "Mode",
      dataIndex: "mode",
      key: "mode",
      align: "center",
      sorter: (a, b) => a.reviewStatus.localeCompare(b.reviewStatus),
    },
    // {
    //   title: "Review Status",
    //   dataIndex: "reviewStatus",
    //   key: "reviewStatus",
    //   align: "center",
    //   sorter: (a, b) => a.reviewStatus.localeCompare(b.reviewStatus),
    // },

    {
      title: "Subject Name",
      dataIndex: "subjectName",
      key: "subjectName",
      align: "center",
      sorter: (a, b) => a.subjectName.localeCompare(b.subjectName),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "center",
      sorter: (a, b) => a.status.localeCompare(b.status),
    },

    // {
    //   title: "Total Score",
    //   dataIndex: "totalScore",
    //   key: "totalScore",
    //   align: "center",
    //   sorter: (a, b) => a.totalScore - b.totalScore,
    //   render: (record) => {
    //     if (record == null || record == undefined) {
    //       return " - ";
    //     }
    //     return record;
    //   },
    // },
    // {
    //   title: "Type",
    //   dataIndex: "type",
    //   key: "type",
    //   align: "center",
    //   sorter: (a, b) => a.type.localeCompare(b.type),
    // },
    // {
    //   title: "UploadAssignment",
    //   dataIndex: "UploadAssignment",
    //   key: "UploadAssignment",
    //   render: (_, row, index) => {
    //     return row?.supportDocUrls.length > 0 ? (
    //       <Button
    //         style={{ backgroundColor: "#172b4d", color: "#fff" }}
    //         disabled
    //       >
    //         Upload
    //       </Button>
    //     ) : (
    //       <Button onClick={() => handleUploadFile(row, index)}>{upload}</Button>
    //     );
    //   },
    //   align: "center",
    // },
    {
      title: "Upload Assignment",
      dataIndex: "UploadAssignment",
      key: "UploadAssignment",
      render: (_, row) => {
        const dueDate = new Date(row?.dueDate);
        const today = new Date();

        const isActive = dueDate > today;
        const buttonStyle = isActive
          ? { backgroundColor: "#172b4d", color: "#fff" }
          : { backgroundColor: "#838d9e", color: "#fff" };

        return (
          <Button
            style={{
              ...buttonStyle,
              backgroundColor:
                row?.status === "COMPLETED"
                  ? "grey"
                  : buttonStyle.backgroundColor,
            }}
            disabled={row?.status === "COMPLETED"}
            onClick={() => handleUploadFile(row)}
          >
            {upload}
          </Button>
        );
      },
      align: "center",
    },
  ];

  const onFinishFailed = () => {
    notification.error({
      message: "Error",
      description: "An error occurred , try again later.",
      icon: (
        <DislikeOutlined
          style={{
            color: "red",
          }}
        />
      ),
      duration: 3,
      placement: "bottomRight",
    });
  };

  return (
    <>
      <div className="uploadDoc main-bg">
        <h3>Assignments</h3>
      </div>
      <div className="">
        <Table
          dataSource={AllAssignments}
          pagination={false}
          columns={columns}
          showSorterTooltip={false}
          // scroll={{ x: true }}
        />
      </div>
      <Modal
        title="Submit Assignment"
        open={modalVisible}
        onCancel={handleCancel}
        footer={null}
        // footer={[
        //   <Button
        //     key="submit"
        //     style={{ backgroundColor: "#172b4d" }}
        //     onClick={handleSubmit}
        //     loading={isLoading}
        //   >
        //     Save
        //   </Button>,
        //   <Button
        //     key="cancel"
        //     style={{ backgroundColor: "red" }}
        //     onClick={() => setModalVisible(false)}
        //   >
        //     Cancel
        //   </Button>,
        // ]}
      >
        <Form
          form={form}
          onFinish={handleSubmit}
          layout="vertical"
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label="Upload Assignment"
            name="file"
            rules={[{ required: true, message: "Please upload a file" }]}
          >
            <Upload
              onChange={handleFileChange}
              beforeUpload={() => false}
              multiple
            >
              <Button icon={<UploadOutlined />} className="submit-button">
                Select File
              </Button>
            </Upload>
          </Form.Item>
          <Row className="my-row" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={12} align="end">
              <Space direction="vertical" size="large">
                <Button
                  className="submit-button "
                  htmlType="submit"
                  // onClick={handleSubmit}
                >
                  Save
                </Button>
              </Space>
            </Col>
            <Col span={12}>
              <Space direction="vertical" size="large">
                <Button
                  className="cancel-button"
                  onClick={() => setModalVisible(false)}
                >
                  Cancel
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
        {isError && <div>Error: {SubmitAssignmentIsError.message}</div>}
      </Modal>
      <div className="assignment-languages flexDir-sty"></div>
    </>
  );
};

export default Assignment;
