import React, { useEffect } from "react";
import "./Footer.scss";
// import { FooterLogo } from "../../Assets/Images/Images";
// import { InstaLogo } from "../../Assets/Images/Images";
import {
  FaFacebook,
  FaInstagram,
  FaTwitter,
  FaLinkedin,
  FaYoutube,
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";
// import AOS from "aos";
// import "aos/dist/aos.css";
// import { onlyhandIcon } from "../../Assets/Icons/Icons";

function Footer() {
  // useEffect(() => {
  //   setTimeout(() => {
  //     AOS.init();
  //   }, 10);
  // }, []);
  const navigate = useNavigate();
  //   const handleAbout = () => {
  //     navigate("/about");
  //   };
  //   const handlePrivacy = () => {
  //     navigate("/privacy-policy");
  //   };
  //   const handleServices = () => {
  //     navigate("/services");
  //   };
  //   const handleTermsAndConditions = () => {
  //     navigate("/terms-and-conditions");
  //   };
  //   const handleContactUs = () => {
  //     navigate("/contact-us");
  //   };
  //   const handleHome = () => {
  //     navigate("/");
  //   };

  const linkedInFunc = () => {
    window.open(
      "https://www.linkedin.com/company/eaglemount/mycompany/",
      "_blank"
    );
  };
  return (
    <div className="footerbg">
      <h6 className="copyright">
        © Copyright 2023 by EagleMount Software Pvt Ltd
      </h6>
      <div
        style={{
          width: 250,
          display: "flex",
          justifyContent: "space-between",
        }}
        className="icons-containerFooter"
      >
        <FaFacebook
          size={20}
          color="white"
          className="icon"
          style={{ cursor: "pointer" }}
        />
        <FaInstagram
          style={{ cursor: "pointer" }}
          size={20}
          color="purple"
          className="icon"
        />
        <FaTwitter
          style={{ cursor: "pointer" }}
          size={20}
          color="blue"
          className="icon"
        />
        <FaYoutube
          style={{ cursor: "pointer" }}
          size={20}
          color="red"
          className="icon"
        />
        <FaLinkedin
          style={{ cursor: "pointer" }}
          size={20}
          color="blue"
          className="icon"
          onClick={linkedInFunc}
        />
      </div>
    </div>
  );
}

export default Footer;
