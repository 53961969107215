import React, { useEffect, useState } from "react";
import { LeftOutlined } from "@ant-design/icons";
import { Button } from "antd";
import {
  useGetAuthorBooksQuery,
  useGetAuthorNameQuery,
  useGetRentedBooksQuery,
} from "../../../RTKQuery/rtkLibrary"; // Import the new query hook
import Cookies from "js-cookie";
import RentBookModal from "../Books/RentBookModal";
import RequestBookModal from "../Books/RequestBookModal";
import "./Authors.scss";
import { useSelector } from "react-redux";
const ViewAauthorBooks = ({ authorName, onGoBack, onShowAuthorCards }) => {
  // const studentInfo = Cookies.get("studentInfo")
  //   ? JSON.parse(Cookies.get("studentInfo"))
  //   : [];
  const studentInfo = useSelector(
    (state) => state.studentInfo.studentInfo  );
  const studentId = studentInfo?.rollNo;
  const userId = studentId;
  const rollNo = studentInfo?.rollNo;
  const [authorBookList, setAuthorBookList] = useState([]);

  const { data: getAuthorBooks } = useGetAuthorBooksQuery({
    authorName: authorName,
  });
  const { data: rentedBooks } = useGetRentedBooksQuery({
    userId: rollNo,
    userType: "Student",
  });

  const GetRentedBooks = rentedBooks?.data?.rented;
  const [isRentModalVisible, setRentModalVisible] = useState(false);
  const [isRequestModalVisible, setRequestModalVisible] = useState(false);
  const [selectedBook, setSelectedBook] = useState(null);
  const handleRentBook = (book) => {
    setRentModalVisible(book);
    setSelectedBook(book);
  };
  useEffect(() => {
    if (getAuthorBooks) {
      setAuthorBookList(getAuthorBooks.data);
    }
  }, [getAuthorBooks]);
  const onHandleRequest = (book) => {
    setRequestModalVisible(true);
  };
  return (
    <>
      <div className="main-containers">
        <Button
          className="back-button"
          type="primary"
          htmlType="submit"
          onClick={() => {
            onGoBack(); // Call onGoBack to navigate back to author tab
            onShowAuthorCards(); // Call onShowAuthorCards to show author cards
          }}
        >
          <LeftOutlined />
        </Button>
        <div className="author_books_container">
          <h3>
            Author: <span className="authorName">{authorName}</span>
          </h3>{" "}
          {/* Display the author's name */}
          <div className="card_flex">
            {authorBookList?.map((i) => {
              return (
                <div className="card-container">
                  <img src={i.image} alt="img" class="card-image" />
                  <div className="author-card-content-container">
                    <h3 className="book-title">{i.bookName}</h3>
                    <p>Book Rate :{i.bookRate}</p>
                    <p>Publisher: {i.publisher}</p>
                  </div>
                  <div>
                    {i?.numbersOfBooks > 0 ? (
                      GetRentedBooks?.some((item) => item.book.id === i.id) ? (
                        <Button className="rent-button green-bg">Rented</Button>
                      ) : (
                        <Button
                          className="rent-button orange-bg"
                          onClick={() => handleRentBook(i)}
                        >
                          Rent Now
                        </Button>
                      )
                    ) : (
                      <Button
                        className="request-button"
                        onClick={() => onHandleRequest(i)}
                      >
                        Request
                      </Button>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <RentBookModal
          isRentModalVisible={isRentModalVisible}
          setRentModalVisible={setRentModalVisible}
          selectedBook={selectedBook}
        />
        <RequestBookModal
          isRequestModalVisible={isRequestModalVisible}
          setRequestModalVisible={setRequestModalVisible}
          selectedBook={selectedBook}
        />
      </div>
    </>
  );
};

export default ViewAauthorBooks;
