import React, { useEffect, useState } from "react";
import "./Leaves.scss";
import {
  Button,
  Col,
  Form,
  Modal,
  Row,
  Space,
  Table,
  Input,
  DatePicker,
  Checkbox,
  notification,
} from "antd";
import {
  CheckCircleFilled,
  DislikeOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  useCreateLeaveMutation,
  useGetStudentLeaveQuery,
  useEditLeaveMutation,
} from "../../../RTKQuery/rtkQuery";
import { EyeOutlined, EditOutlined } from "@ant-design/icons";

import TextArea from "antd/es/input/TextArea";

import Cookies from "js-cookie";
import dayjs, { Dayjs } from "dayjs";
import moment from "moment/moment";
import { useSelector } from "react-redux";
const Leaves = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [leaveForm] = Form.useForm();
  const [leaveData, setLeaveData] = useState([]);
  const [leaveCreate, { error: createLeaveError }] = useCreateLeaveMutation();
  const { data, isError, refetch } = useGetStudentLeaveQuery();
  const [isHalfDay, setIsHalfDay] = useState(false);
  const [editLeaveData, setEditLeaveData] = useState(null);
  const [editLeave, { error: editLeaveError }] = useEditLeaveMutation();
  const [viewStudentLeaveData, setViewStudentViewData] = useState(null);
  const [openViewModal, setOpenViewModal] = useState(false);
  // const studentInfo = Cookies.get("studentInfo")
  //   ? JSON.parse(Cookies.get("studentInfo"))
  //   : [];
  const studentInfo = useSelector(
    (state) => state.studentInfo.studentInfo
  );

  const studentId = studentInfo?.studentId;
  
  useEffect(() => {
    if (data) {
      setLeaveData(data?.data);
    }
  }, [data]);

  const handleViewLeave = (row) => {
    setViewStudentViewData(row);
    setOpenViewModal(true);
  };
  const columns = [
    {
      title: "Reason",
      dataIndex: "reason",
      key: "reason",
      align: "center",
      sorter: (a, b) => a.reason.localeCompare(b.reason),
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate",
      align: "center",
      sorter: (a, b) => a.startDate - b.startDate,

      render: (text, record) => {
        const startDate = moment(record.startDate);
        return startDate.isValid() ? startDate.format("DD-MM-YYYY") : "-";
      },
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      key: "endDate",
      align: "center",
      sorter: (a, b) => a.endDate - b.endDate,
      render: (text, record) => {
        const endDate = moment(record.endDate);
        return endDate.isValid() ? endDate.format("DD-MM-YYYY") : "-";
      },
    },
    {
      title: "duration",
      dataIndex: "duration",
      key: "duration",
      align: "center",
      sorter: (a, b) => a.duration - b.duration,
    },
    {
      title: "status",
      dataIndex: "status",
      key: "status",
      align: "center",
      sorter: (a, b) => a.status.localeCompare(b.status),
    },
    {
      title: "Actions",
      key: "actions",
      dataIndex: "actions",
      align: "center",
      render: (text, row) => (
        <Space>
          <Button onClick={() => handleViewLeave(row)} className="blue-button">
            <EyeOutlined />
          </Button>
          <Button
            className="blue-button"
            onClick={() => {
              handleEditLeave(row);
            }}
          >
            <EditOutlined />
          </Button>
          {/* {row.status !== "APPROVED" ? (
            <Button
              className="full-details-button"
              onClick={() => {
                handleEditLeave(row);
              }}
            >
              <EditOutlined />
            </Button>
          ) : (
            <Button className="full-details-button disabled" disabled>
              <EditOutlined />
            </Button>
          )} */}
        </Space>
      ),
    },
  ];
  const handleEditLeave = (row) => {
    setIsOpen(true);
    setEditLeaveData(row);
    let editLeaveData = row;
    const updatedStartDate =
      editLeaveData?.startDate === undefined
        ? dayjs(new Date())
        : dayjs(editLeaveData?.startDate, "YYYY-MM-DD");
    const updatedEndDate =
      editLeaveData?.endDate === undefined
        ? dayjs(new Date())
        : dayjs(editLeaveData?.endDate, "YYYY-MM-DD");
    leaveForm.setFieldsValue({
      reason: editLeaveData?.reason,
      startDate: updatedStartDate,
      endDate: updatedEndDate,
      studentId: studentId,
      isHalfDay: editLeaveData?.isHalfDay || false,
    });
  };
  const showModal = () => {
    setIsOpen(true);
  };
  const handleCancel = () => {
    setIsOpen(false);
    setEditLeaveData(null);
    leaveForm.resetFields();
  };
  const onFinish = (values) => {
    const payload = {
      reason: values.reason,
      startDate: values.startDate,
      endDate: values.endDate,
      studentId: studentId,
      isHalfDay: values.isHalfDay || false,
    };

    const StudentId = editLeaveData?.id;

    if (StudentId) {
      editLeave({ payload: payload, id: StudentId }).then((res) => {
        let errorMessage = "";
        if (res.error && res.error.status === 500) {
          errorMessage = res.error.data.message;
        }
        if (editLeaveError) {
          errorMessage = editLeaveError.data.message;
        }
        if (errorMessage) {
          notification.error({
            message: "Failed",
            placement: "bottomRight",
            description: `${editLeaveError?.data?.message}`,
            icon: <DislikeOutlined style={{ color: "red" }} />,
          });
          leaveForm.resetFields();
        } else {
          setIsOpen(false);
          notification.success({
            message: `Leave Updated Successfully .`,
            placement: "bottomRight",
            icon: (
              <CheckCircleFilled
                style={{
                  color: "green",
                }}
              />
            ),
          });
          setEditLeaveData(null);
          refetch();
          leaveForm.resetFields();
        }
      });
    } else {
      leaveCreate(payload).then((res) => {
        let errorMessage = "";
        if (res.error && res.error.status === 500) {
          errorMessage = res.error.data.message;
        }
        if (createLeaveError) {
          errorMessage = createLeaveError.data.message;
        }
        if (errorMessage) {
          notification.error({
            message: "Failed",
            placement: "bottomRight",
            description: `${errorMessage}`,
            icon: <DislikeOutlined style={{ color: "red" }} />,
          });
          leaveForm.resetFields();
        } else {
          setIsOpen(false);
          notification.success({
            message: `Leave Applied Successfully .`,
            placement: "bottomRight",
            icon: (
              <CheckCircleFilled
                style={{
                  color: "green",
                }}
              />
            ),
          });
          setEditLeaveData(null);
          refetch();
          leaveForm.resetFields();
        }
      });
    }
  };

  const disabledStartDate = (current) => {
    const endDate = new Date(leaveForm.getFieldValue("endDate"));
    const currentDate = new Date(current);
    // Disable past dates
    if (currentDate < new Date()) {
      return true;
    }
    // Disable dates after the selected endDate, if any
    if (endDate && currentDate > endDate) {
      return true;
    }
    return false;
  };

  const disabledEndDate = (current) => {
    const startDate = new Date(leaveForm.getFieldValue("startDate"));
    const endDate = new Date(leaveForm.getFieldValue("endDate"));
    const currentDate = new Date(current);
    // If endDate is selected, disable past dates and dates before the selected startDate
    if (endDate) {
      return currentDate < new Date() || currentDate < startDate;
    }
    // If endDate is not selected, only disable past dates
    return currentDate < new Date();
  };

  return (
    <div className="leaves-container">
      <div>
        <Button onClick={showModal} className="apply-btn">
          Apply Leave
        </Button>
      </div>
      <Table
        dataSource={leaveData}
        columns={columns}
        showSorterTooltip={false}
      />
      <Modal
        open={isOpen}
        onCancel={handleCancel}
        footer={null}
        className="apply-modal"
        title={
          editLeaveData && Object.keys(editLeaveData).length > 0
            ? "Edit Leave"
            : "Apply Leave"
        }
        width={400}
      >
        <Form form={leaveForm} onFinish={onFinish}>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className="gutter-row" span={24}>
              <Form.Item
                name="startDate"
                rules={[{ required: true, message: "" }]}
              >
                <DatePicker
                  placeholder="StartDate"
                  name="startDate"
                  format="DD-MM-YYYY"
                  disabledDate={disabledStartDate}
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={24}>
              <Form.Item
                name="endDate"
                // rules={[{ required: true, message: "" }]}
              >
                <DatePicker
                  placeholder="End Date"
                  name="endDate"
                  format="DD-MM-YYYY"
                  disabledDate={disabledEndDate}
                  disabled={isHalfDay}
                />
              </Form.Item>
            </Col>
            {/* <Col className="gutter-row" span={24}>
              <Form.Item
                name="startDate"
                rules={[{ required: true, message: "" }]}
              >
                <input
                  type="date"
                  name="startDate"
                  className="ant-input"
                  style={{ width: 350 }}
                  placeholder="Start Date"
                  disabled={disabledStartDate}
                  min={new Date().toISOString().split("T")[0]}
                  onChange={(e) =>
                    leaveForm.setFieldsValue({ startDate: e.target.value })
                  }
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={24}>
              <Form.Item name="endDate">
                <input
                  type="date"
                  name="endDate"
                  style={{ width: 350 }}
                  className="ant-input"
                  placeholder="End Date"
                  disabled={disabledEndDate}
                  onChange={(e) =>
                    leaveForm.setFieldsValue({ endDate: e.target.value })
                  }
                />
              </Form.Item>
            </Col> */}
            <Col className="gutter-row" span={24}>
              <Form.Item name="isHalfDay" valuePropName="checked">
                <Checkbox onChange={(e) => setIsHalfDay(e.target.checked)}>
                  Half Day
                </Checkbox>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={24}>
              <Form.Item
                name="reason"
                rules={[{ required: true, message: "" }]}
              >
                <TextArea
                  name="reason"
                  placeholder="Reason"
                  rows={8}
                  maxLength={100}
                  autoSize={{ minRows: 4, maxRows: 5 }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row className="my-row" gutter={8}>
            <Col span={12} align="end">
              <Space direction="vertical" size="large">
                <Button htmlType="submit" className="submit-button">
                  Save
                </Button>
              </Space>
            </Col>
            <Col span={12}>
              <Space direction="vertical" size="large">
                <Button onClick={handleCancel} className="cancel-button">
                  Cancel
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
      </Modal>
      <Modal
        open={openViewModal}
        title="View Leave Details"
        footer={null}
        onCancel={() => setOpenViewModal(false)}
      >
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col
            className="modal-text"
            style={{ fontSize: "16px", fontWeight: 700 }}
            span={9}
          >
            Reason
          </Col>
          <Col
            className="modal-text"
            style={{ fontSize: "15px", fontWeight: 600 }}
            span={15}
          >
            {viewStudentLeaveData?.reason}
          </Col>
          <Col
            className="modal-text"
            style={{ fontSize: "16px", fontWeight: 700 }}
            span={9}
          >
            Start Date
          </Col>
          <Col
            className="modal-text"
            span={15}
            style={{ fontSize: "15px", fontWeight: 600 }}
          >
            {viewStudentLeaveData?.startDate}
          </Col>
          <Col
            className="modal-text"
            style={{ fontSize: "16px", fontWeight: 700 }}
            span={9}
          >
            End Date
          </Col>
          <Col
            className="modal-text"
            span={15}
            style={{ fontSize: "15px", fontWeight: 600 }}
          >
            {viewStudentLeaveData?.endDate}
          </Col>
          <Col
            className="modal-text"
            style={{ fontSize: "16px", fontWeight: 700 }}
            span={9}
          >
            Status
          </Col>
          <Col
            className="modal-text"
            span={15}
            style={{ fontSize: "15px", fontWeight: 600 }}
          >
            {viewStudentLeaveData?.status}
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

export default Leaves;
