import React from "react";
import { mybirthday } from "../../assets/images";
import "./Birthday.css";
import { useGetTodayStudentsBirthDayQuery } from "../../RTKQuery/rtkQuery";
import Cookies from "js-cookie";
import { Spin } from "antd";
import EmptyComponent from "../EmptyComponent/EmptyComponent";
import { useSelector } from "react-redux";

function Birthday() {
  // const studentInfo = Cookies.get("studentInfo")
  //   ? JSON.parse(Cookies.get("studentInfo"))
  //   : [];
  // const classId = studentInfo?.classId;

  const studentInfo = useSelector(
    (state) => state.studentInfo.studentInfo
  );
  const classId = studentInfo?.classId;

  const { data, isLoading, isError } =
    useGetTodayStudentsBirthDayQuery(classId);

  const todaysBirthdays = data?.data;

  if (isLoading) {
    return (
      <div className="centered_spin">
        <Spin />
      </div>
    );
  }
  if (isError) {
    return (
      <div className="ErrorContainer">
        <EmptyComponent />
      </div>
    );
  }

  return (
    <>
      <div className="main-bg" id="culture">
        <h3 style={{ marginBottom: "20px" }}>Birthdays</h3>
        {todaysBirthdays.length > 0 ? (
          <>
            {todaysBirthdays.map((item, studentId) => {
              return (
                <div className="birthday-item">
                  <div className="birthday-item-flex">
                    <div className="birthday-items-img">
                      <img src={mybirthday} alt="birthday" />
                    </div>
                    <div className="birthday-desc div-sty">
                      <h2>{item.studentName}</h2>
                      <p>5th Class</p>
                    </div>
                  </div>
                  <div className="birthday-btn">
                    <button>Wish Him</button>
                  </div>
                </div>
              );
            })}
          </>
        ) : (
          <>
            <EmptyComponent />
          </>
        )}
      </div>
    </>
  );
}

export default Birthday;
