import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import Cookies from "js-cookie";
export const attendanceService = createApi({
  reducerPath: "attendanceService",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://api.edpedia.co/v1.0/attendance/api/",
    prepareHeaders: (headers, { getState }) => {
      const accessToken = Cookies.get("accessToken");
      if (accessToken) {
        headers.set("Authorization", `Bearer ${accessToken}`);
      }

      headers.set("Content-Type", "application/json");
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getMonthWiseAttendance: builder.query({
      query: ({ studentId, presentMonth }) => ({
        url: `${studentId}/MonthWiseStuAttend?month=${presentMonth}`,
        method: "GET",
      }),
    }),
    getAttendance: builder.query({
      query: (studentId) => {
        return {
          url: `${studentId}/getAttendance`,
          method: "GET",
        };
      },
    }),
  }),
});
export const { useGetAttendanceQuery, useGetMonthWiseAttendanceQuery } =
  attendanceService;
