import React from "react";
import "./Timetable.css";
import { Table, Input, Progress, DatePicker, Spin } from "antd";
import moment from "moment";
import { useState, useEffect } from "react";
// import { todayTimeTableService } from "../../Services/TodayTimeTableService";
import { useGetTodayTimetableQuery } from "../../RTKQuery/rtkQuery";
import EmptyComponent from "../EmptyComponent/EmptyComponent";
import Cookies from "js-cookie";

const weekday = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
const dateFormat = "DD-MM-YYYY";

function Timetable() {
  const [weekDay, setWeekDay] = useState("");
  const [todayTimeTabledata, setTodayTimeTabledata] = useState([]);
  const [loading, setLoading] = useState(false);
  const studentId = Cookies.get("userId");

  const columns = [
    {
      title: "Period",
      dataIndex: "period",
      key: "period",
      align: "center",
      sorter: (a, b) => a.period.localeCompare(b.period),
    },
    {
      title: "Subject Name",
      dataIndex: "subjectName",
      key: "subjectName",
      align: "center",
      sorter: (a, b) => a.subjectName.localeCompare(b.subjectName),
    },
    // {
    //   title: "Subject ID",
    //   dataIndex: "subjectId",
    //   key: "subjectId",
    // },
    {
      title: "Teacher Name",
      dataIndex: "teacherName",
      key: "teacherName",
      align: "center",
      sorter: (a, b) => a.teacherName.localeCompare(b.teacherName),
    },
    {
      title: "Time",
      dataIndex: "time",
      key: "time",
      align: "center",
      sorter: (a, b) => a.time - b.time,
    },
    // {
    //   title: "Chapter Name",
    //   dataIndex: "chapterName",
    //   key: "chapterName",
    // },
    // {
    //   title: "Status",
    //   dataIndex: "status",
    //   align: "center",
    //   sorter: (a, b) => a.type.localeCompare(b.type),
    // render: (value) => {
    //   let color;
    //   if (value === "Complete") {
    //     color = "complete";
    //   } else if (value === "Todo") {
    //     color = "todo";
    //   } else {
    //     color = "inProgress";
    //   }

    //   return (
    //     <div
    //       style={{
    //         display: "flex",
    //         alignItems: "center",
    //         justifyContent: "center",
    //       }}
    //     >
    //       <button className={`radio-btn ${color}`}></button>
    //       <p>{value}</p>
    //     </div>
    //   );
    // },
    // align: "center",
    // },
    {
      title: "Status",
      dataIndex: "overAllStatus",
      align: "center",

      render: (overAllStatus) => {
        return (
          <div style={{ display: "flex" }}>
            <Progress
              size="small"
              strokeColor="green"
              status="active"
              percent={overAllStatus}
              showInfo={false}
            />
            <span style={{ display: "flex" }}> {overAllStatus}%</span>
          </div>
        );
      },
    },
  ];

  const {
    data: TodayTimetableData,
    isLoading,
    isError,
  } = useGetTodayTimetableQuery(studentId);

  const TodayTimetableDataMain = TodayTimetableData?.data;
  const TodayTimetableDataMainList = TodayTimetableDataMain
    ? Object.keys(TodayTimetableDataMain)
        .filter(
          (key) =>
            key.startsWith("period") && TodayTimetableDataMain[key] !== null
        )
        .map((key) => ({ period: key, ...TodayTimetableDataMain[key] }))
    : [];
  if (isLoading) {
    return (
      <div className="centered_spin">
        <Spin />
      </div>
    );
  }

  if (isError) {
    return (
      <div className="ErrorContainer">
        <EmptyComponent />
      </div>
    );
  }

  return (
    <div className="sidebar-scroll-container main-bg">
      <div className="heading-container">
        <h3>Time Table</h3>
        <h3> Class : {TodayTimetableDataMain.className}</h3>
        <p className="date-paragraph">{moment().format(dateFormat)}</p>
      </div>
      <div className="">
        {TodayTimetableDataMainList.length > 0 ? (
          <Table
            dataSource={TodayTimetableDataMainList}
            columns={columns}
            showSorterTooltip={false}
            pagination={false}
          />
        ) : (
          <EmptyComponent />
        )}
      </div>
    </div>
  );
}
export default Timetable;

// <DatePicker
//   style={{
//     backgroundColor: "transparent",
//     borderColor: "black",
//     color: "transparent",
//   }}
//   defaultValue={moment(new Date(), dateFormat)}
//   format={dateFormat}
// />;
