import React from "react";
import { Culture, Schoolname } from "../../components";

function Cultures() {
  return (
    <main>
      <Schoolname />
      <Culture />
    </main>
  );
}

export default Cultures;
