import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import Cookies from "js-cookie";

export const notificationService = createApi({
  reducerPath: "notificationService",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://api.edpedia.co/v1.0/instAdmin/",
    prepareHeaders: (headers, { getState }) => {
      const accessToken = Cookies.get("accessToken");
      if (accessToken) {
        headers.set("Authorization", `Bearer ${accessToken}`);
      }

      headers.set("Content-Type", "application/json");
      return headers;
    },
  }),

  endpoints: (builder) => ({
    viewNotification: builder.query({
      query: ({ userId, userType }) => ({
        url: `/api/notification/${userId}/getAllNotifications?userType=${userType}`,
        method: "GET",
      }),
    }),
    updateReadStatus: builder.mutation({
      query: ({ payload, id }) => ({
        url: `/api/notification/${id}/updateStatus`,
        method: "POST",
        body: payload,
      }),
    }),
    getStudentFeePayments: builder.query({
      query: (studentId) => {
        return {
          url: `/api/studentFee/${studentId}/find`,
          method: "GET",
        };
      },
    }),
  }),
});

export const {
  useViewNotificationQuery,
  useUpdateReadStatusMutation,
  useGetStudentFeePaymentsQuery,
} = notificationService;
