import { Input, Carousel, Button, Radio } from "antd";
import "./EdpediaStore.scss";
import {
  Bags,
  CarousalImage1,
  GirlUniform,
  Stationery,
  StoreCart,
  StoreFilter,
  TextBooks,
  Toys,
  Uniform,
  footWear,
} from "../../assets/icons";
import Cookies from "js-cookie";
import CategoryList from "./CategoryList/CategoryList";
import SubCategoryList from "./CategoryList/SubCategory";
import { useState } from "react";
import { CloseCircleOutlined } from "@ant-design/icons";
import CartProducts from "./Cart/CartProducts";
import CartWishlist from "./Cart/CartWishlist";
import { useNavigate } from "react-router-dom";
// import {StoreHome} from "../../assets/Icons"
const contentStyle = {
  height: "300px",
  color: "#fff",
  width: "1150px",
  lineHeight: "160px",
  // textAlign: "center",
  // background: "orange",
};
const EdpediaStore = () => {
  const studentInfo = Cookies.get("studentInfo")
    ? JSON.parse(Cookies.get("studentInfo"))
    : [];
  const studentId = studentInfo?.rollNo;
  const [selected, setSelected] = useState("home");
  const [offer, setOffer] = useState(true);
  const [selectedOption, setSelectedOption] = useState("cart");
  const [cartClick, setCartClick] = useState(false);
  const [selectedSudId, setSelectedSubId] = useState(null);
  const navigate = useNavigate();

  const handleClsoe = () => {
    setOffer(false);
  };

 

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const addressClick = () => {
    navigate("/address");
  };

  const ordersClick = () => {
    navigate("/orders");
  };
  const WishlistClick = () => {
    navigate("/wishlist");
  };

  return (
    <main className="main-bg" id="culture">
      <section className="edpedia-store">
        {offer && (
          <div className="header-title">
            <h1>
              Summer Sale For All Items - OFF 30%! <span>Shop Now</span>
              <CloseCircleOutlined
                onClick={handleClsoe}
                className="closeIcon"
              />
            </h1>
          </div>
        )}
        <div className="header-container">
          <h5>Edpedia Store</h5>
          <Input.Search
            className="store_search"
            placeholder="Search for item"
          />
          <div className="filter-container" onClick={addressClick}>
            {/* <img src={StoreFilter} alt="Filters" className="icon" /> */}
            <h5>Address</h5>
          </div>
          <div className="filter-container" onClick={ordersClick}>
            {/* <img src={StoreFilter} alt="Filters" className="icon" /> */}
            <h5>Orders</h5>
          </div>
          <div className="filter-container" onClick={WishlistClick}>
            {/* <img src={StoreFilter} alt="Filters" className="icon" /> */}
            <h5>Wishlist</h5>
          </div>
          <div className="filter-container">
            <img src={StoreFilter} alt="Filters" className="icon" />
            <h5>Filters</h5>
          </div>
          <div className="filter-container" onClick={() => setCartClick(true)}>
            <img src={StoreCart} alt="Cart" className="icon" />
            <h5>Cart</h5>
          </div>
        </div>
        <CategoryList
          setSelected={setSelected}
          setSelectedSubId={setSelectedSubId}
        />
        {selected === "home" && (
          <div>
            <Carousel
              autoplay
              autoplaySpeed={1000}
              style={{ width: 1150, height: 300 }}
            >
              <div>
                <img src={CarousalImage1} alt="HomeIcon" style={contentStyle} />
              </div>
              <div>
                <img src={CarousalImage1} alt="HomeIcon" style={contentStyle} />
              </div>
              <div>
                <img src={CarousalImage1} alt="HomeIcon" style={contentStyle} />
              </div>
              <div>
                <img src={CarousalImage1} alt="HomeIcon" style={contentStyle} />
              </div>
            </Carousel>
            <div className="dicount-container-main">
              <h1>Discounts For You</h1>
              <div className="discount-container">
                <div className="category-item">
                  <img src={Uniform} alt="HomeIcon" className="img-icon" />
                  <h3>Uniforms</h3>
                </div>
                <div className="category-item">
                  <img src={TextBooks} alt="HomeIcon" className="img-icon" />
                  <h3>Textbooks</h3>
                </div>
                <div className="category-item">
                  <img src={Stationery} alt="HomeIcon" className="img-icon" />
                  <h3>Stationery</h3>
                </div>
                <div className="category-item">
                  <img src={Bags} alt="HomeIcon" className="img-icon" />
                  <h3>Bags</h3>
                </div>
                <div className="category-item">
                  <img src={footWear} alt="HomeIcon" className="img-icon" />
                  <h3>Footwear</h3>
                </div>
                <div className="category-item">
                  <img src={Toys} alt="HomeIcon" className="img-icon" />
                  <h3>Toys and Greetings</h3>
                </div>
              </div>
            </div>
            <div
              className="dicount-container-main"
              style={{ marginBottom: 20 }}
            >
              <h1>Best Sports Wear</h1>
              <div className="discount-container">
                <div className="category-item">
                  <img src={Uniform} alt="HomeIcon" className="img-icon" />
                  <h3>Uniforms</h3>
                </div>
                <div className="category-item">
                  <img src={TextBooks} alt="HomeIcon" className="img-icon" />
                  <h3>Textbooks</h3>
                </div>
                <div className="category-item">
                  <img src={Stationery} alt="HomeIcon" className="img-icon" />
                  <h3>Stationery</h3>
                </div>
                <div className="category-item">
                  <img src={Bags} alt="HomeIcon" className="img-icon" />
                  <h3>Bags</h3>
                </div>
                <div className="category-item">
                  <img src={footWear} alt="HomeIcon" className="img-icon" />
                  <h3>Footwear</h3>
                </div>
                <div className="category-item">
                  <img src={Toys} alt="HomeIcon" className="img-icon" />
                  <h3>Toys and Greetings</h3>
                </div>
              </div>
            </div>
          </div>
        )}
        {selected !== "home" && (
          <SubCategoryList selectedSudId={selectedSudId} />
        )}
        {selected === "home" && cartClick === true && (
          <div>
            <Radio.Group
              defaultValue={selectedOption}
              buttonStyle="solid"
              onChange={handleOptionChange}
            >
              <Radio.Button value="cart">Cart</Radio.Button>
              <Radio.Button value="wishlist">Wishlist</Radio.Button>
            </Radio.Group>
            {selectedOption === "cart" && <CartProducts />}
            {selectedOption === "wishlist" && <CartWishlist />}
          </div>
        )}
      </section>
    </main>
  );
};

export default EdpediaStore;
