import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Dropdown,
  Menu,
  Spin,
  message,
  notification,
} from "antd";
import {
  DownOutlined,
  EditOutlined,
  DeleteOutlined,
  LeftOutlined,
  DislikeOutlined,
} from "@ant-design/icons";
import Cookies from "js-cookie";
// import { EdpediaStoreService } from "../../../Services/EdpediaStoreService";
import AddressCreateModal from "./AddressCreateModal";

import "./Address.scss";
import { useNavigate } from "react-router-dom";
import {
  useGetFindAddressByUserQuery,
  useLazyRemoveAddressQuery,
} from "../../../RTKQuery/rtkEdpediaStore";

const { Meta } = Card;
const EdpediaStoreService = [];

const Address = () => {
  const navigate = useNavigate();
  const userId = Cookies.get("userId");
  const studentInfo = Cookies.get("studentInfo")
    ? JSON.parse(Cookies.get("studentInfo"))
    : [];
  const studentId = studentInfo?.rollNo;
  // const [addressList, setAddressList] = useState([]);
  const [visible, setVisible] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState([]);
  const {
    data: addressList,
    isLoading,
    isError,
    refetch,
  } = useGetFindAddressByUserQuery(studentId);

  const handleEdit = (address) => {
    setSelectedAddress(address);
    setVisible(true);
  };

  const [deleteResource, { isLoading: isDeleting }] =
    useLazyRemoveAddressQuery();

  if (isLoading) {
    return (
      <div className="centered_spin">
        <Spin />
      </div>
    );
  }

  const handleDelete = async (id) => {
    try {
      const response = await deleteResource(id);
      if (response?.error) {
        notification.error({
          placement: "bottomRight",
          message: `${response?.error?.data?.message} `,
          icon: <DislikeOutlined style={{ color: "red" }} />,
        });
      } else {
        refetch();
        notification.success({
          message: `${response?.data?.data}`,
          placement: "bottomRight",
        });
      }
    } catch (error) {
      console.error("An error occurred:", error);
    } finally {
      refetch();
    }
  };

  const menu = (address) => (
    <Menu>
      <Menu.Item key="edit" onClick={() => handleEdit(address)}>
        <EditOutlined /> Edit
      </Menu.Item>
      <Menu.Item key="delete" onClick={() => handleDelete(address.id)}>
        <DeleteOutlined /> Delete
      </Menu.Item>
    </Menu>
  );

  const handleBack = () => {
    navigate("/edpediaStore");
  };

  return (
    <>
      <main>
        <section className="address-container">
          <div className="address-header">
            <div className="back-container">
              <Button className="button" onClick={handleBack}>
                <LeftOutlined />
              </Button>
              <h1>Address List</h1>
            </div>
            <Button onClick={() => setVisible(true)} className="button">
              Create
            </Button>
          </div>

          {addressList?.data?.map((address) => (
            <div className="address-cards-conatainer">
              <Card
                key={address.id}
                className="address-card"
                title={address.fullName}
                extra={
                  <Dropdown overlay={() => menu(address)}>
                    <Button icon={<DownOutlined />} />
                  </Dropdown>
                }
              >
                <p>{`${address.addressLine1}, ${address.city}, ${address.country}`}</p>
              </Card>
            </div>
          ))}
        </section>
        <AddressCreateModal
          visible={visible}
          setVisible={setVisible}
          selectedAddress={selectedAddress}
          // getAddressList={getAddressList}
        />
      </main>
    </>
  );
};

export default Address;
