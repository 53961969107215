// import React from "react";
// import "./Holiday.css";
// import { BsDashCircle } from "react-icons/bs";
// import { AiFillCloseCircle } from "react-icons/ai";
// import { BiBlock } from "react-icons/bi";
// import { Calendar } from 'antd';

// const Holiday = () => {
//   return (
//     <div className="main-bg" id="culture">
//       <h3>Holidays & Leaves </h3>

//       <article className="auto flexDir-sty flex-sty">
//         <div className="Table">
//           <table>
//             <thead className="holiday-header">
//               <tr>
//                 <th>Monday</th>
//                 <th>Tuesday</th>
//                 <th>Wednesday</th>
//                 <th>Thurday</th>
//                 <th>Friday</th>
//                 <th>Saturday</th>
//                 <th>Sunday</th>
//                 {/* <th>October</th> */}
//               </tr>
//             </thead>
//             <tbody>
//               <tr>
//                 <td className="holiday-data">
//                   <div className="attendance-box div-sty">
//                     <span className="attend-check">01</span>
//                   </div>
//                 </td>
//                 <td className="holiday-data">
//                   <div className="attendance-box div-sty">
//                     <span className="attend-check">02</span>
//                   </div>
//                 </td>
//                 <td className="holiday-data">
//                   <div className="attendance-box div-sty">
//                     <span className="attend-check">03</span>
//                   </div>
//                 </td>
//                 <td className="holiday-data">
//                   <div className="attendance-box div-sty">
//                     <span className="attend-check">
//                       <small>Diwell</small>
//                       04
//                       <span
//                         className="flex-sty span-text"
//                         style={{ background: "#096CC8" }}
//                       >
//                         <BsDashCircle className="attend-icon" />
//                         Holiday
//                       </span>
//                     </span>
//                   </div>
//                 </td>
//                 <td className="holiday-data">
//                   <div className="attendance-box div-sty">
//                     <span className="attend-check">05</span>
//                   </div>
//                 </td>
//                 <td className="holiday-data">
//                   <div className="attendance-box div-sty">
//                     <span className="attend-check">06</span>
//                   </div>
//                 </td>
//                 <td className="holiday-data">
//                   <div className=" disabled attendance-box div-sty">
//                     <span className="attend-check">
//                       07
//                       <span
//                         className="flex-sty span-text"
//                         style={{ background: "#096CC8" }}
//                       >
//                         <BsDashCircle className="attend-icon" />
//                         Holiday
//                       </span>
//                     </span>
//                   </div>
//                 </td>
//               </tr>

//               <tr>
//                 <td className="holiday-data">
//                   <div className="attendance-box div-sty">
//                     <span className="attend-check">
//                       08
//                       <span
//                         className="flex-sty  flexBox-sty span-text"
//                         style={{ background: "red" }}
//                       >
//                         <AiFillCloseCircle className="attend-icon" />
//                         Absent
//                       </span>
//                     </span>
//                   </div>
//                 </td>

//                 <td className="holiday-data">
//                   <div className="attendance-box div-sty">
//                     <span className="attend-check">09</span>
//                   </div>
//                 </td>

//                 <td className="holiday-data">
//                   <div className="attendance-box div-sty">
//                     <span className="attend-check">10</span>
//                   </div>
//                 </td>
//                 <td className="holiday-data">
//                   <div className="attendance-box div-sty">
//                     <span className="attend-check">
//                       11
//                       <span
//                         className="flex-sty span-text  flexBox-sty"
//                         style={{ background: "#7E8184" }}
//                       >
//                         <BiBlock className="attend-icon" />
//                         Leave
//                       </span>
//                     </span>
//                   </div>
//                 </td>
//                 <td className="holiday-data">
//                   <div className="attendance-box div-sty">
//                     <span className="attend-check">12</span>
//                   </div>
//                 </td>
//                 <td className="holiday-data">
//                   <div className="attendance-box div-sty">
//                     <span className="attend-check">13</span>
//                   </div>
//                 </td>
//                 <td className="holiday-data">
//                   <div className=" disabled attendance-box div-sty">
//                     <span className="attend-check">14</span>
//                   </div>
//                 </td>
//               </tr>

//               {/* another  */}

//               <tr>
//                 <td className="holiday-data">
//                   <div className="attendance-box div-sty">
//                     <span className="attend-check">15</span>
//                   </div>
//                 </td>

//                 <td className="holiday-data">
//                   <div className="attendance-box div-sty">
//                     <span className="attend-check">16</span>
//                   </div>
//                 </td>

//                 <td className="holiday-data">
//                   <div className="attendance-box div-sty">
//                     <span className="attend-check">17</span>
//                   </div>
//                 </td>
//                 <td className="holiday-data">
//                   <div className="attendance-box div-sty">
//                     <span className="attend-check">
//                       <small>Karthika Masam</small>
//                       18
//                       <span
//                         className="flex-sty span-text flexBox-sty"
//                         style={{ background: "#096CC8" }}
//                       >
//                         <BsDashCircle className="attend-icon" />
//                         Holiday
//                       </span>
//                     </span>
//                   </div>
//                 </td>
//                 <td className="holiday-data">
//                   <div className="attendance-box div-sty">
//                     <span className="attend-check">19</span>
//                   </div>
//                 </td>
//                 <td className="holiday-data">
//                   <div className="attendance-box div-sty">
//                     <span className="attend-check">20</span>
//                   </div>
//                 </td>
//                 <td className="holiday-data">
//                   <div className=" disabled attendance-box div-sty">
//                     <span className="attend-check">21</span>
//                   </div>
//                 </td>
//               </tr>

//               {/*  */}

//               <tr>
//                 <td className="holiday-data">
//                   <div className="attendance-box div-sty">
//                     <span className="attend-check">22</span>
//                   </div>
//                 </td>

//                 <td className="holiday-data">
//                   <div className="attendance-box div-sty">
//                     <span className="attend-check">23</span>
//                   </div>
//                 </td>

//                 <td className="holiday-data">
//                   <div className="attendance-box div-sty">
//                     <span className="attend-check">24</span>
//                   </div>
//                 </td>
//                 <td className="holiday-data">
//                   <div className="attendance-box div-sty">
//                     <span className="attend-check">25</span>
//                   </div>
//                 </td>
//                 <td className="holiday-data">
//                   <div className="attendance-box div-sty">
//                     <span className="attend-check">26</span>
//                   </div>
//                 </td>
//                 <td className="holiday-data">
//                   <div className="attendance-box div-sty">
//                     <span className="attend-check">27</span>
//                   </div>
//                 </td>
//                 <td className="holiday-data">
//                   <div className=" disabled attendance-box div-sty">
//                     <span className="attend-check">28</span>
//                   </div>
//                 </td>
//               </tr>
//               {/*  */}
//               <tr>
//                 <td className="holiday-data">
//                   <div className="attendance-box div-sty">
//                     <span className="attend-check">29</span>
//                   </div>
//                 </td>

//                 <td className="holiday-data">
//                   <div className="attendance-box div-sty">
//                     <span className="attend-check">30</span>
//                   </div>
//                 </td>
//               </tr>
//             </tbody>
//           </table>
//         </div>
//       </article>
//       <div className="attendance-rates">
//         <div className="rates-item flex-sty">
//           <div className="rate-circle" style={{ background: "#935252" }} />
//           <small>School Working Days - 09</small>
//         </div>
//         <div className="rates-item flex-sty">
//           <div className="rate-circle" />
//           <small>Absent Days - 01</small>
//         </div>
//         <div className="rates-item flex-sty">
//           <div className="rate-circle" style={{ background: "#7E8184" }} />
//           <small>Leave Days - 01</small>
//         </div>
//         <div className="rates-item flex-sty">
//           <div className="rate-circle" style={{ background: "#096CC8" }} />
//           <small>Holidays - 02</small>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Holiday;

// // import { Calendar } from 'antd';
// // import { useState } from 'react';

// // function Holiday() {
// //   const [selectedDate, setSelectedDate] = useState(null);

// //   const onPanelChange = (date, mode) => {
// //     setSelectedDate(date);
// //   };

// //   const dateCellRender = (date) => {
// //     const isSelected = date.isSame(selectedDate, 'day');
// //     const dateStyle = {
// //       backgroundColor: isSelected ? 'red' : 'white',
// //     };
// //     return <div style={dateStyle}>{date.date()}</div>;
// //   };

// //   return (
// //     <div>
// //       <Calendar value={selectedDate} onPanelChange={onPanelChange} dateCellRender={dateCellRender} />
// //     </div>
// //   );
// // }

// // export default Holiday;

import React, { useState } from "react";
import { useGetHolidaysQuery } from "../../RTKQuery/rtkQuery";
import { Button, Radio, Tooltip, Spin } from "antd";
import moment from "moment";
import HolidayList from "./HolidayList/HolidayList";
import Leaves from "./Leaves/Leaves";
import EmptyComponent from "../EmptyComponent/EmptyComponent";

const getMonthData = (value) => {
  if (value.month() === 8) {
    return 1394;
  }
};
function Holiday() {
  const [loading, setLoading] = useState(true);
  const [selectedOption, setSelectedOption] = useState("Holidays");
  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };
  const { data, isLoading, isError } = useGetHolidaysQuery();

  if (isLoading) {
    return (
      <div className="centered_spin">
        <Spin />
      </div>
    );
  }
  if (isError) {
    return (
      <div className="ErrorContainer">
        <EmptyComponent />
      </div>
    );
  }

  const holidaysArray = data?.data?.data;

  const HolidaysCount = data?.data?.data.length;

  const dateRender = (current) => {
    const formattedDate = moment(current).format("YYYY-MM-DD");
    const isHoliday = holidaysArray.find((h) => h.date === formattedDate);
    if (isHoliday) {
      return (
        <div
          className="ant-picker-cell-inner"
          style={{ backgroundColor: "red" }}
        >
          <div>{current.date()}</div>
          <span
          // onMouseEnter={() => setIsonHoverDate(moment("YYYY-MM-DD"))}
          ></span>
        </div>
      );
    } else {
      return <div className="ant-picker-cell-inner">{current.date()}</div>;
    }
  };

  const monthCellRender = (value) => {
    const num = getMonthData(value);
    return num ? <div className="notes-month"></div> : null;
  };

  const dateCellRender = (date) => {
    const holiday = holidaysArray.find((holiday) =>
      holiday.holidayDate.includes(date.format("YYYY-MM-DD"))
    );

    if (holiday) {
      return (
        <Tooltip
          title={
            <div className="toolTip">
              <p>Holiday Name:&nbsp;{holiday.holidayName}</p>
              <p>Observance:&nbsp;{holiday.observance}</p>
              <p>Description:&nbsp;{holiday.description}</p>
              <p>Holiday Type:&nbsp;{holiday.holidayType}</p>
            </div>
          }
        >
          <div>
            <p>{holiday.holidayName.substring(0, 4) + "..."}</p>
            <p>{holiday.holidayType.substring(0, 4) + "..."}</p>
          </div>
        </Tooltip>
      );
    }
  };

  const noDataFunction = () => {
    setLoading(false);
  };

  return (
    <>
      <div className="main-bg holidaysMainbg">
       
          <div className="radioparent">
          <Radio.Group
            className="mainradio"
            defaultValue={selectedOption}
            buttonStyle="solid"
            onChange={handleOptionChange}
          >
            <Radio.Button value="Holidays">Holidays</Radio.Button>
            <Radio.Button value="Leaves">Leaves</Radio.Button>
          </Radio.Group>
          </div>
        
        {selectedOption == "Holidays" && (
          <div className="sidebar-scroll-container">
            <div className="tabholidayview">
              <div className="holidayviewlft">
                {loading ? (
                  <div className="loadingHolidays">
                    <Spin size="large" />
                    <p>{noDataFunction()}</p>
                  </div>
                ) : (
                  HolidaysCount > 0 && (
                    <>
                      <h4 className="holidayNumbers">
                        Total Holidays : {HolidaysCount}
                      </h4>
                      <div className="holidadyleft">
                        <HolidayList holidays={holidaysArray} />
                      </div>
                    </>
                  )
                )}
              </div>

              {/* <div className="holidayviewrft">
              <div>
                {loading ? (
                  <div className="loadingCallenderCss">
                    <Spin size="large" />
                    <p>{noDataFunction()}</p>
                  </div>
                ) : HolidaysCount > 0 ? (
                  <div>
                    <Calendar
                      style={{ color: "white" }}
                      dateCellRender={dateRender}
                      monthCellRender={monthCellRender}
                      defaultValue={moment()}
                    />
                  </div>
                ) : (
                  <div className="loadingCallenderCss">
                    <EmptyComponent />
                  </div>
                )}
              </div>
            </div>*/}
            </div>
          </div>
        )}
        {selectedOption == "Leaves" && <Leaves />}
      </div>
    </>
  );
}

export default Holiday;
