import React from "react";
import { EdpediaStore, Schoolname, Footer } from "../../components";

function EdpediaUserStore() {
  return (
    <main>
      <Schoolname />
      <EdpediaStore />
    </main>
  );
}

export default EdpediaUserStore;
