import React, { useState, useEffect } from "react";
import { Card, Row, Col, Space, Typography } from "antd";
import Cookies from "js-cookie";
import "./Orders.scss";

import { useGetOrderListQuery } from "../../../RTKQuery/rtkEdpediaStore";

const { Title, Text } = Typography;
const EdpediaStoreService = [];
const Orders = () => {
  // const [ordersList, setOrdersList] = useState([]);
  const userId = Cookies.get("userId");
  const studentInfo = Cookies.get("studentInfo")
    ? JSON.parse(Cookies.get("studentInfo"))
    : [];
  const studentId = studentInfo?.rollNo;

  const { data: ordersList } = useGetOrderListQuery(studentId);

  return (
    <main>
      <section className="orders-container">
        <Title level={2}>Orders</Title>
        <Row gutter={[16, 16]}>
          {ordersList?.data?.map((order) => (
            <Col span={24} key={order.id}>
              <Card
                title={`Order ID: ${order.id}`}
                extra={<Text strong>{order.status}</Text>}
              >
                <Space className="item-container">
                  <Text>Total Amount: ${order.totalAmount}</Text>
                  <Text>Payment Method: {order.paymentMethod}</Text>
                  <Text>
                    Order Date: {new Date(order.orderDate).toLocaleString()}
                  </Text>
                </Space>

                <Card type="inner" title="Products">
                  {order?.products?.map((product) => (
                    <Space key={product.productId} direction="vertical">
                      <Text>
                        {product.productName} ({product.quantity} x $
                        {product.quantity})
                      </Text>
                      <Text>
                        Brand: {product.brandName}, Size: {product.sizeName}
                      </Text>
                    </Space>
                  ))}
                </Card>

                <Card type="inner" title="Shipping Address">
                  <Space direction="vertical">
                    <Text>{order.shippingAddress.fullName}</Text>
                    <Text>
                      {order.shippingAddress.addressLine1},{" "}
                      {order.shippingAddress.addressLine2}
                    </Text>
                    <Text>
                      {order.shippingAddress.city},{" "}
                      {order.shippingAddress.state}{" "}
                      {order.shippingAddress.postalCode}
                    </Text>
                    <Text>Country: {order.shippingAddress.country}</Text>
                    <Text>
                      Mobile Number: {order.shippingAddress.mobileNumber}
                    </Text>
                    <Text>Landmark: {order.shippingAddress.landmark}</Text>
                  </Space>
                </Card>
              </Card>
            </Col>
          ))}
        </Row>
      </section>
    </main>
  );
};

export default Orders;
