import React, { useEffect, useState } from "react";
import { AccountBookOutlined, ApartmentOutlined } from "@ant-design/icons";
import { Pie, Column } from "@ant-design/plots";
import {
  useGetLatestActivityQuery,
  useGetOverralSyllabusPercentageQuery,
  useGetStudentInformationQuery,
  useGetStudentPositionQuery,
  useGetTodayTimetableQuery,
} from "../../RTKQuery/rtkQuery";

import gradeIcon from "../../assets/icons/Mask_Group.svg";

import { useGetExamQuery } from "../../RTKQuery/rtkQuery";
import EmptyComponent from "../EmptyComponent/EmptyComponent";
import { Card, Progress, Spin, Table } from "antd";
import "./Dashboard.css";
import Cookies from "js-cookie";
import DashboardAssignments from "./DashboardAssignments";
import DashboardAttendace from "./DashboardAttendace";
import { useSelector } from "react-redux";
function Dashboard() {
  const classInfo = Cookies.get("classInfo")
    ? JSON.parse(Cookies.get("classInfo"))
    : [];

  // useEffect(()=>{

  // },[])

  const studentInfo = useSelector((state) => state.studentInfo.studentInfo);
  const studentId = studentInfo?.studentId;
  const classId = studentInfo?.classId;

  const {
    data: getUpcomingExamData,
    isLoading: IsLoadingUpcomingExam,
    isError: isErrorUpcomingExam,
  } = useGetExamQuery(classId);

  const { data: getPositionData } = useGetStudentPositionQuery(studentId);
  const { data: getSyllabusPercentage } =
    useGetOverralSyllabusPercentageQuery(classId);

  console.log("getSyllabusPercentage", getSyllabusPercentage);

  const { data, isLoading, isError } = useGetLatestActivityQuery();
  const [timeTable, setTimetable] = useState([]);
  const [studentData, setStudentData] = useState([]);
  const {
    data: TodayTimetableData,
    isLoading: TodayTimetableisLoading,
    isError: TodayTimetableisError,
  } = useGetTodayTimetableQuery(studentId);

  const latestActivity = data?.data;
  const syllabusData = getSyllabusPercentage?.data;
  const TodayTimetableDataMain = TodayTimetableData?.data;

  // const exam = Object.values(getUpcomingExamData?.data?.UPCOMING).flat();
  let exam = [];
  if (getUpcomingExamData?.data?.UPCOMING) {
    exam = Object.values(getUpcomingExamData?.data?.UPCOMING).flat() || [];
  }

  console.log("exam", exam);

  const examCards = exam?.map((exam, index) => (
    <div className="exam-cards" key={index}>
      <div className="exam-card">
        <p>Exam Name: {exam.examName}</p>
        <p>Subject: {exam.subject}</p>
        <p>Date: {exam.examDate}</p>
        <p>
          Time: {exam.startTime} - {exam.endTime}
        </p>
      </div>
      <img
        style={{ width: 40, height: 40 }}
        src={exam.logoImg}
        alt={exam.subject}
        className="examimg"
      />
    </div>
  ));

  const removeNullPeriods = () => {
    setTimetable(TodayTimetableDataMain);
  };
  const { data: StudentDetails } = useGetStudentInformationQuery(studentId);

  useEffect(() => {
    if (StudentDetails) {
      setStudentData(StudentDetails?.data);
      Cookies.set("studentInfo", JSON.stringify(StudentDetails?.data));
    }
  }, [StudentDetails]);

  useEffect(() => {
    removeNullPeriods();
  }, [TodayTimetableDataMain]);

  const TodayTimetableDataMainList = timeTable
    ? Object.keys(timeTable)
        .filter((key) => key.startsWith("period") && timeTable[key] !== null)
        .map((key) => ({ period: key, ...timeTable[key] }))
    : [];

  if (isLoading || TodayTimetableisLoading || IsLoadingUpcomingExam) {
    return (
      <div className="centered_spin">
        <Spin />
      </div>
    );
  }

  // if (isError || TodayTimetableisError || isErrorUpcomingExam) {
  //   return (
  //     <div className="ErrorContainer">
  //       <EmptyComponent />
  //     </div>
  //   );
  // }

  const getstudentPositionAndRank = getPositionData;
  console.log("getstudentPositionAndRank", getstudentPositionAndRank);
  const getActivityItemStyle = (latestActivityId) => {
    switch (latestActivityId) {
      case 1:
        return { backgroundColor: "#FFC0CB" }; // Light Pink
      case 2:
        return { backgroundColor: "#98FB98" }; // Pale Green
      case 3:
        return { backgroundColor: "#ADD8E6" }; // Light Blue
      case 4:
        return { backgroundColor: "#FFFFE0" }; // Light Yellow
      case 5:
        return { backgroundColor: "#FFD700" }; // Gold
      default:
        return { backgroundColor: "#D3D3D3" }; // Light Gray
    }
  };
  const columns = [
    {
      title: "Exam Name",
      dataIndex: "examName",
      key: "examName",
    },
    {
      title: "Class Name",
      dataIndex: "className",
      key: "className",
    },
    {
      title: "Subject",
      dataIndex: "subject",
      key: "subject",
    },
    {
      title: "Exam Date",
      dataIndex: "examDate",
      key: "examDate",
    },
    // {
    //   title: "Status",
    //   dataIndex: "status",
    //   key: "status",
    // },
  ];

  return (
    <section className=" dashboard-main-container">
      <div className="dash-main">
        <div className="dash-col">
          <h2>Attendance</h2>
          <DashboardAttendace />
        </div>
        <div className="dash-col">
          <h2>Overall Syllabus</h2>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
              gap: 3,
            }}
          >
            <div className="overall-Grade">
              <div className="position grade">
                <div
                  style={{ display: "flex", alignItems: "center" }}
                  className="grade-text"
                >
                  <h5>Overall Grade : </h5>
                  <span style={{ paddingLeft: 5 }}>
                    {getstudentPositionAndRank === null ? (
                      <span style={{ color: "red" }}>Result Not Declared </span>
                    ) : (
                      getstudentPositionAndRank?.data?.grade
                    )}
                  </span>
                </div>
                <div
                  style={{ display: "flex", alignItems: "center" }}
                  className="grade-text"
                >
                  <h5>Rank : </h5>
                  <span style={{ paddingLeft: 5 }}>
                    {getstudentPositionAndRank == null ? (
                      <span style={{ color: "red" }}>Result Not Declared </span>
                    ) : (
                      getstudentPositionAndRank?.data?.rank
                    )}
                  </span>
                </div>
                <img src={gradeIcon} alt="gradeIcon" className="grade-icon" />
              </div>
            </div>
            <div className="common-bgs syllabus">
              <div style={{ display: "flex", gap: "10px" }}>
                <h5>Syllabus</h5>
                <Progress
                  // percent={getSyllabusPercentage}
                  // size="small"
                  // status="active"
                  // strokeWidth={20}
                  percent={syllabusData?.completedPercentage}
                  strokeColor={
                    syllabusData?.completedPercentage >= 0 &&
                    syllabusData?.completedPercentage <= 30
                      ? "#aa0b28"
                      : syllabusData?.completedPercentage >= 31 &&
                        syllabusData?.completedPercentage <= 75
                      ? "#cbaf1e"
                      : "#5f9e20"
                  }
                  format={() => (
                    <span
                      style={{
                        fontSize: "12px",
                        textAlign: "center",
                        height: "13px",
                      }}
                    >
                      {syllabusData?.completedPercentage.toFixed(0)} %
                    </span>
                  )}
                />
              </div>
              <diV className="bar">
                <div
                  style={{ display: "flex", justifyContent: "center", gap: 25 }}
                >
                  <div className="syllabus-text">
                    <span>
                      {syllabusData?.completedPercentage.toFixed(2)} %
                    </span>
                    <p>Completed </p>
                  </div>

                  <div className="syllabus-text">
                    <span>{syllabusData?.pendingPercentage.toFixed(2)} %</span>
                    <p>Pending </p>
                  </div>
                </div>
              </diV>
            </div>
          </div>
        </div>
      </div>

      <div className="dash-main">
        <div className="dash-col">
          <h2>Upcoming Exams</h2>

          <div className="tablediv" style={{ height: 200, overflow: "scroll" }}>
            <Table dataSource={exam} columns={columns} pagination={false} />
          </div>
          {/* <div className="common-bgs sidebar-scroll-container exam-container"> */}
          {/* <EmptyComponent /> */}
          {/* {examCards.length > 0 ? (
              examCards
            ) : (
              <h5>
                <EmptyComponent />
              </h5>
            )} */}

          {/* <div className="exam-cards">
            <div className="exam-card">
              <p>Subject : English </p>
              <p>Date : 23-10-2023</p>
              <p>Time : 2:00 pm</p>
            </div>
            <AccountBookOutlined className="examimg" />
          </div>
          <div className="exam-cards">
            <div className="exam-card">
              <p>Subject : English </p>
              <p>Date : 23-10-2023</p>
              <p>Time : 2:00 pm</p>
            </div>
            <AccountBookOutlined className="examimg" />
          </div> */}
          {/* </div> */}
        </div>

        <div className="dash-col">
          <h2>Assignments</h2>
          <div className="dashboard-content" style={{ marginTop: 10 }}>
            <div className="dashboard-table flexBox-sty">
              <div
                style={{
                  display: "flex",
                  gap: "25px",
                  alignItems: "center",
                  marginRight: 20,
                }}
              >
                <div
                  style={{ display: "flex", gap: "5px", alignItems: "center" }}
                >
                  <span className="roundbtn1"></span>
                  <strong style={{ fontSize: 13, fontWeight: 700 }}>
                    Completed
                  </strong>
                </div>
                <div
                  style={{ display: "flex", gap: "5px", alignItems: "center" }}
                >
                  <span className="roundbtn2"></span>

                  <strong style={{ fontSize: 13, fontWeight: 700 }}>
                    Pending
                  </strong>
                </div>
              </div>
            </div>
          </div>
          <DashboardAssignments />
        </div>
      </div>

      <div className="dash-main">
        <div className="dash-col">
          <div>
            <h2>Latest Activity</h2>
            <div className="latest-activicty">
              {latestActivity?.length > 0 ? (
                <>
                  {latestActivity.map((activity) => (
                    <div
                      key={activity.latestActivityId}
                      className="activity-item"
                      style={getActivityItemStyle(activity.latestActivityId)}
                    >
                      {activity.latestActivity}
                    </div>
                  ))}
                </>
              ) : (
                <EmptyComponent />
              )}
            </div>
          </div>
          {/* <div className="actilist">
              <div className="accol">
                <span className="acdate colr1">
                  From 24-05-2023 To 07-06-2023
                </span>
                <span className="accontent">Your Absent School From</span>
              </div>
              <div className="accol">
                <span className="acdate colr2">
                  From 24-05-2023 To 07-06-2023
                </span>
                <span className="accontent">
                  We Are Having Cultural Programs
                </span>
              </div>
              <div className="accol">
                <span className="acdate colr3">
                  From 24-05-2023 To 07-06-2023
                </span>
                <span className="accontent">
                  Management Declares X-Mas Holidays
                </span>
              </div>
              <div className="accol">
                <span className="acdate colr4">
                  From 24-05-2023 To 07-06-2023
                </span>
                <span className="accontent">
                  Half Yearly Exam Will Be Held From{" "}
                </span>
              </div>
              <div className="accol">
                <span className="acdate colr1">
                  From 24-05-2023 To 07-06-2023
                </span>
                <span className="accontent">
                  Monthly Exam Results Announced{" "}
                </span>
              </div>
            </div>
          </div>*/}
        </div>
      </div>

      <div className="dashboard-updated_grid grid-sty"></div>
      {/* /exam row/ */}

      {/* dashboard */}
      {/* <div className="dashboard-content">
        <div className="dashboard-table flexBox-sty">
          <h5>Time Table</h5>
          <div
            style={{
              display: "flex",
              gap: "25px",
              alignItems: "center",
              marginRight: 20,
            }}
          >
            <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
              <span className="roundbtn1"></span>
              <strong style={{ fontSize: 13, fontWeight: 700 }}>
                Completed{" "}
              </strong>
            </div>
            <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
              <span className="roundbtn2"></span>

              <strong style={{ fontSize: 13, fontWeight: 700 }}>Pending</strong>
            </div>
          </div>
        </div>
      </div> */}
      {/* <DashboardTimetable /> */}

      {/* activities */}
    </section>
  );
}

export default Dashboard;
