import React from "react";
import "./Dashboard.css";
import { Card, Spin } from "antd";
import EmptyComponent from "../EmptyComponent/EmptyComponent";
import { useGetAllAssignmentsQuery } from "../../RTKQuery/rtkQuery";
import Cookies from "js-cookie";
import { SlackOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";

const DashboardAssignments = () => {
  // const studentId = Cookies.get("userId");
  const studentInfo = useSelector((state) => state.studentInfo.studentInfo);
  const studentId = studentInfo?.studentId;
  const { data, isLoading, isError } = useGetAllAssignmentsQuery(studentId);

  const AllAssignments = data?.data;

  if (isLoading) {
    return (
      <div className="centered_spin">
        <Spin />
      </div>
    );
  }

  // if (isError) {
  //   return (
  //     <div className="ErrorContainer">
  //       <EmptyComponent />
  //     </div>
  //   );
  // }
  const currentTime = new Date();
  return (
    <>
      <div className="dashboard-grid grid-sty gt3 sidebar-scroll-container">
        {AllAssignments?.length > 0 ? (
          <>
            {AllAssignments &&
              AllAssignments.map((item) => {
                const { assignmentId, subjectName, givenDate, mode, img } =
                  item;

                const dueDate = new Date(item.dueDate);
                const givenDateTime = new Date(givenDate);
                const dueDateTime = new Date(dueDate);
                const givenDateFormatted = givenDateTime.toDateString();
                const dueDateFormatted = dueDateTime.toDateString();
                const isCompleted = currentTime > dueDate;
                const isUpcoming = currentTime < dueDate;
                const periodClass = isCompleted
                  ? "completed-period"
                  : isUpcoming
                  ? "upcoming-period"
                  : "";
                return (
                  <div className={`dashboard-grid-item1 ${periodClass}`}>
                    <Card
                      key={assignmentId}
                      className={`dashboard-card ${periodClass}`}
                      cover={
                        <div className="dashboard-grid-item">
                          <div className="dashboard-textContent">
                            <div className="period">
                              <p className="period">{subjectName}</p>
                              <img
                                src={item.subjectLogo}
                                alt="img"
                                className="subject-logo"
                              />
                            </div>
                            <p>Given Date : {givenDateFormatted}</p>
                            <p>Due Date : {dueDateFormatted}</p>
                            <p>Mode: {mode}</p>
                          </div>
                        </div>
                      }
                    ></Card>
                  </div>
                );
              })}
          </>
        ) : (
          <div style={{ textAlign: "center", marginLeft: 300, width: "100%" }}>
            <EmptyComponent />
          </div>
        )}
      </div>
    </>
  );
};

export default DashboardAssignments;
