import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import Cookies from "js-cookie";
export const libraryServiceStudent = createApi({
  reducerPath: "libraryServiceStudent",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://api.edpedia.co/v1.0/library/",
    prepareHeaders: (headers, { getState }) => {
      const accessToken = Cookies.get("accessToken");
      if (accessToken) {
        headers.set("Authorization", `Bearer ${accessToken}`);
      }

      headers.set("Content-Type", "application/json");
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getAllBooks: builder.query({
      query: () => {
        return {
          url: "/api/book/getBooks",
          method: "GET",
        };
      },
    }),
    getCategories: builder.query({
      query: () => {
        return {
          url: "/api/category/getCategories",
          method: "GET",
        };
      },
    }),
    getRentedBooks: builder.query({
      query: ({ userId, userType }) => {
        return {
          url: `api/rental/booksByStatus?userId=${userId}&userType=${userType}`,
          method: "GET",
        };
      },
    }),
    getAuthors: builder.query({
      query: () => {
        return {
          url: "/api/author/getAuthors",
          method: "GET",
        };
      },
    }),
    getAuthorsFirstAlphabet: builder.query({
      query: (alphabet) => {
        return {
          url: `api/author/getAuthors?firstAlphabet=${alphabet}`,
          method: "GET",
        };
      },
    }),

    categoryBooks: builder.query({
      query: (category) => {
        return {
          url: `/api/book/categoryBooks?categoryName=${category}`,
          method: "GET",
        };
      },
    }),
    // getBookRequest: builder.query({
    //   query: (userId) => {
    //     return {
    //       url: `/api/bookRequest/${userId}/getBookRequests`,
    //       method: "GET",
    //     };
    //   },
    // }),
    postRentalBook: builder.mutation({
      query: ({ userId, bookId, userType }) => ({
        url: "/api/rental/rentBook",
        method: "POST",
        body: { userId, bookId, userType },
      }),
    }),
    postRequestBook: builder.mutation({
      query: ({ bookId, userId, userType }) => ({
        // url: `/api/bookRequest/${bookId}/requestBook?userId=${userId}&requestedDate=${requestedDate}&userType=${userType}`,
        url: `/api/bookRequest/${bookId}/requestNotAvailableBook?userId=${userId}&userType=${userType}`,
        method: "POST",
      }),
    }),
    MissingBook: builder.mutation({
      query: (rentalId) => ({
        url: `/api/rental/${rentalId}/reportMissing`,
        method: "POST",
        // body: { userId, bookId, userType, requestedDate },
      }),
    }),
    // /api/book/authorBooks/
    getFamousBooks: builder.query({
      query: () => {
        return {
          url: "/api/book/famousBooks?limit=10",
          method: "GET",
        };
      },
    }),
    getAuthorBooks: builder.query({
      query: ({ authorName }) => {
        return {
          url: `/api/book/authorBooks?authorName=${authorName}`,
          method: "GET",
        };
      },
    }),
    getLatestBooks: builder.query({
      query: () => {
        return {
          url: "/api/book/latestBooks?limit=10",
          method: "GET",
        };
      },
    }),
    getReqBooks: builder.query({
      query: ({ userId, userType }) => {
        return {
          url: `/api/bookRequest/getBookRequests?userId=${userId}&userType=${userType}`,
          method: "GET",
        };
      },
    }),
    getTopAuthors: builder.query({
      query: () => {
        return {
          url: `/api/author/topAuthors?limit=5`,
          method: "GET",
        };
      },
    }),
    getAllSuggestionBookById: builder.query({
      query: ({ userId, userType }) => {
        return {
          url: `/api/book/getSuggestionById?userId=${userId}&userType=${userType}`,
          method: "GET",
        };
      },
    }),
    createSuggestedBook: builder.mutation({
      query: ({ payload, userId, userType }) => {
        return {
          url: `/api/book/create/suggestions?userId=${userId}&userType=${userType}`,
          method: "POST",
          body: payload,
        };
      },
    }),
    editSuggestedBook: builder.mutation({
      query: ({ bookId, payload }) => {
        return {
          url: `/api/book/${bookId}/updateSuggestion`,
          method: "POST",
          body: payload,
        };
      },
    }),
    cancelMissingBookRequest: builder.mutation({
      query: (rentalId) => {
        return {
          url: `/api/rental/${rentalId}/cancelReportMissing`,
          method: "POST",
          // body: payload,
        };
      },
    }),
    // /api/book/getSuggestionById
    getRentalInformationByBook: builder.query({
      query: (bookId) => {
        return {
          url: `/api/rental/rentalInformationByBook?bookId=${bookId}`,
          method: "GET",
        };
      },
    }),
  }),
});
export const {
  useMissingBookMutation,
  useGetRentalInformationByBookQuery,
  useGetTopAuthorsQuery,
  useGetReqBooksQuery,
  useGetLatestBooksQuery,
  useGetFamousBooksQuery,
  usePostRequestBookMutation,
  usePostRentalBookMutation,
  useCategoryBooksQuery,
  useGetAuthorsFirstAlphabetQuery,
  useGetAllBooksQuery,
  useGetCategoriesQuery,
  useGetRentedBooksQuery,
  useGetAuthorsQuery,
  useGetAllSuggestionBookByIdQuery,
  useCreateSuggestedBookMutation,
  useEditSuggestedBookMutation,
  useGetAuthorBooksQuery,
  useCancelMissingBookRequestMutation,
} = libraryServiceStudent;
