import React, { useState } from "react";
import "./CheckoutForm.scss";
import { Button, Col, Row, Modal, Form, Input, Radio } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import FloatInput from "../../Common/FloatLable/Input";
import AddressForm from "./AddressForm";
import AddressCard from "./AddressCard";
import PaymentCard from "./PaymentCard";
const RadioGroup = Radio.Group;
const CheckOutForm = (props) => {
  const { checkOutProduct } = props;
  const [showAddressModal, setShowAddressModal] = useState(false);
  const [activeTabKey, setActiveTabKey] = useState("1");
  const addressModalOpen = () => {
    setShowAddressModal(true);
  
  };
  const handleTabChange = (event) => {
    setActiveTabKey(event.target.value);
  };
  return (
    <>
      {checkOutProduct !== null && (
        <div className="checkout-container">
          <Row gutter={{ md: 10, lg: 12 }}>
            <Col span={16}>
              <div className="price-header">
                {" "}
                <h3>Placed Order</h3>
              </div>
              <div className="address-container">
                <RadioGroup
                  className="annoucement-btns"
                  onChange={handleTabChange}
                  value={activeTabKey}
                  optionType="button"
                  buttonStyle="solid"
                  style={{ marginBottom: 10 }}
                >
                  <Radio.Button className="RadioButtons" value="1">
                    Address
                  </Radio.Button>
                  <Radio.Button className="RadioButtons" value="2">
                    Payment
                  </Radio.Button>
                  <Radio.Button className="RadioButtons" value="3">
                    Item Delivery
                  </Radio.Button>
                </RadioGroup>
                <div className="add-address">
                  <span onClick={addressModalOpen} className="add-address-text">
                    <PlusOutlined /> <span>Add Address</span>
                    <div className="radio-container">
                      {activeTabKey === "1" && (
                        <>
                          <AddressCard />
                        </>
                      )}
                      {activeTabKey === "2" && (
                        <>
                          <PaymentCard />
                        </>
                      )}
                      {activeTabKey === "3" && (
                        <>
                          <h1>hii item</h1>
                        </>
                      )}
                    </div>
                  </span>
                </div>
                <div className="button-div">
                  <Button className="grey-button">Home</Button>
                  <Button className="grey-button">School</Button>
                  <Button className="grey-button">Others</Button>
                </div>
              </div>
            </Col>
            <Col span={7}>
              <div className="price-details-container">
                <div className="price-header">
                  {" "}
                  <h3>Price Details</h3>
                </div>
                <div className="single-item">
                  <span>Price : 1500</span>
                </div>
                <div className="single-item">
                  <span>Deliver Charges : 500</span>
                </div>
                <div className="single-item">
                  <span>Discount : -500/-</span>
                </div>

                <div className=" total single-item">
                  <span>Total Amount : 1500/-</span>
                </div>

                <div className="saving">
                  <span>You saved 500 in this offer .</span>
                </div>

                <div className="delivery-time">
                  <span>Delivery in 2 Dec-10 Dec </span>
                  {/* <Button className="submitButton"> Enter Details</Button> */}
                </div>
              </div>
            </Col>
          </Row>
          <AddressForm
            showAddressModal={showAddressModal}
            setShowAddressModal={setShowAddressModal}
          />
        </div>
      )}
    </>
  );
};

export default CheckOutForm;
