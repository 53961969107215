import React,{ useState } from 'react'
import { three } from "../../assets/images";

function Data1() {
  const [results, setResults] = useState(
    Array.from({ length: 8 }, (v, i) => false)
  );  
  const [className1, setClassName1] = useState(false)
  
  // const octFunction=()=>{
  //   setClassName1(!className1)
      
  // }
  return (
    <div>
    <div className="results-content">
    <div className="div-sty result-content grid-sty gt3">
      <div className="result-content-item flexDir-sty flex-sty text-ct">
        <div
          className="result-circle white-text flexBox-sty"
          id="bg-bblue"
        >
          200
        </div>
        <h3>Exam Marks</h3>
      </div>

      <div className="result-content-item flexDir-sty flex-sty text-ct">
        <div
          className="result-circle white-text flexBox-sty"
          id="bg-gold"
        >
          176
        </div>
        <h3>Your Marks</h3>
      </div>

      <div className="result-content-item flexDir-sty flex-sty text-ct">
        <div
          className="result-circle white-font flexBox-sty"
          id="bg-purple"
        >
          A
        </div>
        <h3>Grade</h3>
      </div>
    </div>

    {/* result gris */}

    <div className="results-grid grid-sty gt3" id="grid1">
      {results.map((item, i) => {
        return (
          <div className="result-item div-sty">
            <div className="result-timer">
              <div className="result-timerText">
                <h5>09-10-2021</h5>
                <small>09.30 AM- 12.30 PM</small>
              </div>
              <div className="result-timerImg">
                <img src={three} alt="three" />
              </div>
            </div>
            <div
              className="result-content grid-sty gt3"
              style={{
                gap: "5px",
              }}
            >
              <div className="result-content-item flexDir-sty flex-sty text-ct">
                <div
                  className="result-circle item-circle white-text flexBox-sty"
                  id="bg-bblue"
                  style={{
                    fontSize: "14px",
                  }}
                >
                  25
                </div>
                <small style={{ fontSize: "10px" }}>Exam Marks</small>
              </div>
              {/*  */}
              <div className="result-content-item flexDir-sty flex-sty text-ct">
                <div
                  className="result-circle item-circle flexBox-sty"
                  style={{
                    background: "#889C37",
                    color: "white",
                    fontSize: "14px",
                  }}
                >
                  22
                </div>
                <small style={{ fontSize: "10px" }}>Your Marks</small>
              </div>
              {/*  */}
              <div className="result-content-item flexDir-sty flex-sty text-ct">
                <div
                  className="result-circle item-circle flexBox-sty"
                  style={{
                    background: "#5A1B49",
                    color: "#FFFFFF",
                    fontSize: "14px",
                  }}
                >
                  Pass
                </div>
                <small style={{ fontSize: "10px" }}>Result</small>
              </div>
            </div>
          </div>
        );
      })}
    </div>

    {/* end result grid */}
  </div>
    </div>
  )
}

export default Data1