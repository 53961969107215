import React from "react";
import { Study, Schoolname } from "../../components";

const Studies = () => {
  return (
    <main>
      <Schoolname />
      <Study />
    </main>
  );
};

export default Studies;
