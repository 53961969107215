import React, { useEffect } from "react";
import "./Study.css";
import {
  DownloadOutlined,
  FolderOpenOutlined,
  ExclamationCircleOutlined,
  DislikeOutlined,
  CheckCircleFilled,
} from "@ant-design/icons";
import { Tabs, Button, Table, Select, Modal, notification } from "antd";
import { useState } from "react";
import { useGetAllStudyMaterialsQuery } from "../../RTKQuery/rtkQuery";
import Cookies from "js-cookie";
import axios from "axios";
import FileView from "./FileView";
import { useSelector } from "react-redux";
const { Option } = Select;
function Study() {
  // const studentInfo = Cookies.get("studentInfo")
  //   ? JSON.parse(Cookies.get("studentInfo"))
  //   : [];
  const studentInfo = useSelector((state) => state.studentInfo.studentInfo);
  const studentId = studentInfo?.studentId;
  const [fileData, setFileData] = useState(null);
  const [view, setView] = useState(false);
  const { data, isLoading, isError } = useGetAllStudyMaterialsQuery(studentId);
  const [studyMaterial, setStudyMaterial] = useState([]);
  const accessToken = Cookies.get("accessToken");

  const handleDownload = (record) => {
    setView(true);
    setFileData(record);
  };
  const columns = [
    {
      title: "Class Name",
      dataIndex: "className",
      key: "className",
      align: "center",
      sorter: (a, b) => a.className.localeCompare(b.className),
    },
    {
      title: "Subject Name",
      dataIndex: "subjectName",
      key: "subjectName",
      align: "center",
      sorter: (a, b) => a.subjectName.localeCompare(b.subjectName),
    },
    {
      title: "Book Title",
      dataIndex: "bookTitle",
      key: "bookTitle",
      align: "center",
      sorter: (a, b) => a.bookTitle.localeCompare(b.bookTitle),
    },
    // {
    //   title: "Description",
    //   dataIndex: "description",
    //   key: "description",
    //   align: "center",
    //   width: 150,
    //   sorter: (a, b) => a.description.localeCompare(b.description),
    // },
    {
      title: "Author",
      dataIndex: "author",
      key: "author",
      align: "center",
      sorter: (a, b) => a.author.localeCompare(b.author),
    },
    {
      title: "Study Materials",
      dataIndex: "staffStudyMaterialUrls",
      key: "staffStudyMaterialUrls",
      align: "center",
      render: (urls) => (
        <span>
          {urls && urls.length > 0 ? (
            <span>
              <FolderOpenOutlined
                onClick={() => handleDownload(urls)}
                style={{ fontSize: "20px" }}
              />
            </span>
          ) : (
            "No PDFs available"
          )}
        </span>
      ),
    },
  ];

  useEffect(() => {
    if (data) {
      const study = data?.data;
      setStudyMaterial(study);
      console.log("ddd", data);
      // setActiveTab(Object.keys(study)[0]);
    }
  }, [data]);

  const subjectKeys = Object?.keys(studyMaterial);
  console.log("subjectKyes", subjectKeys);
  const [selectedSubject, setSelectedSubject] = useState(subjectKeys[0]);

  const handleSubjectChange = (value) => {
    setSelectedSubject(value);
  };

  useEffect(() => {
    if (selectedSubject) {
      setSelectedSubject(selectedSubject);
    } else {
      setSelectedSubject(subjectKeys[0]);
    }
  }, [selectedSubject, subjectKeys]);
  const showDeleteConfirm = (questionPaper, classId, subjectId, examId) => {
    Modal.confirm({
      title: `Are you sure you want to delete Question paper?`,
      icon: <ExclamationCircleOutlined />,
      okType: "danger",
      cancelText: "Cancel",
      onOk() {
        const examToDelete = studyMaterial.find((exam) =>
          exam.questionPaper.includes(questionPaper)
        );

        if (examToDelete) {
          deleteQuestionPaper(
            questionPaper,
            examToDelete.classId,
            examToDelete.subjectId,
            examToDelete.examId
          );
        }
      },
    });
  };
  const deleteQuestionPaper = async (
    questionPaper,
    classId,
    subjectId,
    examId
  ) => {
    const payload = {
      examId: examId,
      classId: classId,
      subjectId: subjectId,
      url: questionPaper,
    };

    try {
      const response = await axios.delete(
        `https://api.edpedia.co/v1.0/staff/api/exam/deleteQuestionPaper `,
        {
          data: payload,
          headers: {
            Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
          },
        }
      );

      if (response.status === 200) {
        const updatedFilteredExams = studyMaterial.map((exam) => {
          if (exam.subjectId === subjectId) {
            return {
              ...exam,
              questionPaper: exam.questionPaper.filter(
                (paper) => paper !== questionPaper
              ),
            };
          }
          return exam;
        });
        setFileData(fileData.filter((item) => item !== questionPaper));
        // setFilteredExams(updatedFilteredExams);
        // getExamsList();
        notification.success({
          message: `Question Paper Deleted Successfully`,
          placement: "bottomRight",
          icon: (
            <CheckCircleFilled
              style={{
                color: "green",
              }}
            />
          ),
        });
      }
    } catch (error) {
      notification.success({
        message: `Subject Paper Deleted Failed`,
        placement: "bottomRight",
        icon: (
          <DislikeOutlined
            style={{
              color: "red",
            }}
          />
        ),
      });
    }
  };
  const downloadExamPaper = async (questionPaperUrl) => {
    try {
      const response = await axios.get(
        `https://api.edpedia.co/v1.0/instAdmin/api/storage/download?url=${questionPaperUrl}`,

        {
          responseType: "blob",
          headers: {
            Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
          },
        }
      );
      const blob = new Blob([response.data], {
        type: "application/octet-stream",
      }); //application/octet-stream

      const blobUrl = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = blobUrl;
      link.click();

      URL.revokeObjectURL(blobUrl);
    } catch (error) {
      notification.success({
        message: `Studymaterial Downloaded Successfully`,
        placement: "bottomRight",
        icon: (
          <DislikeOutlined
            style={{
              color: "red",
            }}
          />
        ),
      });
    }
  };
  return (
    <div className="" id="culture">
      <div className="studies-widget">
        <div className="results-title">
          <h3>Study Material </h3>
          <div>
            <Select
              defaultValue={subjectKeys[0]}
              onChange={handleSubjectChange}
              value={selectedSubject}
              placeholder={"Subjects"}
            >
              {subjectKeys.map((key) => (
                <Option key={key} value={key}>
                  {key.charAt(0).toUpperCase() + key.slice(1)}
                </Option>
              ))}
            </Select>
          </div>
        </div>
      </div>
      <div className="container">
        <Table
          columns={columns}
          dataSource={studyMaterial[selectedSubject]}
          showSorterTooltip={false}
          pagination={false}
          // scroll={{ x: 1000 }}
        />
      </div>
      <FileView
        view={view}
        setView={setView}
        fileData={fileData}
        downloadExamPaper={downloadExamPaper}
      />
    </div>
  );
}
export default Study;
