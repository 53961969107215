import { Button, Col, Modal, Row, Space, notification } from "antd";
import React from "react";
import { DislikeOutlined, CheckCircleFilled } from "@ant-design/icons";
import Cookies from "js-cookie";
import { usePostRequestBookMutation } from "../../../RTKQuery/rtkLibrary";
import { useSelector } from "react-redux";

const RequestBookModal = ({
  isRequestModalVisible,
  setRequestModalVisible,
  selectedBook,
}) => {
  const handleRequestCancel = () => {
    setRequestModalVisible(false);
  };
  const [requestBookMutation] = usePostRequestBookMutation();
  // const studentInfo = Cookies.get("studentInfo")
  //   ? JSON.parse(Cookies.get("studentInfo"))
  //   : [];
  const studentInfo = useSelector((state) => state.studentInfo.studentInfo);
  const studentId = studentInfo?.rollNo;
  const userId = studentId;

  const handleRequestOk = () => {
    requestBookMutation({
      userId: userId,
      bookId: selectedBook.id,
      userType: "Student",
    })
      .then((res) => {
        if (res.error) {
          notification.error({
            placement: "bottomRight",
            message: `Book Is Not Available At This moment . Please Try Again Later`,
            icon: <DislikeOutlined style={{ color: "red" }} />,
          });
          setRequestModalVisible(false);
        } else {
          setRequestModalVisible(false);
          notification.success({
            message: "Book Requested Successfully",
            placement: "bottomRight",
            icon: (
              <CheckCircleFilled
                style={{
                  color: "green",
                }}
              />
            ),
          });
        }
      })
      .catch((error) => {});
  };
  return (
    <>
      <Modal
        open={isRequestModalVisible}
        onCancel={handleRequestCancel}
        footer={null}
        className="custom-modal"
      >
        <div>
          <p
            style={{
              fontSize: "20px",
              fontWeight: "700",
              cursor: "pointer",
              marginBottom: "35px",
            }}
          >
            Are you sure you want to Request the book?
          </p>
          <Row className="my-row" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={12} align="end">
              <Space direction="vertical" size="large">
                <Button onClick={handleRequestCancel}>cancel</Button>
              </Space>
            </Col>
            <Col span={12} align="start">
              <Space direction="vertical" size="large">
                <Button htmlType="submit" onClick={handleRequestOk}>
                  Request
                </Button>
              </Space>
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
};

export default RequestBookModal;
