import React, { useEffect, useState } from "react";
import { useGetCulturalActivitiesQuery } from "../../RTKQuery/rtkCulturalActivicties";
import { useCreateCulturalActivitiesMutation } from "../../RTKQuery/rtkCulturalActivicties";
import { Button, Modal, Spin, notification } from "antd";
import "./Culture.scss";
import Cookies from "js-cookie";
import { DislikeOutlined, CheckCircleFilled } from "@ant-design/icons";
import EmptyComponent from "../EmptyComponent/EmptyComponent";
import { useSelector } from "react-redux";
function Culture() {
  const [culturalData, setCulturalData] = useState([]);
  const { data, isLoading, isError } = useGetCulturalActivitiesQuery();
  const [createCulturalActivities, { error: createCulturalError }] =
    useCreateCulturalActivitiesMutation();

  // const studentInfo = Cookies.get("studentInfo")
  //   ? JSON.parse(Cookies.get("studentInfo"))
  //   : [];

  // const studentId = studentInfo?.studentId;

  const studentInfo = useSelector(
    (state) => state.studentInfo.studentInfo
  );
  const studentId = studentInfo?.studentId;

  useEffect(() => {
    if (data) {
      setCulturalData(data?.data);
    }
  }, [data]);
  if (isLoading) {
    return (
      <div className="centered_spin">
        <Spin />
      </div>
    );
  }

  const showConfirm = (culturalId) => {
    Modal.confirm({
      title: "Register for Cultural Activity",
      content: "Are you sure you want to register for this cultural activity?",
      okText: "Save",
      okButtonProps: {
        className: "save-button",
      },
      cancelButtonProps: {
        className: "cancel-button",
        style: {
          backgroundColor: "red",
          color: "#fff",
        },
      },
      onOk() {
        const payload = {
          studentId: studentId,
          activityId: culturalId,
        };
        createCulturalActivities(payload).then((response) => {
          let errorMessage = "";
          if (response.error && response.error.status === 500) {
            errorMessage = response.error.data.message;
          }
          if (errorMessage) {
            errorMessage = createCulturalError.data.message;
          }
          if (errorMessage) {
            notification.error({
              description: errorMessage,
              placement: "bottomRight",
              icon: <DislikeOutlined style={{ color: "red" }} />,
            });
          } else {
            notification.success({
              message: "Registered Successfully",
              placement: "bottomRight",
              icon: (
                <CheckCircleFilled
                  style={{
                    color: "green",
                  }}
                />
              ),
            });
          }
        });
      },
      onCancel() {},
    });
  };

  if (isError) {
    return (
      <div className="ErrorContainer">
        <EmptyComponent />
      </div>
    );
  }
  // const showConfirm = (culturalId) => {
  //   Modal.confirm({
  //     title: "Register for Cultural Activity",
  //     content: "Are you sure you want to register for this cultural activity?",
  //     okText: "Save",

  //     onOk() {
  //       const payload = {
  //         studentId: studentId,
  //         activityId: culturalId,
  //       };
  //       createCulturalActivities(payload).then((response) => {
  //         let errorMessage = "";
  //         if (response.error && response.error.status === 500) {
  //           errorMessage = response.error.data.message;
  //         }
  //         if (createCulturalError) {
  //           errorMessage = createCulturalError.data.message;
  //         }
  //         if (errorMessage) {
  //           notification.error({
  //             description: errorMessage,
  //             placement: "bottomRight",
  //             icon: <DislikeOutlined style={{ color: "red" }} />,
  //           });
  //         } else {
  //           notification.success({
  //             message: "Resister Successfully",
  //             placement: "bottomRight",
  //             icon: (
  //               <CheckCircleFilled
  //                 style={{
  //                   color: "#108ee9",
  //                 }}
  //               />
  //             ),
  //           });
  //         }
  //       });
  //     },
  //     onCancel() {},
  //     cancelButtonProps: {
  //       className: "cancel_button",
  //     },
  //   });
  // };
  return (
    <section className="main-bg" id="culture">
      <div className="header">
        <h3>Cultural Activities</h3>
      </div>
      {culturalData.length == [] ? (
        <>
          <EmptyComponent />
        </>
      ) : (
        <div className="culture-grids">
          {culturalData &&
            culturalData.map((item, index) => {
              const {
                id,
                title,
                description,
                fromDate,
                toDate,
                location,
                duration,
                organizer,
                category,
                performers,
                contactInfo,
                status,
              } = item;

              // Define an array of background colors
              const backgroundColors = [
                "#ffcccb",
                "#b5e7a0",
                "#ffb347",
                "#c2c2f0",
              ]; // Add more colors if needed

              // Use the modulo operator to cycle through the background colors
              const backgroundColor =
                backgroundColors[index % backgroundColors.length];

              return (
                <article
                  key={id}
                  className="culture-grid-item div-sty main-cultural-container"
                  style={{ backgroundColor }}
                >
                  <div className="culture-item">
                    <div className="culture-text">
                      <span className=" title">
                        <strong>{title}</strong>
                      </span>
                      <span className="desctription">{description}</span>
                      <span className="desctription">{status}</span>
                      <span className="desctription">{category}</span>
                      <span className="desctription">{organizer}</span>
                    </div>
                    <Button
                      onClick={() => showConfirm(id)}
                      className="register-btn"
                    >
                      Register
                    </Button>
                  </div>
                </article>
              );
            })}
        </div>
      )}
    </section>
  );
}

export default Culture;
