import React from "react";
import "./Profile.scss";
import ProfileRatings from "./ProfileRatings";
import ProfileSettings from "./ProfileSettings";
import { useSelector } from "react-redux";
import { selectMenu } from "../../features/toggleSlice";
import { useState } from "react";

const Profile = () => {
  const [select, setSelect] = useState(false);

  return (
    <React.Fragment>
      {select ? (
        <ProfileRatings select={select} setSelect={setSelect} />
      ) : (
        <ProfileSettings select={select} setSelect={setSelect} />
      )}
    </React.Fragment>
  );
};

export default Profile;
