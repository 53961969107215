import React, { useState, useEffect } from "react";
import HolidayCard from "../HolidayCard/HolidayCard";
import "../Holiday.scss";

import { Calendar, Tooltip } from "antd";
import moment from "moment";
import dayjs from "dayjs";

const HolidayList = ({ holidays }) => {
  const [upcomingHolidays, setUpcomingHolidays] = useState([]);

  useEffect(() => {
    //only future dates will shows
    const futureHolidays = holidays.filter((holiday) => {
      const holidayDate = new Date(holiday.holidayDate);
      return holidayDate >= new Date();
    });

    //date wisely
    futureHolidays.sort((a, b) => {
      const dateA = new Date(a.holidayDate);
      const dateB = new Date(b.holidayDate);
      return dateA - dateB;
    });

    setUpcomingHolidays(futureHolidays);
  }, [holidays]);
  const getMonthData = (value) => {
    if (value.month() === 8) {
      return 1394;
    }
  };
  const monthCellRender = (value) => {
    const num = getMonthData(value);
    return num ? <div className="notes-month"></div> : null;
  };

  const dateCellRender = (date) => {
    const holiday = holidays.find((holiday) =>
      holiday.holidayDate.includes(date.format("YYYY-MM-DD"))
    );
    if (holiday) {
      return (
        <Tooltip
          title={
            <div className="toolTip">
              <p>Holiday Name:&nbsp;{holiday.holidayName}</p>
              <p>Observance:&nbsp;{holiday.observance}</p>
              <p>Description:&nbsp;{holiday.description}</p>
              <p>Holiday Type:&nbsp;{holiday.holidayType}</p>
            </div>
          }
        >
          <div className="calender-texts">
            <span>{holiday.holidayName}</span>
            <span className="type">{holiday.holidayType}</span>
          </div>
        </Tooltip>
      );
    }
  };
  return (
    <div>
      {/* <div>
        <p className="holidayNumber">
          Number of Up Coming Holidays: {upcomingHolidays.length}
        </p>
        <div className="gridcards">
          {upcomingHolidays.map((holiday) => (
            <HolidayCard key={holiday.holidayId} holiday={holiday} />
          ))}
        </div>
      </div> */}

      <Calendar
        style={{ color: "white" }}
        dateCellRender={dateCellRender}
        monthCellRender={monthCellRender}
        defaultValue={dayjs()}
        className="custom-calendar"
      />
    </div>
  );
};

export default HolidayList;
