import { Button, Radio } from "antd";
import { useState } from "react";
import RentBooks from "./MyRentBooks/RentBooks";
import RequestedBooks from "./RequestedBooks/RequestedBooks";
import PendingBooks from "./PendingBooks/PendingBooks";
import NotReturn from "./Returned/Returned";
import { LeftOutlined } from "@ant-design/icons";
import MissingBooks from "./MissingBooks/MissingBooks";
import SuggestedBooks from "./SuggestedBooks/SuggestedBooks";

const MyAccount = ({ setActiveTabKey }) => {
  const [selected, setSelected] = useState("rentBooks");
  const RadioGroup = Radio.Group;

  const handleTabChange = (e) => {
    setSelected(e.target.value);
  };

  const onHandleBack = () => {
    setActiveTabKey("home");
  };

  return (
    <>
      <div
        className="align-buttons mainContainer"
        style={{ backgroundColor: "f0f0f0" }}
      >
        <Button
          className="goback_button"
          type="primary"
          htmlType="submit"
          onClick={onHandleBack}
        >
          <LeftOutlined />
        </Button>
        <RadioGroup
          onChange={handleTabChange}
          value={selected}
          optionType="button"
          buttonStyle="solid"
          style={{ marginLeft: "10px" }}
        >
          <Radio.Button value="rentBooks">MyRent Books</Radio.Button>
          <Radio.Button value="requestedBooks">Requested </Radio.Button>
          <Radio.Button value="returned">Returned</Radio.Button>
          <Radio.Button value="pendingBooks">Pending</Radio.Button>
          <Radio.Button value="missingBooks">Missing</Radio.Button>
          <Radio.Button value="suggestedBooks">Suggested</Radio.Button>
        </RadioGroup>
      </div>
      {selected === "rentBooks" && <RentBooks />}
      {selected === "requestedBooks" && <RequestedBooks />}
      {selected === "returned" && <NotReturn />}
      {selected === "pendingBooks" && <PendingBooks />}
      {selected === "missingBooks" && <MissingBooks />}
      {selected === "suggestedBooks" && <SuggestedBooks />}
    </>
  );
};
export default MyAccount;
