import React, { useEffect, useState } from "react";
import "./FeeDetail.scss";
import { Radio, Spin, Table, Tooltip } from "antd";
import { useGetStudentFeePaymentsQuery } from "../../RTKQuery/Notification";
import Cookies from "js-cookie";
import {
  EditOutlined,
  DeleteOutlined,
  DislikeOutlined,
  CheckCircleFilled,
  FileOutlined,
  CheckOutlined,
  CloseCircleOutlined,
  CloseCircleFilled,
} from "@ant-design/icons";
import { useSelector } from "react-redux";

const FeeDetail = () => {
  // const studentInfo = Cookies.get("studentInfo")
  //   ? JSON.parse(Cookies.get("studentInfo"))
  //   : [];

  const studentInfo = useSelector((state) => state.studentInfo.studentInfo);
  const studentId = studentInfo?.studentId;
  const { data, isLoading, isError } = useGetStudentFeePaymentsQuery(studentId);
  const [studentFeeDetails, setStudentFeeDetails] = useState([]);
  const [selectedRadio, setSelectedRadio] = useState("");
  const studentPaidAmount = data?.data?.[0]?.studentPaidAmount;
  const studentRemainingAmount = data?.data?.[0]?.studentRemainingAmount;
  const studentTotalAmount = data?.data?.[0]?.studentTotalAmount;
  console.log("studentTotalAmount", studentTotalAmount);
  const transactionTableResponse = data?.data?.[0]?.feeComponents;

  console.log("studentFeeDetails", studentFeeDetails);
  useEffect(() => {
    if (data) {
      setStudentFeeDetails(data?.data);
      setSelectedRadio(data.data[0]?.id);
    }
  }, [data]);
  if (isLoading) {
    return (
      <div className="centered_spin">
        <Spin />
      </div>
    );
  }

  if (isError) {
    return <div>Error occurred while fetching data.</div>;
  }
  const feeColumns = [
    {
      title: "Component",
      dataIndex: "componentName",
      key: "componentName",
      align: "center",
      sorter: (a, b) => a.componentName.localeCompare(b.componentName),
    },
    {
      title: "Total Amount",
      dataIndex: "actualAmount",
      key: "actualAmount",
      align: "center",
      sorter: (a, b) => a.actualAmount - b.actualAmount,
    },
    {
      title: "Term Amount",
      dataIndex: "finalAmount",
      key: "finalAmount",
      align: "center",
      sorter: (a, b) => a.finalAmount - b.finalAmount,
      render: (finalAmount, record) =>
        record.actualAmount === 0 ? "-" : finalAmount,
    },
    {
      title: "Paid Amount",
      dataIndex: "paidAmount",
      key: "paidAmount",
      align: "center",
      sorter: (a, b) => a.paidAmount - b.paidAmount,
      render: (paidAmount, record) =>
        record.actualAmount === 0 ? "-" : paidAmount?.toFixed(0),
    },
    {
      title: "Remaining Amount",
      dataIndex: "remainingAmount",
      key: "remainingAmount",
      align: "center",
      sorter: (a, b) => a.remaningAmount - b.remaningAmount,
      render: (remainingAmount, record) =>
        record.actualAmount === 0
          ? "-"
          : (record.finalAmount - record.paidAmount)?.toFixed(0),
    },
    {
      title: "Payment Status",
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (status, record) => (record.actualAmount === 0 ? "-" : status),
    },
    // {
    //   title: "Payment Status",
    //   dataIndex: "paymentStatus",
    //   key: "paymentStatus",
    //   align: "center",
    //   render: (text, record) => {
    //     console.log("payment", text, record);

    //     // Assuming some condition to determine payment status
    //     const isPaid = record.paidAmount > 0;
    //     const paymentStatus = isPaid ? 'Paid' : 'Unpaid';

    //     if (paymentStatus === 'Paid') {
    //       return <span style={{ color: 'green' }}>{paymentStatus}</span>;
    //     } else if (paymentStatus === 'Unpaid') {
    //       return <span style={{ color: 'red' }}>{paymentStatus}</span>;
    //     } else {
    //       return <span>{paymentStatus}</span>;
    //     }
    //   },
    // },

    // {
    //   title: "Action",
    //   dataIndex: "action",
    //   key: "action",
    //   align: "center",
    //   render: (_, record) => (
    //     <span
    //       style={{
    //         display: "flex",
    //         flexDirection: "row",
    //         justifyContent: "center",
    //       }}
    //     >
    //       <EditOutlined
    //         style={{ marginRight: 8 }}
    //         onClick={() => editStudentFee(record)}
    //       />
    //       <DeleteOutlined />
    //     </span>
    //   ),
    // },
  ];

  const handleChange = (e) => {
    setSelectedRadio(e.target.value);
  };
  const findStudentFeeTermDetails = studentFeeDetails.filter(
    (item) => item.id === selectedRadio
  );

  console.log(
    "findStudentFeeTermDetailsfindStudentFeeTermDetails",
    findStudentFeeTermDetails
  );
  return (
    <>
      {/* <div className="feature-grids grid-sty gt3">
        <div className="feature-grid-item text-ct div-sty card">
          <h2>Total Fee</h2>
          <h3>{studentTotalAmount == 0.0 ? 0 : studentTotalAmount}</h3>
        </div>
        <div className="feature-grid-item text-ct div-sty card">
          <h2>Paid Fee</h2>
          <h3>{studentPaidAmount == 0.0 ? 0 : studentPaidAmount}</h3>
        </div>
        <div className="feature-grid-item text-ct div-sty card">
          <h2>Pending Fee</h2>
          <h3>{studentRemainingAmount == 0.0 ? 0 : studentRemainingAmount}</h3>
        </div>
      </div> */}

      <header className="fee-header">
        <h1>Fee Details</h1>
        <div>
          <Radio.Group
            defaultValue="studentPersonalDetails"
            buttonStyle="solid"
            onChange={handleChange}
            value={selectedRadio}
          >
            <div className="tabtop">
              {studentFeeDetails?.map((item) => {
                const statusClass =
                  selectedRadio === item.id
                    ? "active-tab"
                    : item.status === "PAID"
                    ? "paid-status"
                    : item.status === "PARTIALLY_PAID"
                    ? "partially-paid-status"
                    : "unpaid-status";

                return (
                  <Radio.Button
                    key={item.id}
                    value={item.id}
                    // className={statusClass}
                    style={{ position: "relative" }}
                  >
                    Term {item.term}
                    {item.status === "PAID" ? (
                      <Tooltip title="Term Fee Paid">
                        <CheckCircleFilled
                          style={{
                            color: "green",
                            position: "absolute",
                            top: 13,
                            right: 7,
                          }}
                        />
                      </Tooltip>
                    ) : (
                      <CloseCircleFilled
                        style={{
                          color: "red",
                          position: "absolute",
                          top: 13,
                          right: 7,
                        }}
                      />
                    )}
                  </Radio.Button>
                );
              })}
            </div>
          </Radio.Group>
        </div>
      </header>
      <div className="total_fees">
        <div className="back_container bg1">
          <p>Total Fee</p>
          <h1>
            {" "}
            {findStudentFeeTermDetails[0]?.totalAmount?.toFixed(2) || "NA"}
          </h1>
        </div>
        <div className="back_container bg2">
          <p>Paid Fee</p>
          <h1>
            {findStudentFeeTermDetails[0]?.paidAmount?.toFixed(2) || "NA"}
          </h1>
        </div>
        <div className="back_container bg3">
          <p>Due Amount </p>
          <h1>
            {findStudentFeeTermDetails[0]?.reamingAmount?.toFixed(2) || "NA"}
          </h1>
        </div>
      </div>

      <Table
        columns={feeColumns}
        dataSource={findStudentFeeTermDetails[0]?.feeComponents}
        pagination={false}
        showSorterTooltip={false}
      />
    </>
  );
};
export default FeeDetail;
