import { configureStore } from "@reduxjs/toolkit";
import toggleReducer from "../features/toggleSlice";
import { combineReducers } from "@reduxjs/toolkit";
import { getLatestActivityListApi } from "../RTKQuery/rtkQuery";
import { getLatestActivityListApi2 } from "../RTKQuery/rtkQuery";
import { getLatestActivityListApi3 } from "../RTKQuery/rtkQuery";
import { edpediaStoreStudent } from "../RTKQuery/rtkEdpediaStore";
import { libraryServiceStudent } from "../RTKQuery/rtkLibrary";
import { instituteDetails } from "../RTKQuery/instituteDetais";
import { culturalActivicties } from "../RTKQuery/rtkCulturalActivicties";
import { notificationService } from "../RTKQuery/Notification";
import { attendanceService } from "../RTKQuery/Attendance";
import StudentSlice from "../Store/StudentSlice";
import StudentInfoSlice from "../Store/StudentInfoSlice";
const store = configureStore({
  reducer: {
    student: StudentSlice,
    studentInfo: StudentInfoSlice,
    [getLatestActivityListApi.reducerPath]: getLatestActivityListApi.reducer,
    [getLatestActivityListApi2.reducerPath]: getLatestActivityListApi2.reducer,
    [getLatestActivityListApi3.reducerPath]: getLatestActivityListApi3.reducer,
    [libraryServiceStudent.reducerPath]: libraryServiceStudent.reducer,
    [instituteDetails.reducerPath]: instituteDetails.reducer,
    [culturalActivicties.reducerPath]: culturalActivicties.reducer,
    [notificationService.reducerPath]: notificationService.reducer,
    [edpediaStoreStudent.reducerPath]: edpediaStoreStudent.reducer,
    [attendanceService.reducerPath]: attendanceService.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      getLatestActivityListApi.middleware,
      getLatestActivityListApi2.middleware,
      getLatestActivityListApi3.middleware,
      libraryServiceStudent.middleware,
      attendanceService.middleware,
      culturalActivicties.middleware,
      notificationService.middleware,
      edpediaStoreStudent.middleware
    ),
});

export default store;
