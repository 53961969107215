import React, { useEffect, useState } from "react";
import { Vijay } from "../../assets/images";
import { blackarrow, greyarrow } from "../../assets/icons";
import { BsArrowRight } from "react-icons/bs";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import "./Profile.scss";

import Cookies from "js-cookie";
import {
  useGetStudentInformationQuery,
  useGetResultQuery,
} from "../../RTKQuery/rtkQuery";
import { useGetMonthWiseAttendanceQuery } from "../../RTKQuery/Attendance";
function ProfileSettings({ select, setSelect }) {
  const [studentData, setStudentData] = useState([]);
  const [monthWiseAttndance, setMonthWiseAttendanceData] = useState([]);
  const [currentMonth, setCurrentMonth] = useState("");
  const studentId = Cookies.get("userId");
  const { data: getMonthWiseAttendance } =
    useGetMonthWiseAttendanceQuery(studentId);
  const { data } = useGetStudentInformationQuery(studentId);

  const { data: results, isLoading, isError } = useGetResultQuery(studentId);

  const resultsData = results?.data?.examResults?.[0];

  useEffect(() => {
    const currentDate = new Date();
    const options = { month: "long" };
    const currentMonthName = currentDate.toLocaleDateString(undefined, options);
    setCurrentMonth(currentMonthName);
  }, []);

  useEffect(() => {
    if (data) {
      setStudentData(data?.data);
      Cookies.set("studentInfo", JSON.stringify(data?.data));
    }
  }, [data]);
  useEffect(() => {
    if (getMonthWiseAttendance) {
      setMonthWiseAttendanceData(getMonthWiseAttendance?.data);
    }
  }, [getMonthWiseAttendance]);

  const percentage = 25;

  const half = 50;

  const onHandleClick = () => {
    setSelect(!select);
  };

  return (
    <div className="profile sidebar-scroll-container rightBar">
      <div style={{ marginTop: 5 }}>
        <BsArrowRight onClick={onHandleClick} />
        <div className="dashboard-profile-content">
          <div className="dashboard-profile-pic">
            <img src={Vijay} alt="vijay" />
          </div>
          <div className="student-data">
            <p> {studentData?.studentName}</p>
            <span>Roll No. - {studentData?.rollNo}</span>
            {/* <span>Class - {studentData?.className}</span>
            <span>Section -{studentData?.section}</span> */}
          </div>
          <hr />
        </div>
        <div className="student_details">
          <div>
            <span className="student-data-title">Gender : </span>
            <span className="student-texts">{studentData?.gender}</span>
          </div>
          <div>
            {" "}
            <span className="student-data-title"> Class :</span>
            <span className="student-texts">{studentData?.className}</span>
          </div>
          <div>
            <span className="student-data-title"> Section : </span>
            <span className="student-texts">{studentData?.section}</span>
          </div>
          <div>
            {" "}
            <span className="student-data-title"> DOB :</span>
            <span className="student-texts">{studentData?.dateOfBirth}</span>
          </div>
          <div>
            {" "}
            <span className="student-data-title"> Contact :</span>
            <span className="student-texts">{studentData?.contact}</span>
          </div>

          <div>
            <span className="student-data-title">Email : </span>
            <span className="student-texts">{studentData.email}</span>
          </div>
          <div>
            <span className="student-data-title"> Address : </span>
            <span className="student-texts">{studentData?.address}</span>
          </div>
          <div>
            <span className="student-data-title"> Join : </span>
            <span className="student-texts">{studentData?.joiningDate}</span>
          </div>
        </div>

        {/* <article className="dashboard-attendance">
          <div className="dashboard-month">
            <span>Attendance</span> :<b>{currentMonth}</b>
          </div>
          <div className="attendance-item">
            <span className="attendance-span">Working Days</span>
            <span className="sp-sty">
              {monthWiseAttndance?.schoolWorkingDays}
            </span>
          </div>
          <div className="attendance-item ">
            <span className="attendance-span">Total Classes</span>
            <span className="sp-sty">{monthWiseAttndance?.totalClasses}</span>
          </div>
          <div className="attendance-item">
            <span className="attendance-span">Present Classes</span>
            <span className="sp-sty">
              {monthWiseAttndance?.presentedClasses}
            </span>
          </div>
          <div className="attendance-item">
            <span
              style={{
                marginRight: "10px",
                background: "#FF6969",
                color: "#ffffff",
              }}
              className="attendance-span"
            >
              Absent Classes
            </span>
            <span
              style={{
                color: "#ffffff",
                textAlign: "center",
                background: "#FF6969",
                flex: 0.2,
              }}
            >
              {monthWiseAttndance?.absentClasses}
            </span>
          </div>
        </article> */}

        <div className="dashboard-exam">
          <h5>Recent Exam Results</h5>
          <div className="flex">
            <span className="student-data-title">Exam Name : </span>
            <span className="student-texts">{resultsData?.examName}</span>
          </div>
          <div className="flex">
            {" "}
            <span className="student-data-title"> Total Mark :</span>
            <span className="student-texts">{resultsData?.examMarks}</span>
          </div>
          <div className="flex">
            <span className="student-data-title">Secured Marks : </span>
            <span className="student-texts">{resultsData?.yourMarks}</span>
          </div>
          <div className="flex">
            {" "}
            <span className="student-data-title"> Grade :</span>
            <span className="student-texts">{resultsData?.Grade}</span>
          </div>

          {/* {resultsData?.subjectResults?.map((item) => {
            return (
              <div className="dashboard-exam-item">
                <div
                  className="dashboard-exam-item flex-sty"
                  style={{ background: "#786F6F" }}
                >
                  <div style={{ width: "40px" }}>
                    <img src={item.subjectLogo} alt="subject-logo" />
                  </div>
                  <div className="dashboard-exam-desc">
                    <div>
                      <div style={{ margin: "0 30px 0 6px" }}>
                        <h4 style={{ color: "white" }}>{item.examDate}</h4>
                        <small style={{ fontSize: "10px", color: "white" }}>
                          {item.startTime} - {item.endTime}
                        </small>
                      </div>

                      <img
                        style={{ width: "10px" }}
                        className="dashboard-exam-desc-img"
                        src={greyarrow}
                        alt="grey-arrow"
                        onClick={handleClick}
                      />
                    </div>
                  </div>
                </div>
              </div>
            );
          })} */}
        </div>
      </div>
    </div>
  );
}

export default ProfileSettings;
