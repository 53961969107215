import React from "react";
import "../Transports.scss";
import { Card } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";
import schoolImage from "../../../assets/images/school_img.jpg";
const TransportDetails = ({ transportDetailsData }) => {
 
  return (
    <>
      <div className="pickuppointsCard">
        {transportDetailsData === null ? (
          <>
            <div class="scrolling-text">
              <h2>Please Select Route</h2>
            </div>
          </>
        ) : (
          <>
             <h1 className="headings">Route Details</h1>
          </>
        )}
        <div style={{ display: "flex", flexDirection: "colum" }}>
       
          {transportDetailsData?.pickupPoints?.map((item, index) => (
            <>
            
              <div
                // span={4}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Card className="transport-card">
                  <p className="name"> {item?.pickupName}</p>
                  <p>{item?.address}</p>
                  <p className="time">{item?.pickupTime}</p>
                </Card>
                {index !== transportDetailsData?.pickupPoints?.length - 0 && (
                  <>
                    <div className="horizontal-line"></div>
                    <CaretRightOutlined
                      style={{
                       
                        fontWeight: "bold",
                        marginTop: 10,
                        marginLeft: -8,
                      }}
                    />
                  </>
                )}
              </div>
            </>
          ))}
          {transportDetailsData?.pickupPoints?.length > 0 && (
            <img src={schoolImage} alt="school" className="school-imgage" />
          )}
        </div>
        {transportDetailsData === null ? (
          <></>
        ) : (
          <>
            <h1 className="headings">Driver Details</h1>
          </>
        )}

        <div className="driverDeatils">
          {transportDetailsData?.vehicles?.map((item) => {
            return (
              <>
                <div className="driverDetailsCard">
                  <p className="dr_name">Driver Name : {item.driverName}</p>
                  <p>Mobile No : {item.driverMobileNu}</p>
                  <p>Helper Name : {item.helperName}</p>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default TransportDetails;
