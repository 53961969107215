import React, { useEffect, useState } from "react";
import "./Exam.css";
import { three } from "../../assets/images";
import { Tabs, Card, Row, Col, Empty } from "antd";
import DataUpComingExams from "./DataUpComingExams";
import EmptyComponent from "../EmptyComponent/EmptyComponent";

const { TabPane } = Tabs;

function Present({ exams }) {
  const [examData, setExamData] = useState([]);
  const prasentExamNames = Object.keys(exams);
  const prasentExamLength = prasentExamNames?.length;
  const [activeTab, setActiveTab] = useState(prasentExamNames[0]);

  const handleTabChange = (key) => {
    setExamData([]);
    setActiveTab(key);
  };

  useEffect(() => {
    setExamData(exams[activeTab]);
  }, [activeTab]);

  if (prasentExamLength === 0) {
    return <Empty description="No Exams available" />;
  }
  console.log("prasentExamNames", prasentExamNames);

  return (
    <div>
      <>
        {prasentExamLength > 0 ? (
          <Tabs
            tabPosition="left"
            activeKey={activeTab}
            onChange={handleTabChange}
            // Add this line to apply custom CSS class to the active tab
            className="custom-tabs"
          >
            {prasentExamNames?.map((item) => (
              <TabPane tab={item} key={item}>
                <Row
                  gutter={[16, 16]}
                  style={{ margin: 0, width: "100%", height: "100%" }}
                >
                  {exams[activeTab]?.map((item) => (
                    <Col key={item.examId} xs={24} sm={12} md={12} lg={12}>
                      <>
                        <div className="present-grid-item upcoming-grid div-sty crad-container">
                          <p>Date: {item.examDate}</p>
                          <div className="present-single-item flex-sty">
                            <div className="present-img">
                              <img src={item.logoImg} alt="three" />
                            </div>
                            <div className="present-desc">
                              <h3>{item.subject}</h3>
                              <p>
                                Time: {item.startTime} - {item.endTime}
                              </p>
                            </div>
                          </div>
                        </div>
                      </>
                    </Col>
                  ))}
                </Row>
              </TabPane>
            ))}
          </Tabs>
        ) : (
          <div className="h1Container">
            <EmptyComponent />
          </div>
        )}
      </>
    </div>
  );
}

export default Present;
