import bookImage from "../../../../assets/images/book image.jpg";
import "../MyAccount.scss";
import { useGetRentedBooksQuery } from "../../../../RTKQuery/rtkLibrary";
import Cookies from "js-cookie";
import { Empty, Spin } from "antd";
import EmptyComponent from "../../../EmptyComponent/EmptyComponent";
import { useSelector } from "react-redux";
const ReturnBooks = () => {
  // const studentInfo = Cookies.get("studentInfo")
  //   ? JSON.parse(Cookies.get("studentInfo"))
  //   : [];
  const studentInfo = useSelector((state) => state.studentInfo.studentInfo);
  const studentId = studentInfo?.rollNo;
  const userId = studentId;
  const { data, isLoading, isError } = useGetRentedBooksQuery({
    userId: userId,
    userType: "Student",
  });

  const getReturnBooks = data?.data?.returned;

  if (isLoading) {
    return (
      <div className="centered_spin">
        <Spin />
      </div>
    );
  }

  // if (isError) {
  //   return (
  //     <div className="ErrorContainer">
  //       <EmptyComponent />
  //     </div>
  //   );
  // }
  return (
    <div className="mainContainer">
      {getReturnBooks && getReturnBooks.length == 0 ? (
        <div
          style={{
            paddingTop: "70px",
            paddingBottom: "40px",
            backgroundColor: "#f0f0f0",
          }}
        >
          <EmptyComponent />
        </div>
      ) : (
        <div className="common-conmtainers">
          {getReturnBooks?.map((returnedBook, index) => {
            const { book } = returnedBook;

            return (
              <div key={returnedBook.rentalId} className="rent-card">
                <div className="image">
                  <img src={book.image} alt="CardImage" />
                </div>
                <div className="right-side">
                  <p>{book?.bookName}</p>
                  <span>ISBN: {book?.isbn}</span>
                  <span>{book?.author}</span>
                  <span>Price: {book?.bookRate} /-</span>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
export default ReturnBooks;
