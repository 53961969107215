import React from "react";
import "./Subject.css";
import { items } from "./data";
import techerImg from "../../assets/teacher/Lakshman.svg";
import Rating from "./Rating";
import subjectImg from "../../assets/images/subject-1.jpg";
import { GoPrimitiveDot } from "react-icons/go";
import { Card, Empty, Spin } from "antd";
import { useGetTeachersSubjectQuery } from "../../RTKQuery/rtkQuery";
import Cookies from "js-cookie";
import EmptyComponent from "../EmptyComponent/EmptyComponent";
import { useSelector } from "react-redux";

const Subject = () => {
  // const studentInfo = Cookies.get("studentInfo")
  //   ? JSON.parse(Cookies.get("studentInfo"))
  //   : [];

  const studentInfo = useSelector((state) => state.studentInfo.studentInfo);
  const studentId = studentInfo?.studentId;

  const { data, isLoading, isError } = useGetTeachersSubjectQuery(studentId);

  const teachersSubject = data?.data;

  console.log("teachersSubject", teachersSubject);

  if (isLoading) {
    return (
      <div className="centered_spin">
        <Spin />
      </div>
    );
  }
  if (isError) {
    return (
      <div className="ErrorContainer">
        <EmptyComponent />
      </div>
    );
  }

  if (teachersSubject.length === 0) {
    return <Empty description="No subjects available" />;
  }

  return (
    <section className="main-bg" id="culture">
      <div className="sidebar-scroll-container">
        <h3>Subjects and Teachers</h3>
        <div className="subjects-grid grid-sty gt2 ">
          {teachersSubject.map((item) => {
            const {
              subjectId,
              logoImg,
              teacherName,
              review,
              subjectName,
              experience,
              isGreen,
              subjectType,
            } = item;
            return (
              <article key={subjectId} className="subjects-grid-item">
                <div className="subjects-img">
                  <img src={logoImg || subjectImg} alt={teacherName} />
                  {/* <Rating rating={review} /> */}
                </div>

                <div className="subject-textContent">
                  <p className="teacherName">Teacher : {teacherName}</p>
                  <span>Subjects : {subjectName}</span>
                  <span>Experience : {experience}</span>
                  <span>Subject Type : {subjectType}</span>
                  {/* <GoPrimitiveDot
                    className={isGreen ? "subject-dot green" : "subject-dot"}
                  /> */}
                </div>
              </article>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Subject;
