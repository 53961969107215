import React from "react";
import { Schoolname,Holiday } from "../../components";

const Holidays = () => {
  return (
    <main>
      <Schoolname />
      <Holiday />
    </main>
  );
};

export default Holidays;
