import React from "react";
import { Schoolname, Result } from "../../components";

function Results() {
  return (
    <main>
      <Schoolname />
      <Result />
    </main>
  );
}

export default Results;
