import React, { useEffect, useState } from "react";
import "./Result.css";
import { FiSearch } from "react-icons/fi";
import items from "./data";
import { Tabs, Button, Spin, Empty } from "antd";
import { useGetResultQuery } from "../../RTKQuery/rtkQuery";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";
const { TabPane } = Tabs;
function Result() {
  const [filteredData, setFilteredData] = useState([]);

  // const studentInfo = Cookies.get("studentInfo")
  //   ? JSON.parse(Cookies.get("studentInfo"))
  //   : [];
  const studentInfo = useSelector((state) => state.studentInfo.studentInfo);
  const studentId = studentInfo?.studentId;
  const { data, isLoading, isError } = useGetResultQuery(studentId);
  const resultsData = data?.data?.examResults;
  const examNames = resultsData?.map((result) => result.examName);
  const defaultExamNames = examNames?.[0];
  const [selectedExam, setSelectedExam] = useState(null);

  useEffect(() => {
    const selectedResult = resultsData?.filter(
      (item) => item.examName === selectedExam
    )?.[0];
    setFilteredData(selectedResult || resultsData?.[0]);
  }, [selectedExam, resultsData]);

  const handleTabChange = (key) => {
    if (key) {
      setSelectedExam(key);
    } else {
      setSelectedExam(examNames[0]);
    }
  };

  useEffect(() => {
    if (selectedExam !== null) {
      setSelectedExam(selectedExam);
    } else {
      setSelectedExam(examNames?.[0]);
    }
  }, [selectedExam]);

  if (isLoading) {
    return (
      <div>
        <Spin />
      </div>
    );
  }

  if (isError) {
    return (
      <div>
        <Empty />
      </div>
    );
  }

  return (
    <section id="culture" className="main-bg">
      <div className="results-widget flex-sty">
        <div className="results-title">
          <h3>Results </h3>
        </div>
      </div>

      <div className="results-flex">
        <div className="results-flex-btns flexDir-sty">
          <Tabs
            tabPosition="left"
            style={{ marginLeft: 0 }}
            defaultActiveKey={examNames?.[0]}
            onChange={handleTabChange}
          >
            {examNames?.map((tab, id) => (
              <TabPane
                tab={
                  <Button
                    // className="tab_Button"
                    style={{
                      width: 150,
                      height: 40,
                      padding: 10,
                      color: "#fff",
                      background:
                        (selectedExam === null && id === 0) ||
                        tab === selectedExam
                          ? "#172b4d"
                          : "Grey",
                    }}
                  >
                    {tab}
                  </Button>
                }
                key={id}
              >
                <div>
                  <div className="results-content">
                    <div className="div-sty result-content grid-sty gt3">
                      <div className="result-content-item flexDir-sty flex-sty text-ct">
                        <div
                          className="result-circle white-text flexBox-sty"
                          id="bg-bblue"
                        >
                          {filteredData?.examMarks}
                        </div>
                        <h3>Exam Marks</h3>
                      </div>

                      <div className="result-content-item flexDir-sty flex-sty text-ct">
                        <div
                          className="result-circle white-text flexBox-sty"
                          id="bg-gold"
                        >
                          {filteredData?.yourMarks}
                        </div>
                        <h3>Your Marks</h3>
                      </div>

                      <div className="result-content-item flexDir-sty flex-sty text-ct">
                        <div
                          className="result-circle white-font flexBox-sty"
                          id="bg-purple"
                        >
                          {filteredData?.Grade}
                        </div>
                        <h3>Grade</h3>
                      </div>
                    </div>

                    {/* result gris */}

                    <div className="results-grid grid-sty gt3" id="grid1">
                      {filteredData?.subjectResults?.map((item, i) => {
                        return (
                          <div className="result-item div-sty">
                            <div className="result-timer">
                              <div className="result-timerText">
                                <h5>{item.examDate}</h5>
                                <small>
                                  {item.startTime} - {item.endTime}
                                </small>
                              </div>
                              <div className="result-timerImg">
                                <img src={item.subjectLogo} alt="three" />
                              </div>
                            </div>
                            <div
                              className="result-content grid-sty gt3"
                              style={{
                                gap: "5px",
                              }}
                            >
                              <div className="result-content-item flexDir-sty flex-sty text-ct">
                                <div
                                  className="result-circle item-circle white-text flexBox-sty"
                                  id="bg-bblue"
                                  style={{
                                    fontSize: "14px",
                                  }}
                                >
                                  {item.subMark}
                                </div>
                                <small style={{ fontSize: "10px" }}>
                                  Exam Marks
                                </small>
                              </div>
                              {/*  */}
                              <div className="result-content-item flexDir-sty flex-sty text-ct">
                                <div
                                  className="result-circle item-circle flexBox-sty"
                                  style={{
                                    background: "#889C37",
                                    color: "white",
                                    fontSize: "14px",
                                  }}
                                >
                                  {item.subSecMark}
                                </div>
                                <small style={{ fontSize: "10px" }}>
                                  Your Marks
                                </small>
                              </div>
                              {/*  */}
                              <div className="result-content-item flexDir-sty flex-sty text-ct">
                                <div
                                  className="result-circle item-circle flexBox-sty"
                                  style={{
                                    background: "#5A1B49",
                                    color: "#FFFFFF",
                                    fontSize: "14px",
                                  }}
                                >
                                  {item.subPassStatus}
                                </div>
                                <small style={{ fontSize: "10px" }}>
                                  Result
                                </small>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </TabPane>
            ))}
          </Tabs>
        </div>
      </div>
    </section>
  );
}

export default Result;
