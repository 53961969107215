import React from "react";
import { Schoolname, Timetable } from "../../components";

const Timetables = () => {
  return (
    <main>
      <Schoolname />
      <Timetable />
    </main>
  );
};

export default Timetables;
