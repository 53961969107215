import {
  Button,
  Col,
  Empty,
  Modal,
  Row,
  Select,
  Space,
  Spin,
  notification,
} from "antd";
import "./Books.scss";
import { DislikeOutlined, LeftOutlined } from "@ant-design/icons";
import {
  useGetAllBooksQuery,
  useGetCategoriesQuery,
  useCategoryBooksQuery,
  useGetRentalInformationByBookQuery,
  usePostRentalBookMutation,
  usePostRequestBookMutation,
  useGetRentedBooksQuery,
} from "../../../RTKQuery/rtkLibrary";
import { useEffect, useState } from "react";
// import BookView from "../BookView/BookView";
import Cookies from "js-cookie";
import EmptyComponent from "../../EmptyComponent/EmptyComponent";
import RentBookModal from "./RentBookModal";
import RequestBookModal from "./RequestBookModal";
import { useSelector } from "react-redux";
import { skipToken } from "@reduxjs/toolkit/dist/query";
const { Option } = Select;
const Books = ({ setActiveTabKey }) => {
  const [categoryName, setCategoryName] = useState(null);
  const [book, setBook] = useState(null);
  const [selectedBook, setSelectedBook] = useState(null);
  const { data: getAllBooks, isLoading, isError } = useGetAllBooksQuery();
  const { data: booksCategory } = useGetCategoriesQuery();
  const { data: categoryBooks } = useCategoryBooksQuery(categoryName);
  const [isRentModalVisible, setRentModalVisible] = useState(false);
  const [isRequestModalVisible, setRequestModalVisible] = useState(false);
  const [booksList, setBooksList] = useState(null);
  const [totalBook, setTotalBook] = useState(null);
  const getAllBooksCategorys = booksCategory?.data?.category;
  const [categoryList, setCategoryList] = useState([]);
  // const studentInfo = Cookies.get("studentInfo")
  //   ? JSON.parse(Cookies.get("studentInfo"))
  //   : [];
  const studentInfo = useSelector((state) => state.studentInfo.studentInfo);
  const studentId = studentInfo?.rollNo;
  // const userId = studentId;
  const rollNo = studentInfo?.rollNo;
  const { data } = useGetRentalInformationByBookQuery(selectedBook?.id);
  // const rentedBookInformation = data?.data;
  const { data: rentedBooks } = useGetRentedBooksQuery({
    userId: rollNo,
    userType: "Student",
  });

  const GetRentedBooks = rentedBooks?.data?.rented;
  useEffect(() => {
    setCategoryName(null);
  }, []);

  useEffect(() => {
    if (categoryBooks) {
      setCategoryList(categoryBooks?.data);
    }
  }, [categoryBooks]);

  const filterBooksByCategory = (books, category) => {
    return books.filter((book) => book.categoryId === category);
  };
  useEffect(() => {
    if (getAllBooks) {
      const allBooks = getAllBooks.data.books;

      const filteredBooks = categoryName
        ? filterBooksByCategory(allBooks, categoryName)
        : allBooks;

      setCategoryList(filteredBooks);
      setTotalBook(filteredBooks.length);
    }
  }, [getAllBooks, categoryName]);

  if (isLoading) {
    return (
      <div className="centered_spin">
        <Spin />
      </div>
    );
  }

  if (isError) {
    return (
      <div className="ErrorContainer">
        <EmptyComponent />
      </div>
    );
  }

  const handleChange = (value) => {
    // setCategoryName(value);
    setCategoryName(value === null || value === undefined ? null : value);
  };

  const handleRentBook = (book) => {
    setRentModalVisible(book);
    setSelectedBook(book);
  };

  const onHandleRequest = (book) => {
    setRequestModalVisible(true);
  };

  return (
    <div className="backgroundContainer">
      <>
        {book === null && (
          <>
            <div className="book-total-container">
              <h1 className="books">Books</h1>
              <div className="books-count-container">
                <p className="books-count">{totalBook}</p>
              </div>
              <Select
                placeholder="Select a category"
                style={{ marginLeft: 30, width: 120 }}
                onChange={handleChange}
                allowClear
              >
                {getAllBooksCategorys?.map((category) => (
                  <Option key={category.id} value={category.categoryName}>
                    {/* {category.categoryName} */}
                    {category.categoryName.charAt(0).toUpperCase() +
                      category.categoryName.slice(1)}
                  </Option>
                ))}
              </Select>
            </div>
          </>
        )}
        <div className="book-main-container ">
          {categoryName == null &&
            booksList?.map((book) => {
              return (
                <div
                  className="books-row"
                  // onClick={() => bookViewHandle(book)}
                  key={book.id}
                >
                  <img src={book.image} alt="img" className="card-image" />
                  <div className="author-card-content-container">
                    <h5>{book.bookName}</h5>
                    <h5>No. of books {book.numbersOfBooks}</h5>
                    <h5>{book.publisher}</h5>
                  </div>
                </div>
              );
            })}
          {categoryList.length === 0 ? (
            <div
              className="empty-component"
              style={{
                paddingTop: "70px",
                paddingBottom: "40px",
                backgroundColor: "#f0f0f0",
              }}
            >
              <EmptyComponent />
            </div>
          ) : (
            <>
              {categoryList?.map((book) => (
                <div
                  className="card-container"
                  // onClick={() => bookViewHandle(book)}
                  key={book.id}
                >
                  <img src={book.image} alt="img" className="card-image" />
                  <div className="card-text">
                    <p>{book.bookName}</p>
                    <span>Available Book : {book.booksAvailable}</span>
                    <span>Self Number:{book.bookSelfNumbers}</span>
                  </div>
                  <div>
                    {book?.numbersOfBooks > 0 ? (
                      GetRentedBooks?.some(
                        (item) => item.book.id === book.id
                      ) ? (
                        <Button className="rent-button green-bg">Rented</Button>
                      ) : (
                        <Button
                          className="rent-button orange-bg"
                          onClick={() => handleRentBook(book)}
                        >
                          Rent Now
                        </Button>
                      )
                    ) : (
                      <Button
                        className="request-button"
                        onClick={() => onHandleRequest(book)}
                      >
                        Request
                      </Button>
                    )}
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
      </>

      {/* //modal */}

      <RentBookModal
        isRentModalVisible={isRentModalVisible}
        setRentModalVisible={setRentModalVisible}
        selectedBook={selectedBook}
      />
      <RequestBookModal
        isRequestModalVisible={isRequestModalVisible}
        setRequestModalVisible={setRequestModalVisible}
        selectedBook={selectedBook}
      />
    </div>
  );
};

export default Books;
