import React from "react";
import { Schoolname, SyllabusDetail } from "../../components";

function Syllabus() {
  return (
    <main>
      <Schoolname />
      <SyllabusDetail />
    </main>
  );
}

export default Syllabus;
