import React, { useEffect } from "react";
import {
  Modal,
  Row,
  Col,
  Input,
  Select,
  DatePicker,
  Form,
  notification,
  Space,
  Button,
  message,
} from "antd";
import InputFloatLabel from "../../Common/FloatLable/Input";
// import { EdpediaStoreService } from "../../../Services/EdpediaStoreService";
import { CheckCircleFilled } from "@ant-design/icons";
import {
  useAddressCreateMutation,
  useAddressEditMutation,
} from "../../../RTKQuery/rtkEdpediaStore";
import Cookies from "js-cookie";

const EdpediaStoreService = [];

const AddressCreateModal = ({
  visible,
  setVisible,
  selectedAddress,
  getAddressList,
}) => {
  const studentInfo = Cookies.get("studentInfo")
    ? JSON.parse(Cookies.get("studentInfo"))
    : [];
  const studentId = studentInfo?.rollNo;
  const [addressForm] = Form.useForm();
  const [createAddress] = useAddressCreateMutation();
  const [EditAddress] = useAddressEditMutation();

  const onCancel = () => {
    setVisible(false);
  };
  const onFinish = (values) => {
    const payload = {
      ...values,
    };

    const selectedId = selectedAddress.id;
    if (selectedAddress.id) {
      EditAddress({ selectedId, payload })
        .unwrap()
        .then((response) => {
          getAddressList();
          setVisible(false);
          message.success("Address edited successfully");
        })
        .catch((error) => {
          
          message.success("Address edited failed");
        });
    } else {
      createAddress({ studentId, payload })
        .unwrap()
        .then((response) => {
          getAddressList();
          setVisible(false);
          notification.success({
            message: `Address Created Sucessfully`,
            placement: "bottomRight",
            icon: (
              <CheckCircleFilled
                style={{
                  color: "green",
                }}
              />
            ),
          });
        })
        .catch((error) => {
         
          message.success("Address creating failed");
        });
    }
  };

  useEffect(() => {
    if (selectedAddress.id) {
      addressForm.setFieldsValue({
        fullName: selectedAddress.fullName,
        addressLine1: selectedAddress.addressLine1,
        addressLine2: selectedAddress.addressLine2,
        city: selectedAddress.city,
        state: selectedAddress.state,
        country: selectedAddress.country,
        postalCode: selectedAddress.postalCode,
        mobileNumber: selectedAddress.mobileNumber,
        landmark: selectedAddress.landmark,
      });
    }
  }, [selectedAddress, addressForm]);

  return (
    <Modal
      open={visible}
      title="Create Address"
      onCancel={onCancel}
      onOk={onCancel}
      footer={null}
    >
      <Form form={addressForm} name="form_in_modal" onFinish={onFinish}>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="fullName"
              rules={[{ required: true, message: "" }]}
            >
              <InputFloatLabel label="Full Name" name="fullName" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="mobileNumber"
              rules={[{ required: true, message: "" }]}
            >
              <InputFloatLabel label="Mobile Number" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="addressLine1"
              rules={[{ required: true, message: "" }]}
            >
              <InputFloatLabel label="Address Line1" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="addressLine2"
              rules={[{ required: true, message: "" }]}
            >
              <InputFloatLabel label="Address Line2" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="city" rules={[{ required: true, message: "" }]}>
              <InputFloatLabel name="city" label="City" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="state" rules={[{ required: true, message: "" }]}>
              <InputFloatLabel name="state" label="State" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="postalCode"
              rules={[{ required: true, message: "" }]}
            >
              <InputFloatLabel
                name="postalCode"
                label="Postal Code"
                type="number"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="country" rules={[{ required: true, message: "" }]}>
              <InputFloatLabel name="country" label="Country" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="landmark">
              <InputFloatLabel name="landmark" label="Landmark" />
            </Form.Item>
          </Col>
        </Row>
        <Row className="my-row" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={12} align="end">
            <Space direction="vertical" size="large">
              <Button className="submit-button " htmlType="submit">
                Save
              </Button>
            </Space>
          </Col>
          <Col span={12}>
            <Space direction="vertical" size="large">
              <Button className="cancel-button" onClick={onCancel}>
                cancel
              </Button>
            </Space>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default AddressCreateModal;
